import PropTypes from "prop-types";
export default function HeaderBar({ children, className, ...otherProps }) {
  return (
    <div
      className={`mb-4 p-4 shadow-lg shadow-slate-200 border border-slate-300 bg-slate-200 ${className}`}
      {...otherProps}
    >
      {children}
    </div>
  );
}

HeaderBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
HeaderBar.defaultProps = {
  className: "",
};
