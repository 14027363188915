// return just one element from a collection.
// it is an error if there is more than one match

import useLoad from "./useLoad";

// load some data, then intercept it and check that there are not more than 1 results returned!
function useLoadOne(collection, find = {}, options) {
  const { onLoad } = Object.assign({ onLoad: () => {} }, options);
  const onLoadCheck = (data) => {
    if (data.length > 1)
      throw new Error(`useLoadOne: ${data.length} items returned!`);
    return onLoad(data);
  };
  const { reload, data } = useLoad(
    collection,
    find,
    Object.assign(options || {}, { onLoad: onLoadCheck })
  );

  // if we're still loading (undefined), then item is undefined
  // if we loaded [], then item is null (not found)
  // if we loaded [].length>1 then we should have thrown an error already
  // if we loaded [].length===1 (yay!) then item=data[0];

  let item = null;
  if (data === undefined) item = undefined;
  else if (Array.isArray(data)) {
    if (data.length === 0) item = null;
    else item = data[0];
  }
  return { reload, item, data };
}

export default useLoadOne;
