import { useEffect } from "react";
import { useState } from "react";
import { store } from "react-recollect";
import RenderFieldChooser from "./RenderFieldChooser";
import RenderFieldColumn from "./RenderFieldColumn";
import RenderFieldSharonShortcutTextarea from "./RenderFieldSharonShortcutTextarea";
import RenderFieldTextChooser from "./RenderFieldTextChooser";
import Toggle from "./Toggle";

export const classNamesLeftColumn =
  "flex-none w-60 px-3 py-4 mr-2 bg-gradient-to-r from-transparent to-slate-200 border-r border-slate-300";
export const classNamesRightColumn = "flex-1 py-1";

function RenderField({ field, setValue, savedValue }) {
  const handleChange = (v) => {
    const newValue = field.forceCapitals ? v.toUpperCase() : v;

    setLocalValue(newValue);
    setValue(newValue);
  };

  const [localValue, setLocalValue] = useState(savedValue || "");
  const localValueTRIM =
    typeof localValue === "string"
      ? localValue.replace(/[/-]*/g, "").trim().toUpperCase()
      : "";

  const handleBlur = (e) => {
    store.settingsMap["shortcuts"].forEach((s) => {
      if (localValueTRIM === s.short) setValue(s.long);
    });
  };
  let shortcut = null;
  store.settingsMap["shortcuts"].forEach((s) => {
    if (localValueTRIM === s.short) shortcut = s.long;
  });

  // if savedValue changes (e.g. "Copy values" from a previous cert), update local state
  useEffect(() => {
    let v = savedValue || "";
    store.settingsMap["shortcuts"].forEach((s) => {
      if (v === s.short) v = s.long;
    });
    setLocalValue(v);
  }, [savedValue]);

  switch (field.type || "text") {
    case "text":
      return (
        <RenderFieldColumn leftContent={field.label}>
          <input
            type="text"
            className="input input-bordered w-full"
            onChange={(e) => handleChange(e.target.value)}
            value={localValue}
            onBlur={handleBlur}
          />
          <p className="text-sm text-slate-700">{shortcut}</p>
        </RenderFieldColumn>
      );

    case "textarea":
      return (
        <RenderFieldSharonShortcutTextarea
          label={field.label}
          onChange={handleChange}
          value={localValue}
          onBlur={handleBlur}
          doAlphaNumericCapitalise={field.key === "otherNumbers"}
        />
      );

    case "chooser":
      // like makeAndModel (array of {key, value})
      return (
        <RenderFieldChooser
          field={field}
          localValue={localValue}
          setValue={handleChange}
          savedValue={savedValue}
        />
      );

    case "textchooser":
      // like spec (array of strings)
      return (
        <RenderFieldTextChooser
          settingsKey="specs"
          field={field}
          localValue={localValue}
          setValue={handleChange}
          savedValue={savedValue}
        />
      );

    case "toggleShown":
      const lv = localValue ?? "-noshow-";
      const showIT = !lv.startsWith("-noshow-");
      return (
        <RenderFieldColumn leftContent={field.label} rightExtraClasses="flex">
          <div className="w-60 border-2 pt-1 px-2 rounded-lg">
            <Toggle
              label="Show issued to"
              value={showIT}
              setValue={() =>
                handleChange(
                  showIT
                    ? `-noshow-${savedValue || ""}`.replace(
                        "-noshow--noshow-",
                        "-noshow-"
                      )
                    : (savedValue || "").replace("-noshow-", "")
                )
              }
            />
          </div>
          <div className="flex-1 px-1">
            {showIT && (
              <input
                type="text"
                className="input input-bordered w-full ml-1"
                onChange={(e) => handleChange(e.target.value)}
                value={lv}
                onBlur={handleBlur}
              />
            )}
          </div>
        </RenderFieldColumn>
      );

    case "certNumber":
      // this is delt with higher up, with a POST to the server
      // this is so that we can not show the rest of the fields until it's been done
      return null;

    case "notyping":
      // this is a field that doesn't need data entry (such as "date of issue")
      return null;

    default:
      return <p>Don't know what to do with a {field.type} field</p>;
  }
}

function RenderFields({ fields, data, setData, savedData }) {
  const setValue = (k) => (v) => setData({ ...data, [k]: v });

  return fields.map((f) => (
    <RenderField
      key={f.key}
      field={f}
      savedValue={savedData[f.key]}
      setValue={setValue(f.key)}
    />
  ));
}

export default RenderFields;
