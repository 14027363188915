import { collect, store } from "react-recollect";
import Loading from "components/Loading";
import { dedupe, pluralN } from "lib/useful";
import useStoreLoader from "lib/useStoreLoader";

// we need to load more orders if any of the requested Id's orders are undefined
export const needToLoadShopifyOrders = (ids, storeprop = store) =>
  ids.some((id) => (storeprop.shopifyOrders || {})[id] === undefined);

// load orders, display a Loading while doing so.  pass props through to Loading (so we can set size etc)
function LoadShopifyOrders({ ids, className, ...otherProps }) {
  useStoreLoader({
    collection: "shopifyOrdersLive",
    storeKey: "shopifyOrders",
    find: { _id: { $in: dedupe(ids) } },
    merge: true,
  });

  return (
    <Loading
      text={`Fetching ${pluralN(ids.length, "order")} from Shopify`}
      className={`mt-4 ${className}`}
      {...otherProps}
    />
  );
}

export default collect(LoadShopifyOrders);
