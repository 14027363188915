import LoadShopifyOrders, {
  needToLoadShopifyOrders,
} from "components/loaders/LoadShopifyOrders";
import Loading from "components/Loading";
import Page from "components/Page";
import useLoad from "lib/useLoad";
import { collect, store, useProps } from "react-recollect";
import { Link } from "react-router-dom";

function WebResearchList() {
  useProps([store.shopifyOrders]); // for some reason recollect doesn't detect us reading this

  const { data: webResearches } = useLoad("webResearch", {
    status: "new",
  });

  if (webResearches === undefined) return <Loading text="Loading New orders" />;

  const shopifyOrderIds = webResearches.map((r) => r.shopifyOrdersId);
  if (needToLoadShopifyOrders(shopifyOrderIds, store))
    return <LoadShopifyOrders ids={shopifyOrderIds} />;

  return (
    <Page title="Web Research" heading="Web Research">
      {webResearches.length === 0 && (
        <p className="m-4 text-xl text-emerald-700">
          No new research questions!
        </p>
      )}
      {webResearches.map((item) => {
        const order = store.shopifyOrders[item.shopifyOrdersId];
        return (
          <Link to={`/web/${item._id}`} key={item._id}>
            <div className="cursor-pointer hover:bg-slate-100 hover:border-blue-500 border p-4 m-2 bg-white shadow">
              <h3 className="text-xl">{item.name}</h3>
              <p className="text-slate-700">{order.email}</p>
              <pre className="hidden">
                {JSON.stringify(
                  { item, s: store.shopifyOrders[item.shopifyOrdersId] },
                  null,
                  2
                )}
              </pre>
            </div>
          </Link>
        );
      })}
    </Page>
  );
}

export default collect(WebResearchList);
