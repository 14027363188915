import { batch, store } from "react-recollect";
import { useEffect, useState } from "react";
import { certGETj } from "lib/API";
import { tryToLoadToken } from "./Login";

export function TokenRenewer() {
  const [t, setT] = useState(0);
  const [renewing, setRenewing] = useState(false);

  function renew() {
    setRenewing(true);
    certGETj("renewToken").then((r) => {
      if (r.token) {
        const tokenInfo = tryToLoadToken(r.token);
        batch(() => {
          store.token = tokenInfo.token;
          store.loggedInUser = tokenInfo.loggedInUser;
          window.localStorage.setItem("token", tokenInfo.token);
          setRenewing(false);
        });
      }
    });
  }

  useEffect(() => {
    const timerID = setInterval(() => {
      setT(Math.floor(+new Date() / 1000));
      if (store.loggedInUser) {
        const e = store.loggedInUser.exp - t;
        if (e < 120) {
          console.log("Renewing token...");
          renew();
        }
      }
    }, 10 * 1000);

    return () => clearInterval(timerID);
  });

  if (!store.loggedInUser) return null;

  return (
    <div className="hidden absolute left-0 top-16 bg-slate-900 text-green-500 z-50">
      <pre>
        {JSON.stringify(
          { renewing, l: store.loggedInUser, t, e: store.loggedInUser.exp - t },
          null,
          2
        )}
      </pre>
    </div>
  );
}
