import { useEffect, useState } from "react";
import useStoreLoader from "lib/useStoreLoader";
import Loading from "components/Loading";
import LoadShopifyOrders, {
  needToLoadShopifyOrders,
} from "components/loaders/LoadShopifyOrders";
import { collect, store } from "react-recollect";
import { useNavigate } from "react-router-dom";
import Page from "components/Page";
import FullsizeMessage from "components/FullsizeMessage";
import HeaderBar from "components/HeaderBar";
import OrderLine from "components/OrderLine";
import { randomFromArray } from "lib/useful";

function CertificatesGenericResearch({ title, heading, statuskey, path }) {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const { isLoading, items } = useStoreLoader({
    collection: "certificateResearch",
    storeKey: `cert-${statuskey}`,
    find: { status: statuskey },
  });
  useEffect(
    () => () => {
      delete store[`cert-${statuskey}`];
    },
    [statuskey]
  ); // force it to reload next time

  if (isLoading) return <Loading text={`Loading ${heading} orders`} />;
  const researches = Object.values(items);
  if (researches.length === 0 && statuskey !== "suspended")
    return (
      <FullsizeMessage withConfetti>
        {randomFromArray(["Woohoo", "Yay", "Fantastic", "Great"])}, there are no
        research slips in '{heading}' state
      </FullsizeMessage>
    );
  if (researches.length === 0 && statuskey === "suspended")
    return <FullsizeMessage>No suspended research slips</FullsizeMessage>;

  const shopifyOrderIds = researches.map((r) => r.shopifyOrdersId);
  if (needToLoadShopifyOrders(shopifyOrderIds))
    return <LoadShopifyOrders ids={shopifyOrderIds} />;

  const searchedResearches =
    search.length === 0
      ? researches
      : researches.filter(
          (r) => r.name?.includes(search) || r.certNumber?.includes(search)
        );

  function handleKeyDown(e) {
    if (e.keyCode === 13 && searchedResearches.length === 1) {
      navigate(`/${path}/` + searchedResearches[0]._id);
    }
  }

  return (
    <Page title={title} heading={heading}>
      <HeaderBar>
        <div className="form-control">
          <input
            autoFocus
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            onKeyDown={handleKeyDown}
            className="input input-info input-bordered"
          />
        </div>
        {searchedResearches.length === 1 && (
          <p className="mt-2">
            Press ENTER to load{" "}
            {searchedResearches[0].certNumber
              ? `${searchedResearches[0].certNumber} (Shopify ${searchedResearches[0].name})`
              : searchedResearches[0].name}
          </p>
        )}
      </HeaderBar>
      {searchedResearches.length === 0 && (
        <p className="text-xl text-slate-700 m-4">
          No research slips include "{search}"
        </p>
      )}
      <div className="pb-1">
        {searchedResearches.map((i) => (
          <OrderLine key={i._id} r={i} linkTo={`/${path}/${i._id}`} />
        ))}
      </div>
    </Page>
  );
}

export default collect(CertificatesGenericResearch);
