import { useEffect, useState } from "react";
import Loading from "components/Loading";
import Page from "components/Page";
import { certGETj, certPOST, certPOSTj } from "lib/API";
import { extraAuths } from "lib/constants";
import Toggle from "components/Toggle";
import { ButtonPrimary, ButtonSecondary } from "components/Buttons";
import Input from "components/Input";
import { SaveIcon } from "@heroicons/react/outline";
import Spinner from "components/Spinner";
import MessageDialogWithActions from "components/MessageDialogWithActions";

function AddUserDialogButton({ reload }) {
  const [showing, setShowing] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  async function addUser() {
    const r = await certPOSTj("addUser", { email });
    if (r.ok) {
      setShowing(false);
      reload();
      setTimeout(
        () => window.scrollTo({ top: 99999, behavior: "smooth" }),
        300
      );
    } else setError(r.error);
  }

  return (
    <div className="m-2">
      <ButtonPrimary onClick={() => setShowing(true)}>
        Add user...
      </ButtonPrimary>
      {showing && (
        <MessageDialogWithActions
          name="addUserDialog"
          message={
            <>
              {error && (
                <p className="m-2 p-4 bg-red-300 border-2 border-red-600">
                  {error}
                </p>
              )}
              <Input
                autoFocus
                value={email}
                setValue={setEmail}
                label="Email address"
              />
            </>
          }
          actions={[
            {
              text: "Cancel",
              colour: "red",
              onClick: () => setShowing(false),
            },
            ...(email.trim().length > 0 &&
            email.indexOf("@") > 0 &&
            email.indexOf(".") > 0
              ? [
                  {
                    text: "Add",
                    colour: "green",
                    onClick: addUser,
                  },
                ]
              : []),
          ]}
        />
      )}
    </div>
  );
}

function User({ u, reload }) {
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState(u);
  const setPart = (k) => (v) => setUser((u) => ({ ...u, [k]: v }));

  const different = JSON.stringify(u) !== JSON.stringify(user);

  function save() {
    setSaving(true);
    certPOST(`setUser/${user._id}`, user).then(() => {
      setSaving(false);
      reload();
    });
  }

  return (
    <div className="m-2 border shadow-sm p-2" id={user._id}>
      <p className="text-sm text-slate-400 text-right">{user._id}</p>
      <Input
        className="m-1"
        value={user.user || ""}
        setValue={setPart("user")}
        label="Username"
        disabled={saving}
      />
      <Input
        className="m-1"
        value={user.email || ""}
        setValue={setPart("email")}
        label="Email"
        disabled={saving}
      />
      <div className="my-2 p-2">
        <h4>Authorizations</h4>
        {extraAuths.map((a) => (
          <Toggle
            className="w-60"
            key={a}
            label={a}
            value={user.auths.includes(a)}
            setValue={(v) =>
              v
                ? setPart("auths")([...user.auths, a].sort())
                : setPart("auths")(user.auths.filter((aa) => aa !== a))
            }
            disabled={saving}
          />
        ))}
      </div>
      <ButtonSecondary disabled={!different || saving} onClick={save}>
        {saving ? (
          <>
            <Spinner />
            &nbsp;Saving
          </>
        ) : (
          <>
            <SaveIcon className="w-6 h-6" />
            &nbsp;&nbsp;Save
          </>
        )}
      </ButtonSecondary>
    </div>
  );
}

function Users() {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [reloadC, setReloadC] = useState(0);
  const reload = () => setReloadC((c) => c + 1);

  useEffect(() => {
    setLoading(true);
    certGETj("users").then((r) => {
      setUsers(r);
      setLoading(false);
    });
  }, [reloadC]);

  if (loading) return <Loading text="Loading user information" />;

  return (
    <Page title="Users" heading="Edit users">
      <AddUserDialogButton reload={reload} />
      {users.map((u) => (
        <User key={u._id} u={u} reload={reload} />
      ))}
    </Page>
  );
}
export default Users;
