import PropTypes from "prop-types";
import { isDev } from "lib/useful";
import { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

export default function Page({
  title,
  children,
  className,
  heading,
  breadcrumbs,
}) {
  useEffect(() => {
    document.title = `${isDev() ? "[DEV] " : ""}${title} · Cert System`;
  }, [title]);

  return (
    <div className={`w-full h-full flex flex-col ${className}`}>
      {heading && (
        <h1 className="text-2xl font-bold mt-3 ml-4 mb-2">{heading}</h1>
      )}
      {breadcrumbs && (
        <div className="ml-2 text-xs">
          {breadcrumbs.map((bc, bci) => (
            <Fragment key={`${bci}_${bc.to}`}>
              <span className="text-slate-500 mx-1">«</span>
              <Link to={bc.to}>
                <span className="text-blue-900">{bc.label}</span>
              </Link>
            </Fragment>
          ))}
        </div>
      )}
      {children}
    </div>
  );
}

Page.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  heading: PropTypes.string,
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object),
};
Page.defaultProps = {
  className: "",
  breadcrumbs: null,
};
