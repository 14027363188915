import { Suspense, lazy } from "react";
import { batch, collect, initStore, store } from "react-recollect";
import DarkBackgroundWrap from "./DarkBackgroundWrap";
import LoadSettings from "./LoadSettings";
import LoadAuths from "./LoadAuths";
import { TokenRenewer } from "./TokenRenewer";
import { LoginForm } from "./LoginForm";
import { LoginSetUsername } from "./LoginSetUsername";
const LoginForgotPassword = lazy(() => import("./LoginForgotPassword"));
const LoginReset = lazy(() => import("./LoginReset"));

initStore({ ...tryToLoadToken() });

export function tryToLoadToken(tokenArg = "") {
  let t = tokenArg;
  if (!t) t = store.token;
  if (!t) t = localStorage.getItem("token");
  if (!t) return {};
  const s = t.split(".");
  if (s.length !== 3) return {};
  const p = JSON.parse(atob(s[1]));
  if (!p) return {};
  if (new Date(p.exp * 1000) < new Date()) return {};
  return { token: t, loggedInUser: p };
}

function Login({ children }) {
  let content = null;

  // first, see if we have a token in store
  let { token, loggedInUser } = tryToLoadToken();

  // if not, (maybe refreshed the page?), then see if we've stored
  // one in localstorage (and it's still valid)
  if (token && !store.token) {
    setTimeout(() => {
      console.log("Auto logged in from localStorage for", loggedInUser.email);
      batch(() => {
        store.token = token;
        store.loggedInUser = loggedInUser;
      });
    });
    content = <p>Auto logging in...</p>;
  } else {
    // if we've logged in and loaded everything then just show children
    if (token && store.auths && store.settingsMap) {
      if (!store.loggedInUser.user)
        content = (
          <DarkBackgroundWrap>
            <LoginSetUsername />
          </DarkBackgroundWrap>
        );
      else content = children;
    } else {
      // otherwise, load things or show login button
      // (both wrapped in darkBackground)
      let content2 = null;
      if (token) {
        if (!store.auths) content2 = <LoadAuths />;
        if (!store.settingsMap) content2 = <LoadSettings />;
      } else {
        if (window.location.search === "?forgot")
          content2 = <LoginForgotPassword />;
        else if (window.location.search.startsWith("?reset"))
          content2 = <LoginReset />;
        else content2 = <LoginForm />;
      }
      content = <DarkBackgroundWrap>{content2}</DarkBackgroundWrap>;
    }
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <TokenRenewer />
      {content}
    </Suspense>
  );
}

export default collect(Login);
