import { useEffect, useState } from "react";
import { collect, store } from "react-recollect";
import { certGETj } from "lib/API";
import { isDev } from "lib/useful";

const packageJSON = require("../../package.json");

function VersionInfo() {
  const [serverVersion, setServerVersion] = useState(null);
  const t = store.token;
  useEffect(() => {
    if (t) certGETj("version").then(setServerVersion);
  }, [t]);

  return (
    <div className="absolute text-sm left-14 top-10 lg:left-5 text-white opacity-50 z-50 pointer-events-none">
      {isDev() && "isDev — "}C v{packageJSON.version}
      {serverVersion && <span> — S v{serverVersion}</span>}
    </div>
  );
}

export default collect(VersionInfo);
