import { collect, store } from "react-recollect";
import useStoreLoader from "lib/useStoreLoader";
import Loading from "./Loading";

function LoadSettings(props) {
  // wrap an app with settings so we have it globally
  // map it to a [key]:value pair for ease of use

  useStoreLoader({
    collection: "settings",
    onLoad: (items) => {
      const map = Object.values(items).reduce(
        (p, c) => ({ ...p, [c.key]: c.value }),
        {}
      );
      store.settingsMap = map;
    },
  });

  return <Loading text="Loading Certificate System: settings" />;
}

export default collect(LoadSettings);
