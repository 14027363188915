import { Button, ButtonSecondary } from "components/Buttons";
import HeaderBar from "components/HeaderBar";
import Page from "components/Page";
import Toggle from "components/Toggle";
import { format, formatRelative } from "date-fns";
import { enGB } from "date-fns/locale";
import { certGETj } from "lib/API";
import { atBMM, statusColours } from "lib/constants";
import { isDev, pluralN } from "lib/useful";
import { useEffect, useState } from "react";

const Changes = ({ changes }) =>
  changes && changes.length > 0 ? (
    changes.map((c) => {
      switch (c.type) {
        case "new":
          return (
            <p key={c.k}>
              <span className="text-emerald-600">new</span> {c.k}:{" "}
              <b>{JSON.stringify(c.v, null, 2)}</b>
            </p>
          );
        case "change":
          return (
            <p key={c.k}>
              <span className="text-amber-500">change</span> {c.k}: from{" "}
              {JSON.stringify(c.ov, null, 2)} to{" "}
              <b>{JSON.stringify(c.v, null, 2)}</b>
            </p>
          );
        case "added":
          return (
            <p key={c.k}>
              <span className="text-blue-500">added</span> {c.k}:{" "}
              <b>{JSON.stringify(c.v, null, 2)}</b>
            </p>
          );
        case "deleted":
          return (
            <p key={c.k}>
              <span className="text-red-900">deleted</span> {c.k}:{" "}
              {`(was ${JSON.stringify(c.ov, null, 2)})`}
            </p>
          );
        default:
          return <p key={c.k}>?? {JSON.stringify(c)}</p>;
      }
    })
  ) : (
    <p className="text-slate-500 italic">No changes</p>
  );

function AuditLogs() {
  const [data, setData] = useState({ ndoc: 0, logs: [] });
  const [loading, setLoading] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [lastRefresh, setLastRefresh] = useState(null);
  const [page, _setPage] = useState(0);
  const pagesize = 10;
  const npages = Math.ceil(data.ndoc / pagesize);

  const setPage = (p) => {
    if (p < 0) return;
    if (p >= npages) return;
    _setPage(p);
    setRefreshCounter(0);
  };

  useEffect(() => {
    const tid = setInterval(() => {
      if (autoRefresh) setRefreshCounter((c) => c + 1);
    }, 5000);
    return () => clearInterval(tid);
  }, [autoRefresh]);

  useEffect(() => {
    setLoading(true);
    setLastRefresh(new Date());
    certGETj(
      `getLogs?page=${page}&pagesize=${pagesize}&r=${refreshCounter}`
    ).then((l) => {
      setData(l);
      setLoading(false);
    });
  }, [refreshCounter, page, pagesize]);

  function getColourClass(verbosity) {
    switch (verbosity) {
      case "admin":
        return "text-orange-700 text-2xl";
      case "save":
        return "text-emerald-600";
      case "error":
        return "text-red-700 text-xl";
      case "info":
        return "text-blue-600";
      default:
        return "text-slate-600";
    }
  }

  function formatLog(log) {
    switch (log.func) {
      case "saveResearchData":
        return (
          <>
            <p>
              {log.info.changes.length} changes for cert {log.info._certId}
            </p>
            <Changes changes={log.info.changes} />
          </>
        );
      case "saveLayout":
        return <Changes changes={log.info.changes} />;
      case "search":
        return (
          <p>
            Search for "<b>{log.info.q}</b>" returns{" "}
            <b>{pluralN(log.info.resultsLength, "result")}</b>
          </p>
        );
      case "setStatus":
        return (
          <p>
            Changed status of {log.info.certId} to{" "}
            <b className={statusColours[log.info.status]}>{log.info.status}</b>
          </p>
        );
      case "generateCertNumber":
        return (
          <p>
            Generated certificate number <b>{log.info.certNumber}</b> for order{" "}
            <b>{log.info.name}</b> (certId {log.info.certId})
          </p>
        );
      case "email":
      case "email/sent":
        return (
          <div className="border shadow-lg p-4 m-4">
            <p>
              to <b>{log?.info?.email?.to}</b>
            </p>
            <p>
              subject <b>{log?.info?.email?.subject}</b>
            </p>
            <div className="border p-2 m-1 whitespace-pre-line">
              {(log?.info?.email?.text || "").split("\\n").map((t, ti) => (
                <p key={ti}>{t}</p>
              ))}
            </div>
            <p>
              Attachments:{" "}
              {(log?.info?.email?.attachmentFilenames || []).join(", ")}
            </p>
            <div className="divider">Technical information</div>
            {log?.info?.err && (
              <pre className="text-red-900">
                Error!
                <br />
                {JSON.stringify(log.info.err, null, 2)}
              </pre>
            )}
            {log?.info?.info && (
              <pre className="text-green-900">
                Info from GMail
                <br />
                {JSON.stringify(log.info.info, null, 2)}
              </pre>
            )}
          </div>
        );
      default:
        return (
          <>
            <p>{log.text}</p>
            <pre className="text-sm text-slate-500">
              {JSON.stringify(log.info, null, 2)}
            </pre>
          </>
        );
    }
  }

  function ipRecognised(ip) {
    if (!ip) return "no ip";
    if (ip === "::ffff:127.0.0.1" || ip === "127.0.0.1" || ip === "::1")
      return ip + " localhost" + (isDev() ? " dev mode" : " *local in prod*!");
    if (ip.startsWith("165.225.")) return ip + " (probably JLR ZScaler London)";
    if (ip.startsWith("147.161.")) return ip + " (probably JLR ZScaler London)";
    if (ip === "195.114.103.172") return ip + " (BMM WiFi)";
    const ipv4digits = ip.split(".").map((s) => parseInt(s, 10));
    if (
      ip.startsWith("195.114.103.1") &&
      ipv4digits.length === 4 &&
      ipv4digits[3] >= 169 &&
      ipv4digits[3] <= 172
    )
      return ip + " (BMM other connection)";
    return ip;
  }

  return (
    <Page title="Audit Logs" headings="View Audit Logs">
      <HeaderBar className="flex">
        <div className="w-60 flex flex-col">
          <Toggle
            className="pr-4"
            value={autoRefresh}
            setValue={setAutoRefresh}
            label="Auto refresh"
          />
          {lastRefresh && (
            <p className="">Last refreshed {format(lastRefresh, "HH:mm:ss")}</p>
          )}
        </div>
        {!autoRefresh && (
          <div className="w-40">
            <ButtonSecondary
              onClick={() => setRefreshCounter((c) => c + 1)}
              disabled={loading}
            >
              Refresh
            </ButtonSecondary>
          </div>
        )}
        <div className="w-60">
          <div className="btn-group">
            <Button onClick={() => setPage(page - 1)} disabled={page === 0}>
              «
            </Button>
            <Button onClick={() => setPage(0)}>
              page {page + 1} of {npages}
            </Button>
            <Button
              onClick={() => setPage(page + 1)}
              disabled={page === npages}
            >
              »
            </Button>
          </div>
        </div>
      </HeaderBar>
      <div className="overflow-y-auto px-2">
        {data.logs.map((l) => (
          <div key={l._id} className="border m-2 shadow flex">
            <div className="p-1 pl-2 flex-shrink-0 w-48">
              <p className={`mb-1 font-bold ${getColourClass(l.verbosity)}`}>
                {l.verbosity}
              </p>
              <p className="font-bold">
                {formatRelative(new Date(l.date), new Date(), { locale: enGB })}
              </p>
              <p className="text-sm text-slate-800">
                {(l.loggedInAs || "").replace(atBMM, "")}
              </p>
              <p className="text-slate-500 text-sm">
                c{l.clientVersion} s{l.serverVersion}
              </p>
              <p className="text-slate-500 text-sm">{ipRecognised(l.ip)}</p>
            </div>
            <div className="border-l p-1 pl-2">
              <p>{l.func}</p>
              {formatLog(l)}
            </div>
          </div>
        ))}
      </div>
    </Page>
  );
}

export default AuditLogs;
