import { useState } from "react";
import Loading from "components/Loading";
import LoadShopifyOrders, {
  needToLoadShopifyOrders,
} from "components/loaders/LoadShopifyOrders";
import { collect, store } from "react-recollect";
import { certPOST } from "lib/API";
import RenderFields from "components/RenderFields";
import { useNavigate, useParams } from "react-router-dom";
import Page from "components/Page";
import useLoadOne from "lib/useLoadOne";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonError,
} from "components/Buttons";
import { findOne, formatTimestampNicelyDateTime } from "lib/useful";
import RenderFieldReissueSearch from "components/RenderFieldReissueSearch";
import RenderFieldColumn from "components/RenderFieldColumn";
import {
  ChevronDoubleRightIcon,
  PauseIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import MessageDialogWithActions from "components/MessageDialogWithActions";
import Notes from "components/Notes";

function TypingWithInfo({
  title,
  children,
  fields,
  cert,
  shopifyOrder,
  typingData,
  setTypingData,
  savedData,
  setCopiedData,
  unsuspend,
  doSuspend,
  save,
}) {
  if (!cert) throw Error("no cert");
  if (!shopifyOrder) throw Error("No order");

  const lineItem = shopifyOrder.line_items[cert.lineItemIndex] || {};
  const isReissue = (lineItem.sku || "").includes("REISS");
  const initialSearch = isReissue
    ? findOne(
        lineItem.properties || {},
        "name",
        "previous_certificate_number",
        {}
      ).value
    : "";

  const name =
    cert.certsInOrder > 1
      ? `${cert.name} (${cert.certNumberWithinOrder + 1} of ${
          cert.certsInOrder
        })`
      : cert.name;

  function generateCertNumber() {
    certPOST("generateCertNumber", { certId: cert._id, name }).then(
      async (r) => {
        const certNumber = await r.text();
        setCopiedData(
          Object.assign({}, savedData, typingData, {
            certNumber,
          })
        );
      }
    );
  }

  const notes = typingData.notes || savedData.notes || [];
  function setNotes(notes) {
    setTypingData({ ...typingData, notes }, true);
  }

  return (
    <Page title={`${cert.name} · ${title}`}>
      <div className="px-1 md:px-2 lg:px-3">
        <RenderFieldColumn leftContent="Shopify order number">
          <h2 className="text-2xl font-bold ml-1 mt-2">{name}</h2>
        </RenderFieldColumn>
        {cert.status !== "suspended" && (
          <div className="m-2">
            <SuspendButtonAndDialog doSuspend={doSuspend} />
          </div>
        )}
        <Notes notes={notes} setNotes={setNotes} />
        <RenderFieldColumn leftContent="Product">
          <p className="ml-1 mt-3">
            <b>{lineItem.name}</b>
          </p>
        </RenderFieldColumn>
        {cert.status === "suspended" ? (
          <div className="p-4 border-t-2 w-full">
            <p>Suspended by {savedData.suspendedBy}</p>
            <p>{formatTimestampNicelyDateTime(savedData.suspendedTimestamp)}</p>
            <p>reason:</p>
            <p className="bg-white border shadow-md p-4 mt-2 mb-4 whitespace-pre-line">
              {savedData.suspendedReason}
            </p>
            <div className="flex flex-col">
              <p>Unsuspend and move to...</p>
              {Object.entries({
                typing: "Typing up",
                typochecking: "Typo checking",
                techchecking: "Technical checking",
              }).map(([status, label]) => (
                <ButtonSecondary
                  key={status}
                  onClick={() => unsuspend(status)}
                  className="m-1 w-60"
                >
                  {label}
                </ButtonSecondary>
              ))}
              <ButtonError
                onClick={() => unsuspend("deleted")}
                className="m-1 w-60"
              >
                Delete
              </ButtonError>
            </div>
          </div>
        ) : (
          <>
            <RenderFieldColumn leftContent="Certificate issue number">
              {savedData["certNumber"] ? (
                <p className="ml-1 mt-3">
                  <b>{savedData["certNumber"]}</b>
                </p>
              ) : (
                <ButtonSecondary onClick={generateCertNumber}>
                  Generate certificate number
                </ButtonSecondary>
              )}
            </RenderFieldColumn>
            {savedData["certNumber"] && (
              <>
                <RenderFieldReissueSearch
                  leftText="Copy from previous certificate"
                  mergeNewValues={(values) =>
                    setCopiedData(
                      Object.assign({}, savedData, typingData, values)
                    )
                  }
                />
                {isReissue && (
                  <RenderFieldReissueSearch
                    initialSearch={initialSearch}
                    copiedFromPrevCert={savedData["copiedFromPrevCert"]}
                    mergeNewValues={(values) =>
                      setCopiedData(
                        Object.assign({}, savedData, typingData, values)
                      )
                    }
                  />
                )}
                <RenderFields
                  fields={fields}
                  setCopiedData={setCopiedData}
                  savedData={savedData || {}}
                  data={typingData}
                  setData={setTypingData}
                />
                {/* wrap children so we can provide a <Page>, and set the title */}
              </>
            )}
          </>
        )}
      </div>
      {cert.status !== "suspended" && savedData["certNumber"] && children}
    </Page>
  );
}

function SuspendButtonAndDialog({ doSuspend }) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  function handleSuspend(setStatusTo) {
    if (!message) return;
    doSuspend(message, setStatusTo);
    setOpen(false);
  }

  return (
    <>
      <ButtonSecondary onClick={() => setOpen(true)} className="mx-1">
        <PauseIcon className="w-6 h-6 mr-2" />
        Suspend / delete
      </ButtonSecondary>
      {open && (
        <MessageDialogWithActions
          name="suspendDialog"
          actions={[
            { text: "Cancel", onClick: () => setOpen(false) },
            {
              text: (
                <div className="flex items-center">
                  <PauseIcon className="w-6 h-6 pr-2" /> Suspend
                </div>
              ),
              colour: "green",
              onClick: () => handleSuspend("suspended"),
              disabled: message.trim().length === 0,
            },
            {
              text: (
                <div className="flex items-center">
                  <TrashIcon className="w-6 h-6 pr-2" /> Delete
                </div>
              ),
              colour: "red",
              onClick: () => handleSuspend("deleted"),
              disabled: message.trim().length === 0,
            },
          ]}
          message={
            <>
              <h2 className="text-xl font-bold">
                Provide a reason <small>*required</small>
              </h2>
              <div className="p-1">
                <textarea
                  autoFocus
                  className="textarea h-24 textarea-bordered w-full"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                />
              </div>
            </>
          }
        />
      )}
    </>
  );
}

function CertificatesTyping(props) {
  const { title, path, moveTo, moveText, navTo } = props;
  const params = useParams();
  const certId = params.certId;

  const { item } = useLoadOne("certificateResearch", { _id: certId });
  const { item: certSavedDataFromDB } = useLoadOne("researchData", {
    _certId: certId,
  });

  const [typingData, _setTypingData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [copiedData, setCopiedData] = useState({}); // data copied from a previous-issued certificate

  const navigate = useNavigate();
  const fields = store.settingsMap["fields"];

  if (item === undefined) return <Loading text="Loading Certificate details" />;
  if (item === null)
    return (
      <Loading
        text={`This certificate ${certId} doesn't exist in certificateResearch - maybe you cleared the DB?`}
      />
    );

  const shopifyOrderIds = [item.shopifyOrdersId];
  if (needToLoadShopifyOrders(shopifyOrderIds))
    return <LoadShopifyOrders ids={shopifyOrderIds} />;
  if (certSavedDataFromDB === undefined)
    return <Loading text="Loading previous typed data" />;

  const shopifyOrder = store.shopifyOrders[item.shopifyOrdersId];
  const lineItem = shopifyOrder.line_items[item.lineItemIndex];
  const issuedTo = findOne(
    (lineItem || {}).properties || {},
    "name",
    "issued_to",
    {}
  ).value;

  const certSavedData = Object.assign(
    {},
    { issuedTo },
    certSavedDataFromDB,
    copiedData
  );

  function save(andNav = true, dataToSave = null) {
    const data = dataToSave || {
      _certId: certId,
      ...certSavedData,
      ...typingData,
    };
    setIsSaving(true);
    certPOST("saveResearchData", data).then(() => {
      if (andNav) {
        navigate(navTo || path.replace("/:certId", ""));
        setIsSaving(false);
      }
    });
  }

  // Notes uses this to set the data and save from a child component
  function setTypingData(d, andSave = false) {
    _setTypingData(d);
    if (andSave) save(false, { _certId: certId, ...certSavedData, ...d });
  }

  function saveAndFinish() {
    save(false); // don't nav yet
    certPOST(`setStatus`, { status: moveTo, ids: [certId] }).then(() => {
      navigate(navTo || path.replace("/:certId", ""));
      setIsSaving(false);
    });
  }

  function doSuspend(message, setStatusTo = "suspended") {
    certPOST("saveResearchData", {
      _certId: certId,
      ...certSavedData,
      ...typingData,
      suspendedReason: message,
      suspendedTimestamp: Math.floor(+new Date() / 1000),
      suspendedBy: store.loggedInUser.user || store.loggedInUser.email,
    }).then(() => {
      certPOST("setStatus", { status: setStatusTo, ids: [certId] }).then(() => {
        navigate(navTo || path.replace("/:certId", ""));
      });
    });
  }

  function unsuspend(status) {
    certPOST("setStatus", { status, ids: [certId] }).then(() => {
      if (status === "deleted") navigate("/suspended");
      else navigate(`/${status}`);
    });
  }

  return (
    <TypingWithInfo
      title={title}
      cert={item}
      fields={fields}
      shopifyOrder={store.shopifyOrders[item.shopifyOrdersId]}
      typingData={typingData}
      savedData={certSavedData}
      setCopiedData={setCopiedData}
      setTypingData={setTypingData}
      doSuspend={doSuspend}
      unsuspend={unsuspend}
      save={save}
    >
      <div className="p-4 border-t-2 w-full flex items-center justify-end">
        {isSaving && (
          <span>
            Saving
            <Loading size="20px" />
          </span>
        )}
        <SuspendButtonAndDialog doSuspend={doSuspend} />
        <ButtonSecondary className="mx-1" disabled={isSaving} onClick={save}>
          Save
        </ButtonSecondary>
        <ButtonPrimary
          disabled={isSaving}
          onClick={saveAndFinish}
          className="mx-1"
        >
          Save and move to {moveText || moveTo}&nbsp;
          <ChevronDoubleRightIcon className="w-6 h-6" />
        </ButtonPrimary>
      </div>
    </TypingWithInfo>
  );
}

export default collect(CertificatesTyping);
