const statusColours = {
  new: "text-red-500",
  new_web: "text-orange-500",
  typing: "text-blue-400",
  typochecking: "text-blue-500",
  techchecking: "text-blue-600",
  typing_web: "text-orange-400",
  cert: "text-emerald-500",
  head: "text-emerald-500",
  email: "text-emerald-500",
  premPhoto: "text-emerald-500",
  supportingLetter: "text-emerald-500",
  fulfiling: "text-emerald-500",
  webResearch_web: "text-orange-700",
  techspec: "text-amber-700",
  docwallet: "text-amber-700",
};

const buttonClasses = {
  default:
    "btn bg-gradient-to-br from-slate-800 to-slate-700 hover:from-slate-700 hover:to-slate-600",
  green:
    "btn bg-gradient-to-br from-green-600 to-green-500 hover:from-green-500 hover:to-green-400",
  blue: "btn bg-gradient-to-br from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400",
  red: "btn bg-gradient-to-br from-red-600 to-red-500 hover:from-red-500 hover:to-red-400",
};

const extraAuths = ["certsystem", "reporting", "admin"];
const atBMM = "@britishmotormuseum.co.uk";

const layouts = [
  { key: "certstd", label: "Standard Certificate" },
  { key: "certprem", label: "Premier Certificate" },
  { key: "email", label: "Email copy" },
  { key: "paper", label: "Paper copy" },
];

export { statusColours, buttonClasses, extraAuths, atBMM, layouts };
