import { newPDF, printPDF } from "PDFCreators/common";
import { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import MessageDialogWithActions from "components/MessageDialogWithActions";
import { Button, ButtonSecondary } from "components/Buttons";
import { PrinterIcon } from "@heroicons/react/outline";
import PDFPremierPhoto from "PDFCreators/PDFPremierPhoto";
import { useEffect } from "react";

function PremierPhotoDialog({ handleClose, fulfilment }) {
  const photoUrl = fulfilment.photoUrl;

  // const paperSize = { w: 148, h: 105 }; // a6
  const paperSize = { w: 105, h: 74 }; // a7 (print in the middle of an a6)

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [pdfCrop, setPdfCrop] = useState({ w: 1, h: 1, x: 0, y: 0 });
  const [mediaSize, setMediaSize] = useState(null);
  const [fitWzoom, setFitWZoom] = useState(null);
  const [fitHzoom, setFitHZoom] = useState(null);

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      // convert to paper offsets (negative startx,starty and larger-than-paper w,h)
      const w = paperSize.w / (croppedArea.width / 100);
      const h = paperSize.h / (croppedArea.height / 100);
      setPdfCrop({
        w,
        h,
        x: -((croppedArea.x / 100) * w),
        y: -((croppedArea.y / 100) * h),
      });
    },
    [paperSize.w, paperSize.h]
  );

  function print() {
    const doc = newPDF();
    PDFPremierPhoto(doc, fulfilment, pdfCrop);
    printPDF(doc);
  }

  const cropEl = document.querySelector(".reactEasyCrop_CropArea");
  useEffect(() => {
    if (!mediaSize) return;
    if (!cropEl) return;
    setFitHZoom(cropEl.offsetHeight / mediaSize.height);
    setFitWZoom(cropEl.offsetWidth / mediaSize.width);
  }, [mediaSize, cropEl]);

  return (
    <MessageDialogWithActions
      wide
      name="PremierPhotoDialog"
      message={
        <div className="h-96 min-h-fit min-w-fit">
          <div className="relative w-full h-full">
            <Cropper
              image={photoUrl}
              crop={crop}
              zoom={zoom}
              minZoom={0.5}
              maxZoom={4}
              aspect={paperSize.w / paperSize.h}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              onMediaLoaded={(m) => {
                setMediaSize(m);
              }}
            />
          </div>
        </div>
      }
      actionLineAdditionalContent={
        <div className="flex-1 border flex h-16 items-center">
          <div className="flex-0 w-24 text-center">
            {(zoom * 100).toFixed(2)}%
          </div>
          <div className="btn-group flex-shrink-0">
            {fitHzoom && (
              <Button onClick={() => setZoom(fitHzoom)}>Fit height</Button>
            )}
            {fitWzoom && (
              <Button onClick={() => setZoom(fitWzoom)}>Fit width</Button>
            )}
          </div>
          <input
            type="range"
            value={zoom}
            min={0.5}
            max={4}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
            className="range range-xs mx-4"
          />
        </div>
      }
      actions={[
        {
          text: "Cancel",
          colour: "red",
          onClick: handleClose,
        },
        {
          text: "Print",
          colour: "green",
          onClick: print,
        },
      ]}
    />
  );
}

function PremierPhotoDialogButton({ fulfilment }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      {open && (
        <PremierPhotoDialog
          fulfilment={fulfilment}
          handleClose={() => setOpen(false)}
        />
      )}
      <ButtonSecondary onClick={() => setOpen(true)}>
        <PrinterIcon className="w-6 h-6 inline-block" />
        &nbsp;Print...
      </ButtonSecondary>
    </>
  );
}

export default PremierPhotoDialogButton;
