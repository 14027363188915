import { ButtonPrimary } from "components/Buttons";
import Input from "./Input";

export default function InputTextWithButton({
  value,
  setValue,
  onEnter,
  label,
  buttonContent,
  ...otherProps
}) {
  return (
    <div className="flex px-2">
      <Input
        label={label}
        value={value}
        setValue={setValue}
        onEnter={() => onEnter(value)}
        {...otherProps}
      />
      <ButtonPrimary
        className={`w-30 ml-2 ${label ? "mt-9" : ""}`}
        onClick={() => onEnter(value)}
      >
        {buttonContent}
      </ButtonPrimary>
    </div>
  );
}
