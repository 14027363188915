export function Tooltip({ text, children, position }) {
  // tailwind will not recognise anything clever here and will optimize it away
  const positionClass = {
    top: "",
    left: "tooltip-left",
    right: "tooltip-right",
    bottom: "tooltip-bottom",
  }[position || "top"];

  return (
    <div className={`tooltip ${positionClass}`} data-tip={text}>
      {children}
    </div>
  );
}
