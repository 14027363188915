import PropTypes from "prop-types";

function Toggle({
  left,
  label,
  value,
  setValue,
  className,
  labelClassName,
  ...otherProps
}) {
  return (
    <div className={`form-control ${className}`}>
      <label className={`cursor-pointer label ${left ? "justify-start" : ""}`}>
        {!left && (
          <span className={`label-text ${labelClassName}`}>{label}</span>
        )}
        <input
          type="checkbox"
          checked={value}
          className="toggle toggle-primary"
          onChange={(e) => setValue(e.target.checked)}
          {...otherProps}
        />
        {left && (
          <span className={`label-text ${labelClassName} ml-2`}>{label}</span>
        )}
      </label>
    </div>
  );
}

Toggle.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.string,
  left: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
};
Toggle.defaultProps = {
  className: "",
  labelClassName: "",
  label: "",
  left: false,
};

export default Toggle;
