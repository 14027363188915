import { store } from "react-recollect";
import PDFGenericLayout from "./PDFGenericLayout";

function PDFEmailCopy(doc, fulfilment) {
  const rid = fulfilment.researchId;
  const researchData = store.researchDataByCertId[rid];
  const layoutData = store.settingsMap["layout_email"];

  PDFGenericLayout(doc, layoutData, researchData);
}

export default PDFEmailCopy;
