import { store, collect } from "react-recollect";
import MessageDialogWithActions from "./MessageDialogWithActions";

function ErrorHeader() {
  if (!store.error) return null;

  return (
    <MessageDialogWithActions
      name="errordialog"
      wide
      actions={[
        {
          text: "Reload page",
          onClick: () => window.location.reload(),
          colour: "blue",
        },
        {
          text: "Continue anyway",
          onClick: () => {
            store.error = null;
          },
          colour: "red",
        },
      ]}
      message={<div className={`bg-red-200`}>{store.error}</div>}
    />
  );
}

export default collect(ErrorHeader);
