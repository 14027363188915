import { format, fromUnixTime } from "date-fns";
import { findOne } from "lib/useful";
import { store } from "react-recollect";

export const stylesPerSku = {
  CERT: { fillColor: "#dde3ed" },
  PREM: { fillColor: "#74e19c" },
  // "CERT-LAM": { fillColor: "#bf84d7" },
  // "PREM-LAM": { fillColor: "#00d368" },
  "CERT-EMAIL-COPY": { fillColor: "#dd5231" },
  PAPERCOPY: { fillColor: "#eb89e8" },
  DOCWALLET: { fillColor: "#03a9f4" },
  "CERT-AM-FOLDER": { fillColor: "#1780af" },
  TECHSPEC: { fillColor: "#e9a075" },
  "CERT-REISS": { fillColor: "#000000" },
  // "CERT-REISS-LAM": { fillColor: "#bf84d7" },
  "PREM-REISS": { fillColor: "#74e19c" },
  // "PREM-REISS-LAM": { fillColor: "#00d368" },
  "ARCH-LAM": { fillColor: "#e3e569" },
  "ARCH-LAM-TS": { fillColor: "#e3e579" },
  ARCH_LAM: { fillColor: "#e3e569" },
  ARCH_LAM_TS: { fillColor: "#e3e579" },
  "CERT-SUPPORTING-LETTER": { fillColor: "#f9bb80" },
};

export const initialsPerSku = {
  // CERT: "C",
  // PREM: "PC",
  // "CERT-EMAIL-COPY": "E",
  PAPERCOPY: "P",
  DOCWALLET: "DW",
  "CERT-AM-FOLDER": "AM",
  TECHSPEC: "TS",
  // "CERT-REISS": "C",
  // "PREM-REISS": "PC",
  "ARCH-LAM": "L",
  "ARCH-LAM-TS": "LTS",
  ARCH_LAM: "L",
  ARCH_LAM_TS: "LTS",
  "CERT-SUPPORTING-LETTER": "SL",
};

function checkFlags({ order, research }) {
  const mergeJSON = JSON.stringify({ order, research })
    .toLowerCase()
    .replace(/[^a-z0-9"]+/g);
  return store.flagArray?.filter((f) =>
    mergeJSON.includes(f.flagText.toLowerCase().replace(/[^a-z0-9"]+/g))
  );
}

function PDFCustomerInfo(doc, order, research) {
  doc.addPage("a4");

  doc.setFontSize(24);
  doc.text(order.name, 15, 20);
  if (research.certsInOrder > 1) {
    doc.setFontSize(12);
    doc.text(
      `certificate ${research.certNumberWithinOrder + 1} of ${
        research.certsInOrder
      }`,
      155,
      20
    );
  }

  doc.line(15, 21, 195, 21);

  // const flags = [
  //   {
  //     flagText: "a",
  //     flaggedBy: "tristan.moore@britishmotormuseum.co.uk",
  //     reason: "Looks sus",
  //   },
  //   { flagText: "b", flaggedBy: "tris", reason: "What the b" },
  // ];
  const flags = checkFlags({ order, research });
  if (flags?.length > 0) {
    doc.setFontSize(24);
    doc.setTextColor("#ff0000");
    doc.text("FLAGGED!", 150, 32);
    doc.setFontSize(9);
    let y = 30;
    for (const flag of flags) {
      doc.text(
        `Contains "${flag.flagText}", flagged ${format(
          fromUnixTime(flag.flagCreatedTimestamp),
          "d MMM yyyy"
        )} by ${flag.flaggedBy.replace(/@britishmotormuseum.co.uk/, "")}: ${
          flag.reason
        }`,
        15,
        y
      );
      y += 4;
    }
    doc.setTextColor("#000000");
  } else {
    doc.setFontSize(18);
    doc.text("Products Purchased", 15, 32);
  }

  // find VIN and link products for boldness
  const researchCustomerVIN = findOne(
    order.line_items[research.lineItemIndex].properties,
    "name",
    "vin",
    { value: "" }
  ).value;

  let startY = 40;
  let y = startY;
  let rows = [];
  const line_item_keys = Object.keys(order.line_items);
  for (let i = 0; i < line_item_keys.length; ++i) {
    const li = order.line_items[line_item_keys[i]];
    let title = li.title;

    const isCurrentItem =
      line_item_keys[i].toString() === research.lineItemIndex.toString();

    const isLinkedToCurrentItem =
      findOne(li.properties, "name", "_vin", { value: "no VIN given!" })
        .value === researchCustomerVIN;

    // list out all properties, apart from ones begining with underscore
    if (isCurrentItem) {
      title += "\n";
      for (let p of li.properties.filter((p) => p.name[0] !== "_")) {
        // vin isn't actually a vin in a reissue - it'll be a copy of previous_certificate_number
        if (p.name === "vin" && li.sku.includes("REISS")) continue;
        if (p.name === "confirmCheck") continue; // they must have done this
        title += `\n${p.name}: ${p.value}`;
        y += 5;
      }
    }

    // 2022-09-27: archive now want these items completely ignored, not greyed
    if (!isCurrentItem && !isLinkedToCurrentItem) continue;

    // show the current research line item normally, others faded out
    const styles = {
      textColor: isCurrentItem || isLinkedToCurrentItem ? 20 : 200,
    };
    rows.push([
      {
        content: ``,
        styles: { ...stylesPerSku[li.sku], cellWidth: 10 },
      },
      { content: `${li.quantity} x`, styles: { ...styles, cellWidth: 10 } },
      { content: title, styles },
      { content: `£${li.price}`, styles },
    ]);
    y += 7.5;
  }

  const shipping_lines_keys = Object.keys(order.shipping_lines);
  if (research.certsInOrder === 1) {
    for (let i = 0; i < shipping_lines_keys.length; ++i) {
      const sli = order.shipping_lines[shipping_lines_keys[i]];
      rows.push(["", "", sli.title, `£${sli.price}`]);
      y += 7.5;
    }
  } else {
    for (let i = 0; i < shipping_lines_keys.length; ++i) {
      const sli = order.shipping_lines[shipping_lines_keys[i]];
      rows.push([
        { content: "", styles: { fillColor: 200 } },
        { content: "", styles: { fillColor: 200 } },
        {
          content: `(multiple certs in order): ${sli.title}`,
          styles: { fillColor: 200 },
        },
        { content: `£${sli.price}`, styles: { fillColor: 200 } },
      ]);
      y += 7.5;
    }
  }
  y += 7.5; //header

  doc.autoTable({
    theme: "grid",
    columnStyles: { 2: { halign: "right" }, 3: { halign: "right" } },
    startY,
    rowPageBreak: "avoid",
    head: [
      [
        { content: "", styles: { minCellWidth: 7 } },
        { content: "Qty", styles: { minCellWidth: 10 } },
        { content: "Item" },
        { content: "Price", styles: { minCellWidth: 18 } },
      ],
    ],
    body: rows,
  });

  // add order notes (especially useful for manually-entered orders, i.e. cheque payments)
  if (order.note) {
    y += 20;
    doc.setFontSize(18);
    doc.text("Note", 15, y);
    y += 7.5;
    doc.setFontSize(10);
    doc.text(order.note, 15, y);
  }

  // web research doesn't have shipping, so we must check if we have any
  if (
    order.shipping_address &&
    Object.keys(order.shipping_address).length > 0
  ) {
    doc.setFontSize(18);
    y += 20;
    doc.text("Shipping details", 15, y);
    rows = [];
    y += 7.5;
    startY = y;
    doc.setFontSize(10);
    const addressKeys = [
      "name",
      "company",
      "company",
      "address1",
      "address2",
      "street",
      "city",
      "zip",
      "province",
      "country",
    ];
    const addressLines = addressKeys
      .map((k) => {
        const a = order.shipping_address[k];
        if (k === "province" && a === "England") return null;
        if (k === "country" && a === "United Kingdom") return null;
        return a;
      })
      .filter(Boolean);
    y += 5 * addressLines.length;
    doc.autoTable({ startY, theme: "grid", body: [[addressLines.join("\n")]] });
  }
}

export default PDFCustomerInfo;
