import { DocumentDuplicateIcon, DocumentIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { ButtonPrimary, ButtonSecondary } from "./Buttons";
import MessageDialogWithActions from "./MessageDialogWithActions";
import { certPOST } from "lib/API";
import Loading from "./Loading";
import Toggle from "./Toggle";

function CreateACopyDialog({ setDialogOpen, cert }) {
  const [creating, setCreating] = useState(false);
  const [certNumber, setCertNumber] = useState(cert ? undefined : null);
  const [products, setProducts] = useState([]);

  const possibleProducts = {
    PREM: "Premier certificate",
    EMAIL: "Email copy",
    PAPERCOPY: "Paper copy",
  };

  function toggleProduct(p) {
    if (products.includes(p))
      setProducts(products.filter((prod) => prod !== p));
    else setProducts([...products, p]);
  }

  function doCopy() {
    setCreating(true);
    certPOST("createACopy", { certNumber, products, cert }).then(async (r) => {
      if (r.ok) {
        const { certId } = await r.json();
        console.log(`waiting before navigating to /suspended/${certId}`);
        setTimeout(() => {
          window.location.href = `/suspended/${certId}`;
        }, 1000);
      }
    });
  }

  if (creating)
    return (
      <MessageDialogWithActions
        name="createACopyCreating"
        actions={[]}
        message={<Loading text="Creating new certificate..." />}
      />
    );

  return (
    <MessageDialogWithActions
      name="createACopy"
      actions={[
        { text: "Cancel", onClick: () => setDialogOpen(false) },
        ...(certNumber !== undefined
          ? [{ text: "Create", colour: "green", onClick: doCopy }]
          : []),
      ]}
      message={
        <>
          <h2 className="text-xl border-b border-slate-300">
            Create a {cert ? "copy" : "blank"}
          </h2>
          {certNumber === undefined ? (
            <div>
              <ButtonSecondary
                className="m-2"
                onClick={() => setCertNumber(null)}
              >
                New certificate number
              </ButtonSecondary>
              {cert && cert.certNumber && (
                <ButtonSecondary
                  className="m-2"
                  onClick={() => setCertNumber(cert.certNumber)}
                >
                  Use {cert.certNumber}
                </ButtonSecondary>
              )}
            </div>
          ) : (
            <>
              <p className="m-2">
                Cert number: <b>{certNumber ?? "new number"}</b>
              </p>
              <p>Products:</p>
              {Object.entries(possibleProducts).map(([k, label]) => (
                <Toggle
                  key={k}
                  label={label}
                  value={products.includes(k)}
                  setValue={() => toggleProduct(k)}
                />
              ))}
            </>
          )}
        </>
      }
    />
  );
}

export default function CreateACopyButton({ cert }) {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      {dialogOpen && (
        <CreateACopyDialog cert={cert} setDialogOpen={setDialogOpen} />
      )}
      <ButtonPrimary onClick={() => setDialogOpen(true)} className="m-2">
        <DocumentDuplicateIcon className="w-6 h-6 mr-2" />
        Create a copy…
      </ButtonPrimary>
    </>
  );
}

function CreateABlankButton() {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      {dialogOpen && (
        <CreateACopyDialog cert={null} setDialogOpen={setDialogOpen} />
      )}
      <ButtonPrimary onClick={() => setDialogOpen(true)} className="m-2">
        <DocumentIcon className="w-6 h-6 mr-2" />
        Create Blank
      </ButtonPrimary>
    </>
  );
}

export { CreateABlankButton };
