import Loading from "components/Loading";
import useStoreLoader from "lib/useStoreLoader";
import { pluralN } from "lib/useful";

export default function LoadResearches({ ids }) {
  const { isLoading } = useStoreLoader({
    collection: "certificateResearch",
    find: { _id: { $in: ids } },
    merge: true,
  });
  return isLoading ? (
    <Loading text={`Loading ${pluralN(ids.length, "certificate")} data`} />
  ) : null;
}
