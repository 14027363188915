import { useState } from "react";
import { collect, useProps } from "react-recollect";
import MessageDialogWithActions from "components/MessageDialogWithActions";
import Loading from "components/Loading";
import { certPOST } from "lib/API";
import FullsizeMessage from "components/FullsizeMessage";
import Page from "components/Page";
import OrderLine from "components/OrderLine";
import HeaderBar from "components/HeaderBar";
import useLoad from "lib/useLoad";
import { Button, ButtonPrimary } from "components/Buttons";
import LoadShopifyOrders, {
  needToLoadShopifyOrders,
} from "components/loaders/LoadShopifyOrders";
import PDFCustomerInfoAndSlip from "PDFCreators/PDFCustomerInfoAndSlip";
import { ChevronDoubleRightIcon, PrinterIcon } from "@heroicons/react/outline";

function CertificatesNew({ store }) {
  useProps([store.shopifyOrders]); // for some reason recollect doesn't detect us reading this
  const [showingPrintedMessage, setShowingPrintedMessage] = useState(false);
  const [showingSettingMessage, setShowingSettingMessage] = useState(false);
  const { data: researches, reload } = useLoad("certificateResearch", {
    status: "new",
  });

  if (researches === undefined) return <Loading text="Loading New orders" />;
  if (researches.length === 0)
    return <FullsizeMessage>No new orders!</FullsizeMessage>;
  if (showingSettingMessage)
    return <Loading text="Setting order statuses to 'typing'..." />;

  const shopifyOrderIds = researches.map((r) => r.shopifyOrdersId);
  if (needToLoadShopifyOrders(shopifyOrderIds, store))
    return <LoadShopifyOrders ids={shopifyOrderIds} />;

  function setOrdersAsBeingResearched(id = null) {
    setShowingSettingMessage(true);
    const ids = id ? [id] : researches.map((r) => r._id);
    certPOST("setStatus", { status: "typing", ids }).then((r) => {
      setShowingPrintedMessage(false);
      setShowingSettingMessage(false);
      reload();
    });
  }

  return (
    <Page title="New" heading="New orders from Shopify">
      {showingPrintedMessage && (
        <MessageDialogWithActions
          name="printMessage"
          message="Did the orders print correctly?"
          actions={[
            {
              text: "Yes (set all to typing)",
              colour: "green",
              onClick: () => setOrdersAsBeingResearched(),
            },
            {
              text: "No",
              colour: "red",
              onClick: () => setShowingPrintedMessage(false),
            },
          ]}
        />
      )}
      <HeaderBar>
        <ButtonPrimary
          onClick={() => {
            setShowingPrintedMessage(true);
            PDFCustomerInfoAndSlip(researches);
          }}
        >
          <PrinterIcon className="w-6 h-6" />
          &nbsp; Print all new orders
        </ButtonPrimary>
      </HeaderBar>
      <div>
        {researches.map((r) => (
          <OrderLine key={r._id} r={r}>
            <div className="btn-group">
              <Button onClick={() => PDFCustomerInfoAndSlip([r])}>
                <PrinterIcon className="w-6 h-6" />
                &nbsp;Print order
              </Button>
              <ButtonPrimary onClick={() => setOrdersAsBeingResearched(r._id)}>
                <ChevronDoubleRightIcon className="w-6 h-6" />
                &nbsp;Set to "typing up"
              </ButtonPrimary>
            </div>
          </OrderLine>
        ))}
      </div>
    </Page>
  );
}

export default collect(CertificatesNew);
