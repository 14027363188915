import { buttonClasses } from "lib/constants";
import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

// given an array of actions, and a message
// display a dialog with the message
// and a set of buttons underneath

// actions array is like:
// actions = [
// 	{ text: 'Cancel', onClick: () => setShowConfirmation(false) },
// 	{ text: 'Yes', colour: "red", onClick },
// ];

// wide renders it at 11/12ths (92%), otherwise max-width is 32rem on desktop or full width when <640px

function MessageDialogWithActions({
  name,
  message,
  actions,
  wide,
  actionLineAdditionalContent,
}) {
  // render it at #portal, right at the body node, so it goes above everything
  return ReactDOM.createPortal(
    <div>
      <input
        type="checkbox"
        id={`dialog${name}`}
        className="modal-toggle"
        defaultChecked
      />
      <div className="modal">
        <div
          className={`modal-box flex flex-col${
            wide ? " w-11/12 max-w-none" : ""
          }`}
        >
          <div className="flex-1 overflow-y-auto">{message}</div>
          <div className="flex-0 modal-action">
            {actionLineAdditionalContent}
            {actions.map((o, oi) => (
              <button
                key={oi}
                onClick={o.onClick}
                className={buttonClasses[o.colour || "default"]}
                disabled={o.disabled}
              >
                {o.text}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
}
MessageDialogWithActions.propTypes = {
  name: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  actions: PropTypes.array.isRequired,
  wide: PropTypes.bool,
};
MessageDialogWithActions.defaultProps = {
  wide: false,
};

export default MessageDialogWithActions;
