import { store } from "react-recollect";
import RenderFieldColumn from "./RenderFieldColumn";
import { Tooltip } from "./Tooltip";

export default function RenderFieldSharonShortcutTextarea({
  label,
  onChange,
  value,
  onBlur,
  doAlphaNumericCapitalise,
}) {
  const handleChangeWithReplacements = (_v) => {
    let v = _v;
    for (let { key, value } of store.settingsMap["sharonShortcuts"]) {
      v = v.replace(key, value);
    }

    if (doAlphaNumericCapitalise) {
      // a letter with a number preceding will ALWAYS be uppercase
      // 543d --> 543D
      // or letter(s) with a number after
      // dfkj435345FVKJ -> DFKJ435345FVKJ
      // loop round so that all replacements are made in one go
      let e = 99;
      let ov = "";
      while (--e && v !== ov) {
        ov = v;
        v = v.replace(/[0-9][A-Z.,\-/\\]*([a-z])/, (s) => s.toUpperCase());
        v = v.replace(/([a-z])[.,\-/\\]*[0-9A-Z]+/, (s) => s.toUpperCase());
      }
    }

    onChange(v);
  };

  return (
    <RenderFieldColumn
      leftContent={
        <>
          <div>{label}</div>
          <Tooltip
            text={store.settingsMap["sharonShortcuts"]
              .map(({ key }) => key)
              .join(" · ")}
          >
            <div className="text-sm text-black/60">Use shortcuts</div>
          </Tooltip>
        </>
      }
    >
      <textarea
        className="textarea h-24 textarea-bordered w-full"
        onChange={(e) => handleChangeWithReplacements(e.target.value)}
        value={value}
        onBlur={onBlur}
      />
    </RenderFieldColumn>
  );
}
