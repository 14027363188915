import { useState } from "react";
import { ButtonSecondary } from "components/Buttons";
import EmailDialog from "./EmailDialog";
import { collect, store } from "react-recollect";
import { PaperAirplaneIcon } from "@heroicons/react/outline";

function EmailDialogButton({ fulfilmentId, autoSend }) {
  const [open, setOpen] = useState(false);
  const fulfilment = store["fulfilments-email"][fulfilmentId];
  const research = store.certificateResearch[fulfilment.researchId];
  const shOrder = store.shopifyOrders[research.shopifyOrdersId];
  const researchData = store.researchDataByCertId[fulfilment.researchId];

  return (
    <>
      {(open || autoSend) && (
        <EmailDialog
          key={`${fulfilment._id}_${shOrder.name}_${researchData.certNumber}`} // key it so it unmounts when fulfilment changes
          fulfilment={fulfilment}
          shOrder={shOrder}
          researchData={researchData}
          handleClose={() => setOpen(false)}
          autoSend={autoSend}
        />
      )}
      <ButtonSecondary onClick={() => setOpen(true)}>
        <PaperAirplaneIcon className="w-6 h-6 mr-2" />
        {autoSend ? "Auto emailing" : "Email"}
      </ButtonSecondary>
    </>
  );
}

export default collect(EmailDialogButton);
