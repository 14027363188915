import { useDebouncedEffect } from "lib/useDebouncedEffect";
import { newPDF } from "PDFCreators/common";
import PDFGenericLayout from "PDFCreators/PDFGenericLayout";
import { useState } from "react";

function LayoutPDFView({ certificateData, layoutData, afterGenerated }) {
  const [generatedPDF, setGeneratedPDF] = useState(null);

  useDebouncedEffect(
    () => {
      const doc = newPDF();
      PDFGenericLayout(doc, layoutData, certificateData, afterGenerated);
      setGeneratedPDF(doc.output("datauristring"));
    },
    [layoutData, certificateData],
    1000
  );

  return (
    <iframe title="Layout view" src={generatedPDF} className="w-full h-full" />
  );
}

export default LayoutPDFView;
