import { useEffect, useState } from "react";
import { certGET } from "./API";
import { isDev } from "./useful";

function useLoad(collection, find = {}, options) {
  const { onLoad } = Object.assign({ onLoad: () => {} }, options);
  const [data, setData] = useState(undefined);
  const [reloadCounter, setReloadCounter] = useState(0);

  const reload = () => {
    setData(undefined);
    setReloadCounter(reloadCounter + 1);
  };

  // in JS, {}!=={} so we have to stringify it to check
  const findStringified = JSON.stringify(find);

  useEffect(() => {
    let timerHandle = null;
    let url = "load/" + collection;
    if (Object.keys(find).length > 0) url += "?find=" + JSON.stringify(find);
    certGET(url)
      .then((r) => {
        if (!r.ok) throw r;
        return r.json();
      })
      .then((returnedItems) => {
        timerHandle = setTimeout(() => {
          setData(returnedItems);
          if (onLoad) onLoad(returnedItems);
          timerHandle = null;
        }, (isDev() ? 50 : 500) + 500 * Math.random());
      });

    return () => {
      if (timerHandle) {
        console.warn(
          `Cancelling timer that was doing useLoad(${collection},${findStringified})`
        );
        clearTimeout(timerHandle);
      }
    };
    // we use findStringified instead of find as a dependancy
    // eslint-disable-next-line
  }, [collection, findStringified, reloadCounter]);

  return { data, reload };
}

export default useLoad;
