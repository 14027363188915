import React from "react";
import { store } from "react-recollect";
import Loading from "components/Loading";
import useStoreLoader from "lib/useStoreLoader";

function mapResearchDataByCertId(returnedItems, mightBeEmptyKeys) {
  if (mightBeEmptyKeys && returnedItems.length === 0) {
    store["researchDataByCertId"] = {
      ...(store["researchDataByCertId"] || {}),
      ...mightBeEmptyKeys.reduce((p, c) => ({ ...p, [c]: {} }), {}),
    };
    return;
  }

  const map = {
    ...(store["researchDataByCertId"] || {}),
    ...returnedItems.reduce((a, c) => ({ ...a, [c._certId]: c }), {}),
  };
  store["researchDataByCertId"] = map;
}

// mightBeEmpty set when {} is a perfectly acceptable result
//  - i.e. on Standalone when the research might not have been done yet
export default function LoadResearchData({ ids, mightBeEmpty }) {
  const { isLoading } = useStoreLoader({
    collection: "researchData",
    find: { _certId: { $in: ids } },
    merge: true,
    onLoad: (d) => mapResearchDataByCertId(d, mightBeEmpty ? ids : false),
  });
  return isLoading ? (
    <Loading text="Loading certificate research data" />
  ) : null;
}
