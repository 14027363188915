import format from "date-fns/format";
import { findOneInArray } from "lib/useful";
import { store } from "react-recollect";

function shortcuterise(sc) {
  // check if this value is a shortcut, and return the long version if it is
  if (!sc) return sc;
  const scUpper = sc.replace(/[/-]*/g, "").trim().toUpperCase();
  const l = store.settingsMap["shortcuts"].find((s) => scUpper === s.short);
  if (l) return l.long;
  return sc;
}

async function PDFGenericLayout(
  doc,
  layoutData,
  fieldData,
  afterGenerated = null
) {
  doc.addPage();

  if (layoutData.backgroundImage) {
    const backgroundImage = new Image();
    backgroundImage.src = layoutData.backgroundImage;
    doc.addImage(backgroundImage, "JPEG", 0, 0, 210, 297);
  }

  let y = 0;
  let dbgy = 1;

  for (let fieldKey of layoutData.fieldOrder) {
    const l = layoutData[fieldKey];
    if (!l || !l.show) continue;

    let ex = fieldData[fieldKey];
    if (ex === undefined) ex = "";
    ex = ex.trim();
    ex = shortcuterise(ex);
    if (ex.endsWith(",")) ex = ex.substr(0, ex.length - 1);
    if (l.valueWhenEmpty && (!ex || ex === "")) ex = l.valueWhenEmpty;
    if (ex === "-") ex = "—"; // mdash looks nicer
    if (ex === "$today" || fieldKey === "dateOfIssue")
      ex = format(new Date(), "dd MMMM yyyy");
    if (fieldKey !== "signature" && !l.showWhenEmpty && (!ex || ex === ""))
      continue;
    if (ex && ex.startsWith("-noshow-")) continue;

    // makeAndModel is a "chooser" - look up the actual value
    if (fieldKey === "makeAndModel") {
      ex = findOneInArray(store.settingsMap[fieldKey], "key", ex, {
        value: `not found "${fieldKey}/${ex}"`,
      }).value;
    }

    const lx = parseFloat(l.lx);
    const ly = parseFloat(l.ly);
    const fx = parseFloat(l.fx);
    const fy = parseFloat(l.fy);

    if (false /*debug*/) {
      doc.setFontSize(3);
      doc.text(
        `y${y},fK${fieldKey},ex${ex},l${lx}/${ly},f${fx}/${fy}`,
        2,
        (dbgy += 2)
      );
    }

    // useful for laying out premier certs...
    // draw a box instead of field label/data
    if (
      fieldKey === "debugA6" ||
      fieldKey === "debug6x4" ||
      fieldKey === "debugA7"
    ) {
      let w = 10,
        h = 10,
        c = "#ff4411",
        t = "debug box";
      if (fieldKey === "debugA6") {
        w = 148;
        h = 105;
        t = "A6";
        c = "#4499ff";
      }
      if (fieldKey === "debug6x4") {
        w = 152.4;
        h = 101.6;
        t = "6x4";
        c = "#00bb77";
      }
      if (fieldKey === "debugA7") {
        t = "A7";
        c = "#9944ff";
        w = 105;
        h = 74;
      }
      doc.setFont(undefined, "", "bold");
      doc.setFontSize(20);
      const txtSize = doc.getTextDimensions(t);
      const tw = parseFloat(txtSize.w);
      const th = parseFloat(txtSize.h);
      doc.setDrawColor(c);
      doc.setTextColor(c);
      doc.rect(lx, ly, w, h);
      doc.text(t, lx + w / 2 - tw / 2, ly + h / 2 - th / 2);
      doc.setDrawColor(0);
      doc.setTextColor(0);
      continue;
    }

    // always insert default values (because jsPDF will crash if you send undefined)
    // but make the defaults stupid so they're not accidentally used
    doc.setFontSize(l.lsize || 12);
    doc.setFont(undefined, "", l.lbold ? "bold" : "normal");

    if (!l.belowPrev) y = ly || 0;
    else y += parseFloat(l.lineHeight);
    if (typeof y !== "number") console.log("1", fieldKey, y, l.lineHeight);

    let x = lx || 20;
    if (l.lUseGlobalColumn)
      x = (layoutData.globalColumnHorizPos || 0) + (lx || -2.5);
    if (l.lRightAlign) x = x - doc.getTextDimensions(l.label).w;
    if (!x || isNaN(x)) throw Error(`Invalid x coord: ${x}, for ${fieldKey}`);
    if (!y || isNaN(y)) throw Error(`Invalid y coord: ${y}, for ${fieldKey}`);
    doc.text(l.label, x, y);

    doc.setFontSize(l.fsize || 12);
    doc.setFont(undefined, "", l.fbold ? "bold" : "normal");
    x = fx || 20;
    if (l.fUseGlobalColumn)
      x = (layoutData.globalColumnHorizPos || 0) + (fx || 2.5);

    // use a hard right margin too - premier certs have a right margin in the middle!
    const widthRemaining = Math.min(
      doc.internal.pageSize.getWidth() -
        x -
        (l.useMargin ?? true ? layoutData.marginR : 0),
      doc.internal.pageSize.getWidth() - 15 - x
    );
    const dataStringArray = doc.splitTextToSize(ex, widthRemaining);

    const ty = l.sameAsTitle ? y : fy || 20;
    if (!x || isNaN(x)) throw Error(`Invalid x coord: ${x}, for ${fieldKey}`);
    if (!ty || isNaN(ty))
      throw Error(`Invalid y coord: ${ty}, for ${fieldKey}`);

    if (
      fieldKey === "signature" &&
      l.valueWhenEmpty &&
      l.valueWhenEmpty[0] === "/"
    ) {
      const signatureImage = new Image();
      signatureImage.src = l.valueWhenEmpty; //"/signature.jpg";
      doc.addImage(signatureImage, "JPEG", x, ty - 5);
    } else {
      doc.text(dataStringArray, x, ty);
      const textHeight = parseFloat(doc.getTextDimensions("X").h);
      if (!textHeight || isNaN(textHeight)) throw Error("Invalid text height");
      if (!Array.isArray(dataStringArray) || dataStringArray.length < 1)
        throw Error("Invalid dataStringArray");
      y += textHeight * (dataStringArray.length - 1) * 1.15; // 1.15 is default line-height
      if (typeof y !== "number") console.log("2", fieldKey, y, textHeight);
    }
  }

  if (afterGenerated) await afterGenerated(doc);
  return doc;
}

export default PDFGenericLayout;
