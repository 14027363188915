import { batch, store } from "react-recollect";
import { Button, ButtonPrimary } from "./Buttons";
import { EmojiSadIcon, LoginIcon } from "@heroicons/react/outline";
import { useRef, useState } from "react";
import Input from "./Input";
import { certPOSTj } from "lib/API";
import Toggle from "./Toggle";
import Spinner from "./Spinner";
import { tryToLoadToken } from "./Login";

export function LoginForm() {
  const [loggingIn, setLoggingIn] = useState(false);
  const [user, setUser] = useState(window.localStorage.getItem("user") || "");
  const [pass, setPass] = useState("");
  const [rememberMe, setRememberMe] = useState(user.length > 0);
  const [error, setError] = useState("");
  const passwordRef = useRef();

  async function login() {
    setLoggingIn(true);
    if (rememberMe) window.localStorage.setItem("user", user);
    else window.localStorage.removeItem("user");

    const result = await certPOSTj("login", { user, pass });
    if (!result || !result.token) {
      setError("Invalid username or password");
      setTimeout(() => setLoggingIn(false), 1000);
    } else {
      const tokenInfo = tryToLoadToken(result.token);
      batch(() => {
        store.token = tokenInfo.token;
        store.loggedInUser = tokenInfo.loggedInUser;
        window.localStorage.setItem("token", tokenInfo.token);
      });
    }
  }
  return (
    <div className="border-2 border-slate-900 shadow-lg p-4 m-auto bg-slate-600 w-96">
      {error && (
        <p className="bg-red-900 border-2 border-red-500 p-2 shadow-md shadow-red-700 mb-4">
          {error}
        </p>
      )}
      <Input
        labelClassName="text-white"
        label="username / email"
        value={user}
        setValue={setUser}
        autoFocus
        disabled={loggingIn}
        onEnter={pass.length > 0 ? login : passwordRef?.current?.focus}
      />
      <Input
        labelClassName="text-white"
        label="password"
        type="password"
        value={pass}
        setValue={setPass}
        disabled={loggingIn}
        onEnter={login}
        ref={passwordRef}
      />
      <Toggle
        label="Remember me"
        value={rememberMe}
        setValue={setRememberMe}
        disabled={loggingIn}
        className="toggle-primary my-2"
        labelClassName="text-white"
      />
      <div className="btn-group float-right">
        <Button
          onClick={() => (window.location.search = "?forgot")}
          disabled={loggingIn}
        >
          <EmojiSadIcon className="w-6 h-6" />
          &nbsp;&nbsp;Forgot password&nbsp;
        </Button>
        <ButtonPrimary onClick={login} disabled={loggingIn}>
          {loggingIn ? <Spinner /> : <LoginIcon className="w-6 h-6" />}
          &nbsp;&nbsp;Log in&nbsp;
        </ButtonPrimary>
      </div>
    </div>
  );
}
