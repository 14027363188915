import "lib/wdyr.js";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./index.css";
import App from "./App";
import { isDev } from "lib/useful";

const packageJSON = require("../package.json");
console.log(
  "CertSystem client v" + packageJSON.version + (isDev() ? "dev" : "")
);

if (!isDev()) {
  console.log("Starting Sentry in production mode");
  Sentry.init({
    dsn: "https://430e24aadc7345ac91f97cdaf6eb64d6@sentry.trismoore.com/5",
    integrations: [new BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
} else {
  console.log("%cisDev (no Sentry)", "font-size:200%;color:#0d1");
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
