import { useState } from "react";
import {
  PaperAirplaneIcon,
  SaveIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { formatDistanceToNow } from "date-fns";
import { certPOSTj } from "lib/API";
import { formatNicelyDateTime } from "lib/useful";
import {
  ButtonError,
  ButtonPrimary,
  ButtonSecondary,
} from "../components/Buttons";
import Input from "../components/Input";
import Notes from "../components/Notes";
import Page from "../components/Page";
import RenderFieldColumn from "../components/RenderFieldColumn";
import { store } from "react-recollect";
import Loading from "../components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import useLoad from "lib/useLoad";
import LoadShopifyOrders, {
  needToLoadShopifyOrders,
} from "components/loaders/LoadShopifyOrders";
import FullsizeMessage from "components/FullsizeMessage";
import RenderFieldSharonShortcutTextarea from "components/RenderFieldSharonShortcutTextarea";

function WebResearchTyping() {
  const navigate = useNavigate();
  const params = useParams();
  const _id = params.id;

  const [typingDataState, setTypingData] = useState(null);
  const [doingThings, setDoingThings] = useState(null);

  const { data: webResearches } = useLoad("webResearch", { _id });
  if (webResearches === undefined) return <Loading text="Loading New orders" />;
  const shopifyOrderIds = webResearches.map((r) => r.shopifyOrdersId);
  if (needToLoadShopifyOrders(shopifyOrderIds, store))
    return <LoadShopifyOrders ids={shopifyOrderIds} />;

  if (webResearches.length !== 1)
    return (
      <FullsizeMessage>{webResearches.length} researches?</FullsizeMessage>
    );
  const research = webResearches[0];
  const order = store.shopifyOrders[research.shopifyOrdersId];

  const first_name = order?.customer?.first_name || "";

  const typingData = typingDataState ||
    research.typingData || {
      greeting: first_name ? `Dear ${first_name},\n` : "",
      answer: "",
      notes: [],
    };

  async function saveMaybeSend(sendSaveOrCancel) {
    setDoingThings(sendSaveOrCancel === "send" ? "Sending" : "Saving");
    const r = await certPOSTj(`webResearch/${sendSaveOrCancel}`, {
      _id: research._id,
      typingData,
    });
    if (!r.ok) return (store.error = r.error);

    navigate("/web/new");
  }

  return (
    <Page
      title={`${research.name} · WebResearch`}
      heading={`Web Research — ${research.name}`}
    >
      {doingThings ? (
        <Loading text={doingThings} />
      ) : (
        <>
          <Notes
            notes={typingData.notes}
            setNotes={(notes) => setTypingData({ ...typingData, notes })}
          />
          <div className="p-4">
            <h2 className="text-xl mb-1">Customer supplied info:</h2>
            {research.customerInfo.map((info) => (
              <RenderFieldColumn key={info.name} leftContent={info.name}>
                <div className="w-full pt-3 pb-1 border-t whitespace-pre-line">
                  {info.value}
                </div>
              </RenderFieldColumn>
            ))}
            <h2 className="text-xl mb-1">Answer:</h2>
            <RenderFieldColumn leftContent="Greeting">
              <Input
                value={typingData.greeting}
                setValue={(greeting) =>
                  setTypingData({ ...typingData, greeting })
                }
              />
            </RenderFieldColumn>
            <RenderFieldSharonShortcutTextarea
              label="Answer"
              value={typingData.answer}
              onChange={(answer) => setTypingData({ ...typingData, answer })}
            />
            <div className="flex m-2">
              <div className="flex-1"></div>
              <ButtonError onClick={() => saveMaybeSend("cancel")}>
                <XCircleIcon className="w-6 h-6" />
                &nbsp;Remove request (cancel order)
              </ButtonError>
              <ButtonSecondary
                onClick={() => saveMaybeSend("save")}
                className="mx-2"
              >
                <SaveIcon className="w-6 h-6" />
                &nbsp;Save (don't send)
              </ButtonSecondary>
              <ButtonPrimary onClick={() => saveMaybeSend("send")}>
                <PaperAirplaneIcon className="w-6 h-6" />
                &nbsp;Send email
              </ButtonPrimary>
            </div>
            <h2 className="text-xl mb-1">Additional info:</h2>
            {[
              { l: "Email", r: order.email },
              {
                l: "Shopify order",
                r: (
                  <a
                    href={`https://certificates-3.myshopify.com/admin/orders/${order.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline text-blue-900"
                  >
                    {order.id} (click to view on Shopify)
                  </a>
                ),
              },
              {
                l: "Order created at",
                r: `${formatNicelyDateTime(
                  new Date(order.created_at)
                )} (${formatDistanceToNow(new Date(order.created_at), {
                  addSuffix: true,
                })})`,
              },
              {
                l: "Order line items",
                r: (
                  <ul>
                    {order.line_items.map((l, i) => (
                      <li
                        key={l.id}
                        className={
                          i === research.lineItemIndex ? "" : "text-slate-400"
                        }
                      >
                        {l.name}
                      </li>
                    ))}
                  </ul>
                ),
              },
            ].map(({ l, r }) => (
              <RenderFieldColumn key={l} leftContent={l}>
                <div className="mt-3">{r}</div>
              </RenderFieldColumn>
            ))}
            <pre className="hidden">
              {JSON.stringify({ typingData, research, order }, null, 2)}
            </pre>
          </div>
        </>
      )}
    </Page>
  );
}
WebResearchTyping.wdyr = {
  logOnDifferentValues: true,
};
export default WebResearchTyping;
