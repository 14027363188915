import { Link, useLocation } from "react-router-dom";
import { store } from "react-recollect";

function MenuItem({ m }) {
  const location = useLocation();

  if (m.auth && !store.auths.includes(m.auth)) return null;

  const currentPath = location.pathname;

  const menuChildren = m.c
    ? m.c.map((m) => <MenuItem key={m.t} m={m} />)
    : null;

  if (m.p)
    return (
      <li
        className={
          (m.p === "/" && currentPath === "/") ||
          (m.p !== "/" && currentPath.startsWith(m.p))
            ? "border-r-4 border-r-green-700 bg-slate-300"
            : ""
        }
      >
        <Link to={m.p}>{m.t}</Link>
        {menuChildren}
      </li>
    );
  if (menuChildren)
    return (
      <>
        <li></li>
        <li className="menu-title">
          <span>{m.t}</span>
        </li>
        {menuChildren}
      </>
    );
  return (
    <li>
      <span>{m.t} (!m.p and !m.c?)</span>
    </li>
  );
}

export default MenuItem;
