import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default function OrderLine({ r, children, linkTo }) {
  const complete = r.complete;

  const content = (
    <div
      className={`${
        linkTo ? "cursor-pointer hover:bg-slate-100 hover:border-blue-500 " : ""
      }${
        complete
          ? "transition-all h-0 p-0 overflow-hidden m-0 shadow-none"
          : "border p-4 m-2 bg-white shadow"
      }`}
    >
      <h4 className="text-xl">
        {r.certNumber ? `${r.certNumber} (${r.name})` : r.name}
      </h4>
      {r.certsInOrder > 1 && (
        <p>
          ({r.certNumberWithinOrder + 1} of {r.certsInOrder})
        </p>
      )}
      <p>{r.lineItemName}</p>
      {/* <pre>{JSON.stringify(r, null, 2)}</pre> */}
      {children}
    </div>
  );

  if (linkTo) return <Link to={linkTo}>{content}</Link>;
  else return content;
}

OrderLine.propTypes = {
  children: PropTypes.node,
  linkTo: PropTypes.string,
  r: PropTypes.object.isRequired,
};
