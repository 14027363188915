import { useState } from "react";
import { ButtonPrimary } from "./Buttons";
import MessageDialogWithActions from "./MessageDialogWithActions";
import SettingsOrderDrag from "./SettingsOrderDrag";

export default function SettingsOrderDialogButton({ value, onSave, children }) {
  const [open, setOpen] = useState(false);
  const [localOrder, setLocalOrder] = useState(value);

  function handleSave() {
    onSave(localOrder);
    setOpen(false);
  }

  return (
    <>
      <ButtonPrimary onClick={() => setOpen(true)}>{children}</ButtonPrimary>
      {open && (
        <MessageDialogWithActions
          name="settingsOrderDialog"
          message={
            <div className="select-none">
              <h3>Drag to re-order</h3>
              <SettingsOrderDrag value={localOrder} onChange={setLocalOrder} />
            </div>
          }
          actions={[
            {
              text: "Cancel",
              colour: "red",
              onClick: () => setOpen(false),
            },

            {
              text: "Save",
              colour: "green",
              onClick: handleSave,
            },
          ]}
        />
      )}
    </>
  );
}
