import HeaderBar from "components/HeaderBar";
import Page from "components/Page";
import { layouts } from "lib/constants";
import { Link } from "react-router-dom";

function Layouts() {
  return (
    <Page title="Layouts" heading="Layouts editor">
      <HeaderBar>
        {layouts.map((l) => (
          <Link key={l.key} to={l.key}>
            <p className="p-2 m-2 border-2 border-transparent hover:border-slate-600 hover:bg-white">
              {l.label}
            </p>
          </Link>
        ))}
      </HeaderBar>
    </Page>
  );
}

export default Layouts;
