import { format, fromUnixTime, isValid } from "date-fns";
import { store } from "react-recollect";

export const randomFromArray = (a) => a[Math.floor(Math.random() * a.length)];

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const nowTimestamp = () => Math.floor(new Date() / 1000);

export const formatNicelyDateTime = (d) => {
  if (!d || d === "") return "*No Date() given*";
  if (!isValid(d)) {
    console.error("invalid Date():", d);
    return format(new Date(d), "HH:mm, EEEE do MMMM yyyy");
  }
  return format(d, "HH:mm, EEEE do MMMM yyyy");
};

export const formatNicelyDate = (d) => {
  if (!d || d === "") return "*No Date() given*";
  if (!isValid(d)) {
    console.error("invalid Date():", d);
    return format(new Date(d), "EEEE do MMMM yyyy");
  }
  return format(d, "EEEE do MMMM yyyy");
};

export const formatNicelyTime = (d) => {
  if (!d || d === "") return "*No Date() given*";
  if (!isValid(d)) {
    console.error("invalid Date():", d);
    return format(new Date(d), "HH:mm");
  }
  return format(d, "HH:mm");
};

export const formatTimestampNicelyTime = (t) => {
  if (!t || t === "") return "*No timestamp given*";
  return formatNicelyTime(fromUnixTime(t));
};
export const formatTimestampNicelyDateTime = (t) => {
  if (!t || t === "") return "*No timestamp given*";
  return formatNicelyDateTime(fromUnixTime(t));
};

export const findOne = (haystack, key, value, defaultValue) =>
  Array.isArray(haystack)
    ? findOneInArray(haystack, key, value, defaultValue)
    : findOneInArray(Object.values(haystack), key, value, defaultValue);

export const findOneInArray = (
  arrayOfObjects,
  key,
  value,
  defaultValue = null
) => {
  for (let i = 0; i < arrayOfObjects.length; ++i) {
    const curr = arrayOfObjects[i];
    if (curr && curr[key] === value) return curr;
  }
  return defaultValue;
};

export function dedupe(a) {
  return [...new Set(a)];
}

export const isDev = () => process.env.NODE_ENV !== "production";

// given n, return either singularText (n===1) or pluralText (n!==1)
// pluralText, if not given, is singularText+'s'
export const plural = (n, singularText, pluralText = undefined) =>
  n === 1 ? singularText : pluralText || `${singularText}s`;

// given n, return "n " plus the plural options
export const pluralN = (n, singularText, pluralText = undefined) =>
  `${n} ${plural(n, singularText, pluralText)}`;

export const fieldName = (k) =>
  findOne(store.settingsMap["fields"], "key", k, { label: k }).label;

export const loggedInUserName = () => store.loggedInUser.user;
export const loggedInUserEmail = () => store.loggedInUser.email;

export const capitalise = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";
