import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import useCounter from "lib/useCounter";
import Loading from "components/Loading";
import Page from "components/Page";
import { statusColours } from "lib/constants";

const DashboardRow = ({ title, children }) => (
  <>
    {title && (
      <h2 className="text-md mt-3 text-slate-400 border-l border-grey-50 pl-1 grid content-center">
        {title}
      </h2>
    )}
    <div className="mb-4 w-full rounded-md rounded-tl-none shadow stats border min-h-[100px]">
      {children}
    </div>
  </>
);
DashboardRow.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};
DashboardRow.defaultProps = {
  title: null,
};

function DashboardCounter({
  fulfilment,
  standalone,
  web,
  type,
  text,
  hoverClass,
}) {
  let countCollection = "certificateResearch";
  let countOptions = { status: type };
  let linkTo = `/${type}`;

  if (web) {
    countCollection = "webResearch";
    linkTo = `/web/${type}`;
  }
  if (fulfilment) {
    countCollection = "fulfilments";
    countOptions = { product: type, completed: null };
    linkTo = `/fulfilment/${type}`;
  }
  if (standalone) {
    countCollection = "standalone";
    countOptions = { type, completed: null };
    linkTo = `/standalone/${type}`;
  }

  const count = useCounter(countCollection, countOptions);
  const colourClass = statusColours[`${type}${web ? "_web" : ""}`];

  return (
    <Link to={linkTo}>
      <div className={`stat h-full ${hoverClass}`}>
        <div className="stat-title">{text}</div>
        <div className={`stat-value text-center ${colourClass}`}>
          {count === undefined ? <Loading size={"30px"} /> : count}
        </div>
      </div>
    </Link>
  );
}
DashboardCounter.propTypes = {
  fulfilment: PropTypes.bool,
  web: PropTypes.bool, // true=web research, false=cert research
  hoverClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
DashboardCounter.defaultProps = { fulfilment: false };

function Dashboard() {
  return (
    <Page title="Dashboard" className="sm:py-4 sm:px-4 grid grid-cols-1">
      <DashboardRow>
        <DashboardCounter
          type="new"
          text="New orders"
          hoverClass="hover:bg-red-200"
        />
        <DashboardCounter
          web
          type="new"
          text="New web research"
          hoverClass="hover:bg-orange-200"
        />
        <DashboardCounter
          standalone
          type="techspec"
          text="Tech Specs"
          hoverClass="hover:bg-amber-300"
        />
        <DashboardCounter
          standalone
          type="docwallet"
          text="Document Wallets"
          hoverClass="hover:bg-amber-300"
        />
      </DashboardRow>
      <DashboardRow title="Researching">
        <DashboardCounter
          type="typing"
          text="Typing up"
          hoverClass="hover:bg-blue-100"
        />
        <DashboardCounter
          type="typochecking"
          text="Typo checking"
          hoverClass="hover:bg-blue-200"
        />
        <DashboardCounter
          type="techchecking"
          text="Technical checking"
          hoverClass="hover:bg-blue-300"
        />
        <DashboardCounter
          type="suspended"
          text="Suspended"
          hoverClass="hover:bg-blue-900 hover:text-yellow-100"
        />
      </DashboardRow>
      <DashboardRow title="Fulfiling">
        <DashboardCounter
          fulfilment
          type="cert"
          text="To print on certificate paper"
          hoverClass="hover:bg-emerald-200"
        />
        <DashboardCounter
          fulfilment
          type="head"
          text="To print on headed paper"
          hoverClass="hover:bg-emerald-200"
        />
        <DashboardCounter
          fulfilment
          type="email"
          text="To email"
          hoverClass="hover:bg-emerald-200"
        />
        <DashboardCounter
          fulfilment
          type="premPhoto"
          text="Premier Photos"
          hoverClass="hover:bg-emerald-200"
        />
        <DashboardCounter
          standalone
          type="supportingLetter"
          text="Supporting Letter"
          hoverClass="hover:bg-emerald-200"
        />
      </DashboardRow>
    </Page>
  );
}

export default Dashboard;
