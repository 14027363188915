import PropTypes from "prop-types";
import { forwardRef } from "react";

const Input = forwardRef(
  (
    {
      value,
      setValue,
      className,
      labelClassName,
      label,
      type,
      helperLabel,
      onEnter,
      ...otherProps
    },
    ref
  ) => {
    const onKeyPress = (e) => {
      if (onEnter && (e.code === "Enter" || e.code === "NumpadEnter"))
        return onEnter();
    };

    if (label || helperLabel)
      return (
        <div className="form-control flex-1">
          {label && (
            <label className="label">
              <span className={`label-text ${labelClassName}`}>{label}</span>
            </label>
          )}
          <input
            type={type}
            value={value}
            ref={ref}
            onChange={(e) =>
              setValue(
                type === "number" ? parseFloat(e.target.value) : e.target.value
              )
            }
            className={`input input-bordered text-black ${className}`}
            onKeyPress={onKeyPress}
            {...otherProps}
          />
          {helperLabel && (
            <label className="label">
              <span className="label-text-alt pl-1">{helperLabel}</span>
            </label>
          )}
        </div>
      );

    return (
      <div className="form-control flex-1">
        <input
          type={type}
          value={value}
          ref={ref}
          onChange={(e) => setValue(e.target.value)}
          className={`input input-bordered ${className}`}
          onKeyPress={onKeyPress}
          {...otherProps}
        />
      </div>
    );
  }
);

Input.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  helperLabel: PropTypes.string,
  label: PropTypes.string,
  onEnter: PropTypes.func,
  setValue: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.any.isRequired,
};
Input.defaultProps = {
  className: "",
  labelClassName: "",
  onEnter: null,
  helperLabel: undefined,
  label: undefined,
  type: "text",
};

export default Input;
