import { ButtonPrimary } from "./Buttons";
import { LoginIcon } from "@heroicons/react/outline";
import { useState } from "react";
import Input from "./Input";
import { certPOSTj } from "lib/API";
import Spinner from "./Spinner";
import LogoutButton from "./LogoutButton";

export function LoginSetUsername() {
  const [settingUsername, setSettingUsername] = useState(false);
  const [user, setUser] = useState("");
  const [error, setError] = useState("");

  async function setUsername() {
    setSettingUsername(true);

    const result = await certPOSTj("setUsername", { user });
    if (!result || !result.ok) {
      setError(`An error occured: ${result.error}`);
      setTimeout(() => setSettingUsername(false), 1000);
    } else {
      localStorage.removeItem("token");
      setTimeout(() => window.location.reload(), 100);
    }
  }

  return (
    <div className="border-2 border-slate-900 shadow-lg p-4 m-auto bg-slate-600 w-96">
      {error && (
        <p className="bg-red-900 border-2 border-red-500 p-2 shadow-md shadow-red-700 mb-4">
          {error}
        </p>
      )}
      <h2 className="text-lg text-white mb-4">You have not set a username</h2>
      <p className="my-2 text-slate-200">
        A username will allow you to use a shorter login, instead of having to
        type your whole email address every time.
      </p>
      <p className="my-2 text-slate-200">
        It can be anything that's not already used by someone else.
      </p>
      <p className="my-2 text-slate-200">
        It will not appear on anything customer-facing, but will be used
        internally to identify you (e.g. when you add a note to an order).
      </p>
      <Input
        labelClassName="text-white"
        label="Username"
        value={user}
        setValue={setUser}
        autoFocus
        disabled={settingUsername}
        onEnter={setUsername}
      />
      <div className="btn-group float-right mt-4">
        <LogoutButton />
        <ButtonPrimary onClick={setUsername} disabled={settingUsername}>
          {settingUsername ? <Spinner /> : <LoginIcon className="w-6 h-6" />}
          &nbsp;&nbsp;Set username&nbsp;
        </ButtonPrimary>
      </div>
    </div>
  );
}
