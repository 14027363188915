import { Fragment } from "react";
import {
  ArchiveIcon,
  ClipboardListIcon,
  ShoppingCartIcon,
} from "@heroicons/react/outline";
import CertSummary from "./CertSummary";
import CreateACopyButton from "./CreateACopyButton";
import SearchResultShopify from "./SearchResultShopify";

function SearchResultLayout({ bgcol, icon, title, children, onClick }) {
  return (
    <div
      className={`border m-2 shadow-sm flex ${
        onClick ? "cursor-pointer hover:bg-blue-200" : ""
      }`}
      onClick={onClick}
    >
      <div className={`w-17 p-2 mr-2 ${bgcol}`}>{icon}</div>
      <div className="w-60 text-lg pt-1 pr-2 border-r-2 border-slate-300">
        {title}
      </div>
      <div className="flex-1 p-2">{children}</div>
    </div>
  );
}

function Highlighter({ doc, q, fields, fieldPrefix }) {
  // go throguh fields and see if any match, and display those that do
  const re = new RegExp(`.*${q}.*`, "i");
  return fields.map((f) => {
    const splitarray = f.split(".");
    if (splitarray.length > 1) {
      return (
        <Highlighter
          key={`${fieldPrefix}_${f}`}
          doc={doc[splitarray[0]]}
          q={q}
          fields={[splitarray.slice(1).join(".")]}
          fieldPrefix={
            fieldPrefix
              ? `${fieldPrefix}${splitarray[0]}.`
              : `${splitarray[0]}.`
          }
        />
      );
    }
    if (!doc) return null;
    if (!doc[f]) return null;
    if (doc[f] && re.test(doc[f]))
      return (
        <p key={`${fieldPrefix}_${f}`}>
          <span>
            {fieldPrefix}
            {f}
          </span>
          :{" "}
          <b>
            {doc[f].split("\n").map((v, i, arr) => (
              <Fragment key={i}>
                {i > 0 && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 inline mx-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
                {v}
              </Fragment>
            ))}
          </b>
        </p>
      );
    return null;
  });
}
Highlighter.defaultProps = {
  fieldPrefix: "",
};

function SearchResult({ r, q, setAndDo }) {
  if (!r || !r.type) {
    // throw Error(`Invalid search result object: ${JSON.stringify(r)}`);
    return (
      <div className="bg-red-200 p-4 m-2">
        <pre>{JSON.stringify({ r, q }, null, 2)}</pre>
      </div>
    );
  }

  switch (r.type) {
    case "researchData":
      return (
        <SearchResultLayout
          bgcol="bg-blue-100"
          icon={<ClipboardListIcon className="w-6 h-6" />}
          title="Research Data"
        >
          <Highlighter
            doc={r.result}
            q={q}
            fields={[
              "makeAndModel",
              "vin",
              "engineNumber",
              "bodyNumber",
              "spec",
              "extColour",
              "trimColour",
              "hoodColour",
              "dateBuild",
              "dateDespatched",
              "dest",
              "otherNumbers",
              "details",
              "other",
            ]}
          />
        </SearchResultLayout>
      );
    case "shopifyOrders":
      return (
        <SearchResultLayout
          bgcol="bg-emerald-100"
          icon={<ShoppingCartIcon className="w-6 h-6" />}
          title={
            <>
              <p>Shopify Order {r.result.name}</p>
              <p className="text-sm">
                {(r.result.customer || {}).first_name}{" "}
                {(r.result.customer || {}).last_name}
              </p>
            </>
          }
        >
          <SearchResultShopify q={q} r={r} setAndDo={setAndDo} />
        </SearchResultLayout>
      );
    case "oldCert":
      return (
        <SearchResultLayout
          bgcol="bg-green-200"
          icon={<ArchiveIcon className="w-6 h-6" />}
          title={`Old certificate, number ${r.result.certNumber}`}
        >
          <CertSummary cert={r.result}>
            <CreateACopyButton cert={r.result} />
            {r.result.shopifyLink && (
              <a
                href={r.result.shopifyLink}
                target="_blank"
                rel="noreferrer noopener"
                className="btn btn-secondary relative -top-2"
              >
                Click to open Shopify order
              </a>
            )}
          </CertSummary>
        </SearchResultLayout>
      );
    default:
      return (
        <SearchResultLayout title="Unknown type">{r.type}</SearchResultLayout>
      );
  }
}

export default SearchResult;
export { Highlighter };
