import Page from "components/Page";
import { certPOST } from "lib/API";
import { useEffect } from "react";

function FourOhFour() {
  useEffect(() => {
    certPOST("fourohfour", { location: window.location.href });
  }, []);

  return (
    <Page title="404">
      <div className="w-full h-full bg-gradient-to-br from-sky-700 to-sky-900">
        <h1 className="h-full text-9xl text-orange-200 place-items-center place-content-center grid text-center font-bold">
          Page not found
        </h1>
      </div>
    </Page>
  );
}

export default FourOhFour;
