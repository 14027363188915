import { FlagIcon } from "@heroicons/react/outline";
import { format } from "date-fns";
import useStoreLoader from "lib/useStoreLoader";
import { useEffect, useRef } from "react";
import { collect, store } from "react-recollect";

function NavBarFlags() {
  const lastLoaded = useRef();

  const { isLoading, refresh } = useStoreLoader({
    collection: "flags",
    onLoad: (d) => (store.flagArray = Object.values(d)),
  });

  useEffect(() => {
    lastLoaded.current = new Date();

    const intervalId = setInterval(() => {
      refresh();
      lastLoaded.current = new Date();
    }, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [refresh]);

  if (isLoading || !store.flagArray)
    return (
      <div className="h-full grid place-content-center">Loading flags...</div>
    );

  return (
    <div className="h-full grid place-content-center">
      <div className="flex">
        {store.flagArray.length}
        <FlagIcon className="text-red-100 w-4 h-4 relative top-1 ml-1" />
      </div>
      <div>{lastLoaded.current && format(lastLoaded.current, "H:m d MMM")}</div>
    </div>
  );
}

export default collect(NavBarFlags);
