// render the children inside a bar with a "copy to clipboard" button

export default function CopyToClipboard({ text }) {
  return (
    <div
      className="tooltip tooltip-bottom"
      data-tip="Click to copy to clipboard"
    >
      <div
        className="m-1 border border-bg-base-200 hover:bg-base-300 cursor-pointer shadow-sm whitespace-pre text-left p-2"
        onClick={() => navigator.clipboard.writeText(text)}
      >
        {text}
      </div>
    </div>
  );
}
