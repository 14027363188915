import { useEffect, useRef, useState } from "react";
import { certGETj } from "./API";
import { isDev } from "./useful";

function useCounter(collection, find, options) {
  const { slowdown } = Object.assign({ slowdown: 1 }, options);

  const [count, setCount] = useState(undefined);

  // keep track of if this is mounted - don't setState if we're not mounted (prevent memory leaks)
  const isMounted = useRef(true);

  // in JS, {}!=={} so we have to stringify it to check
  const findStringified = JSON.stringify(find);

  // set isMounted to false when we unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let timerHandle = null;
    let url = "count/" + collection;
    if (Object.keys(find).length > 0) url += "?find=" + JSON.stringify(find);

    certGETj(url).then((returnedCount) => {
      // people are more trusting if it actually takes some time to load
      // and it's nice to test
      timerHandle = setTimeout(() => {
        if (isMounted.current) setCount(returnedCount);
        timerHandle = null;
      }, ((isDev() ? 5 : 500) + 100 * Math.random()) * slowdown);
    });
    return () => {
      if (timerHandle) {
        clearTimeout(timerHandle);
      }
    };
    // we use findStringified instead of find as a dependancy
    // eslint-disable-next-line
  }, [collection, findStringified]);

  return count;
}

export default useCounter;
