// load all certificates that are linked to this order
// then show minimal info - basically need to know what stage they are in

import { statusColours } from "lib/constants";
import { findOneInArray, formatTimestampNicelyDateTime } from "lib/useful";
import useLoad from "lib/useLoad";
import { useNavigate } from "react-router-dom";
import { Highlighter } from "./SearchResult";

function SearchResultShopifyWithShopifyLoaded({ q, r, researches, setAndDo }) {
  const navigate = useNavigate();

  const { data: researchData } = useLoad("researchData", {
    _certId: { $in: researches.map((r) => r._id) },
  });

  if (researchData === undefined)
    return (
      <div className="text-2xl text-blue-700 h-48 grid place-content-center">
        Loading research data...
      </div>
    );

  const stages = [
    "new",
    "typing",
    "typochecking",
    "techchecking",
    "fulfilling",
    "complete",
    "oldCert",
  ];

  return (
    <>
      {researches.map((i, iIndex) => {
        const rd = findOneInArray(researchData, "_certId", i._id, null);
        const clickyUrl =
          stages.findIndex((s) => s === i.status) <= 3
            ? `/${i.status}/${i._id}`
            : null;

        switch (i.lineItemName) {
          case "Heritage Certificate":
          case "Premier Heritage Certificate":
          case "Heritage Certificate Re-issue":
          case "Premier Heritage Certificate Re-issue":
            return (
              <div
                key={iIndex}
                className={`mb-2 p-2 text-xl border ${
                  clickyUrl ? `hover:bg-slate-100 cursor-pointer` : ""
                }`}
                onClick={() => clickyUrl && navigate(clickyUrl)}
              >
                {i.certNumber ? (
                  <span>
                    <b
                      className="cursor-pointer text-blue-800 hover:underline"
                      onClick={() => setAndDo(i.certNumber)}
                    >
                      {i.certNumber}
                    </b>
                    : {i.lineItemName} -{" "}
                    <span className={statusColours[i.status]}>{i.status}</span>
                  </span>
                ) : (
                  <span>
                    {i.lineItemName} -{" "}
                    <span className={statusColours[i.status]}>{i.status}</span>
                  </span>
                )}
                {i.status === "suspended" ? (
                  <div className="m-2 border text-lg bg-red-100 p-2">
                    <p>Suspended by {rd?.suspendedBy}</p>
                    <p>Reason: {rd?.suspendedReason}</p>
                    <p>
                      at {formatTimestampNicelyDateTime(rd.suspendedTimestamp)}
                    </p>
                  </div>
                ) : (
                  <div className="m-2 text-sm w-full">
                    <ul className="steps steps-vertical lg:steps-horizontal">
                      {stages.map((s, si) => (
                        <li
                          key={s}
                          className={`p-2 step ${
                            stages.findIndex((st) => st === i.status) >= si
                              ? "step-primary"
                              : ""
                          }`}
                        >
                          {s}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            );
          default:
            return (
              <div
                key={iIndex}
                className="mb-2 p-2 text-xl border text-black/75"
              >
                {i.lineItemName}
              </div>
            );
        }
      })}
      <p>Matches:</p>
      <Highlighter
        doc={r.result}
        q={q}
        fields={[
          "email",
          "note",
          "name",
          "customer.first_name",
          "customer.last_name",
          "shipping_address.first_name",
          "shipping_address.last_name",
          "shipping_address.name",
          "shipping_address.address1",
          "shipping_address.zip",
          "billing_address.first_name",
          "billing_address.last_name",
          "billing_address.name",
          "billing_address.address1",
          "billing_address.zip",
        ]}
      />
      <a
        href={`https://certificates-3.myshopify.com/admin/orders/${r.result.id}`}
        target="_blank"
        rel="noopener noreferrer"
        className="hover:underline text-blue-900"
      >
        Shopify Order Id: {r.result.id} (click to view on Shopify)
      </a>
    </>
  );
}

export default function SearchResultShopify({ q, r, setAndDo }) {
  const { data: researches } = useLoad("certificateResearch", {
    shopifyOrdersId: r.result._id,
  });

  if (researches === undefined)
    return (
      <div className="text-2xl text-blue-700 h-48 grid place-content-center">
        Loading Shop order details...
      </div>
    );

  return (
    <SearchResultShopifyWithShopifyLoaded
      q={q}
      r={r}
      researches={researches}
      setAndDo={setAndDo}
    />
  );
}
