import { findOneInArray, fieldName } from "lib/useful";
import { store } from "react-recollect";
import RenderFieldColumn from "./RenderFieldColumn";

export default function CertSummary({ cert, children }) {
  const mm = findOneInArray(
    store.settingsMap["makeAndModel"],
    "key",
    cert.makeAndModel,
    { value: null }
  ).value;

  return (
    <div>
      <div className="flex">
        <div className="w-36"></div>
        <h4 className="text-xl mb-1">{cert.certNumber}</h4>
      </div>
      <RenderFieldColumn
        rightExtraClasses="pt-4"
        leftContent="Make &amp; Model"
        borderT
      >
        {mm} ({cert.makeAndModel})
      </RenderFieldColumn>
      <RenderFieldColumn rightExtraClasses="pt-4" leftContent="Specification">
        {cert.spec}
      </RenderFieldColumn>
      {[
        "vin",
        "engineNumber",
        "bodyNumber",
        "otherNumbers",
        "details",
        "other",
        "extColour",
        "trimColour",
        "hoodColour",
        "dateBuild",
        "dateDespatched",
        "dest",
      ].map(
        (f) =>
          cert[f] &&
          cert[f].trim().length > 0 && (
            <RenderFieldColumn
              rightExtraClasses="pt-4"
              key={f}
              leftContent={fieldName(f)}
            >
              {cert[f]}
            </RenderFieldColumn>
          )
      )}
      <RenderFieldColumn rightExtraClasses="pt-4" leftContent="Issued" borderB>
        {cert.dateOfIssue} {cert.issuedTo ? `to ${cert.issuedTo}` : ""}
      </RenderFieldColumn>
      {/* <pre>{JSON.stringify(cert.searchHelper.split("|"), null, 2)}</pre> */}
      {children}
    </div>
  );
}
