import PropTypes from "prop-types";
import {
  ArrowSmDownIcon,
  PencilAltIcon,
  ArrowSmUpIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/react/outline";
import { findOne } from "lib/useful";
import { useState } from "react";
import { store } from "react-recollect";
import { ButtonPrimary, ButtonSecondary } from "./Buttons";
import Input from "./Input";
import LayoutEditField from "./LayoutEditField";
import LayoutPDFView from "./LayoutPDFView";
import RenderFieldReissueSearch from "./RenderFieldReissueSearch";

function LayoutEditor({ layoutData, save }) {
  const fields = store.settingsMap["fields"];

  // const defaultFieldValues = { // use good defaults, because they are good
  //   show: true,
  //   lsize: 12,
  //   lbold: false,
  //   lx: -2.5,
  //   ly: 80,
  //   belowPrev: true,
  //   lUseGlobalColumn: true,
  //   lRightAlign: true,
  //   fsize: 12,
  //   fbold: true,
  //   fx: 2.5,
  //   fy: 10,
  //   sameAsTitle: true,
  //   fUseGlobalColumn: true,
  //   lineHeight: 8,
  //   label: "This is a label",
  // };
  const defaultFieldValues = {
    // use bad defaults, so we can see if it's loaded properly
    show: true,
    lsize: 15,
    lbold: true,
    lx: -22.5,
    ly: 80,
    belowPrev: true,
    lUseGlobalColumn: true,
    lRightAlign: true,
    fsize: 6,
    fbold: false,
    fx: 22.5,
    fy: 10,
    sameAsTitle: true,
    fUseGlobalColumn: true,
    useMargin: true,
    lineHeight: 6,
    label: "This is a label",
  };

  const [researchData, setResearchData] = useState({
    certNumber: "2025/1234",
    makeAndModel: "L1-09DB",
    vin: "SALLXXXXXXX/123456",
    engineNumber: "10P12345A",
    bodyNumber: "Not Recorded",
    spec: "LHD, North American (Californian) Export, Super-de-Luxe",
    extColour: "Woodcote Green (micatallic)",
    trimColour: "Grey (cloth)",
    hoodColour: "Not Applicable",
    dateBuild: "32 February 2020",
    dateDespatched: "35 December 2019",
    dest: "Tristan's Land Rover Dodgy Dealers",
    otherNumbers:
      "Front axle number 12X01234A, Gearbox number 74A01234567L, Rear axle number 61S00000A, Transfer box number 43D0000000",
    details: "2.6 litre TD6 engine, Seven speed manual gearbox",
    other:
      "Recorded sold date 1 February 1999.  The registration mark ABC123D (quoted by you) was issued in Caernarvonshire",
    issuedTo: "A. Land-Rover Person",
    dateOfIssue: "$today",
    signature: "",
  });

  const [editedData, setEditedData] = useState(() =>
    Object.assign(
      {
        fieldOrder: fields.map((f) => f.key),
        globalColumnHorizPos: 75,
        marginR: 9,
      },
      fields.reduce(
        (p, c, i) => ({
          ...p,
          [c.key]: {
            ...(i === 0
              ? { ...defaultFieldValues, belowPrev: false }
              : defaultFieldValues),
            label: c.label,
          },
        }),
        {}
      ),
      layoutData
    )
  );
  const [selectedFieldKey, setSelectedFieldKey] = useState(null);

  const field = findOne(fields, "key", selectedFieldKey, null);
  const setV = (fieldKey) => (k) => (v) => {
    setEditedData({
      ...editedData,
      [fieldKey]: { ...(editedData[fieldKey] || {}), [k]: v },
    });
  };
  const getV =
    (fieldKey) =>
    (k, def = undefined) =>
      (editedData[fieldKey] || {})[k] ?? def ?? 0;

  function moveDown(fi) {
    const fo = editedData.fieldOrder;
    const newOrder = [
      ...fo.slice(0, fi),
      fo[fi + 1],
      fo[fi],
      ...fo.slice(fi + 2),
    ];
    setEditedData({ ...editedData, fieldOrder: newOrder });
  }
  function moveUp(fi) {
    const fo = editedData.fieldOrder;
    const newOrder = [
      ...fo.slice(0, fi - 1),
      fo[fi],
      fo[fi - 1],
      ...fo.slice(fi + 1),
    ];
    setEditedData({ ...editedData, fieldOrder: newOrder });
  }
  const setBackgroundImage = (backgroundImage) =>
    setEditedData({ ...editedData, backgroundImage });

  function swapBoldSide() {
    // swap bold from label to field value & vice versa
    let newData = Object.assign({}, editedData);
    for (let f of newData.fieldOrder) {
      const t = newData[f].fbold;
      newData[f].fbold = newData[f].lbold;
      newData[f].lbold = t;
    }
    setEditedData(newData);
  }

  return (
    <div className="md:flex md:h-full md:max-h-full overflow-hidden">
      <div className="w-full md:w-1/2 lg:w-1/3 max-h-full overflow-y-auto overflow-x-hidden">
        <div className="h-15 shadow-lg p-1 bg-[#323639]">
          <ButtonPrimary onClick={() => save(editedData)}>save</ButtonPrimary>
        </div>
        {!field ? (
          <>
            <div className="bg-white m-2 px-2 pb-2 shadow-xl border">
              <Input
                value={editedData.backgroundImage ?? ""}
                setValue={setBackgroundImage}
                label="URL of background image"
              />
              <div className="btn-group inline-block m-1">
                <ButtonSecondary onClick={() => setBackgroundImage(null)}>
                  clear
                </ButtonSecondary>
                <ButtonSecondary
                  onClick={() => setBackgroundImage("/headed.jpg")}
                >
                  BMM
                </ButtonSecondary>
                <ButtonSecondary
                  onClick={() => setBackgroundImage("/BMIHTheaded.jpg")}
                >
                  BMIHT
                </ButtonSecondary>
                <ButtonSecondary
                  onClick={() => setBackgroundImage("/cert.jpg")}
                >
                  cert
                </ButtonSecondary>
              </div>
            </div>
            <div className="bg-white m-2 px-2 pb-2 shadow-xl border">
              <RenderFieldReissueSearch
                initialSearch="21/2538"
                leftText={false}
                mergeNewValues={(v) => {
                  setResearchData((rd) =>
                    Object.assign({}, rd, v, {
                      certNumber: v.copiedFromPrevCert,
                    })
                  );
                }}
              />
            </div>
            <div className="bg-white m-2 px-2 pb-2 shadow-xl border">
              <Input
                type="number"
                value={editedData.globalColumnHorizPos ?? 80}
                setValue={(v) =>
                  setEditedData({ ...editedData, globalColumnHorizPos: v })
                }
                label="Global column position"
              />
              <Input
                type="number"
                value={editedData.marginR ?? 10}
                setValue={(v) => setEditedData({ ...editedData, marginR: v })}
                label="Right margin (mm)"
                helperLabel={`This is only used to wrap long data fields (like "other numbers" and "other information").  It will be drawn on this example, but not the final documents`}
              />
            </div>
            <div className="bg-white divide-y max-h-60 overflow-y-auto m-2 shadow-xl border">
              {editedData.fieldOrder.map((fk, fi) => (
                <div key={fk} className="p-2 flex">
                  <div className="flex-1">
                    {findOne(fields, "key", fk, {}).label}
                  </div>
                  <div className="w-10">
                    {fi < editedData.fieldOrder.length - 1 && (
                      <ArrowSmDownIcon
                        className="w-6 h-6 inline-block cursor-pointer hover:shadow hover:bg-slate-200"
                        onClick={() => moveDown(fi)}
                      />
                    )}
                  </div>
                  <div className="w-10">
                    {fi > 0 && (
                      <ArrowSmUpIcon
                        className="w-6 h-6 inline-block cursor-pointer hover:shadow hover:bg-slate-200"
                        onClick={() => moveUp(fi)}
                      />
                    )}
                  </div>
                  <div className="w-10">
                    <PencilAltIcon
                      className="w-6 h-6 inline-block cursor-pointer hover:shadow hover:bg-slate-200"
                      onClick={() => setSelectedFieldKey(fk)}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="bg-white m-2 p-2 shadow-xl border">
              <ButtonSecondary onClick={swapBoldSide}>
                swap bold side
              </ButtonSecondary>
            </div>
          </>
        ) : (
          <>
            <div
              className="p-2 cursor-pointer bg-slate-300 border-2 hover:bg-gradient-to-r from-slate-600 to-slate-900 hover:text-white"
              onClick={() => setSelectedFieldKey(null)}
            >
              <ChevronDoubleLeftIcon className="w-6 h-6 inline-block" />
              &nbsp;Back
            </div>
            <div className="pl-2 divide-y">
              {field && (
                <LayoutEditField
                  fieldLabel={field.label}
                  getV={getV(field.key)}
                  setV={setV(field.key)}
                />
              )}
            </div>
          </>
        )}
      </div>
      <div className="w-full md:w-1/2 lg:w-2/3">
        <LayoutPDFView
          certificateData={researchData}
          layoutData={editedData}
          afterGenerated={(doc) => {
            // draw the right margin for debugging
            doc.setDrawColor(0.5);
            const marginX =
              doc.internal.pageSize.getWidth() - editedData.marginR;
            doc.line(marginX, 0, marginX, 297);
          }}
        />
      </div>
    </div>
  );
}

LayoutEditor.propTypes = {
  layoutData: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired,
};

export default LayoutEditor;
