import { useState } from "react";
import { store } from "react-recollect";
import { Button } from "./Buttons";

export default function LogoutButton({ ...otherProps }) {
  const [loggingOut, setLoggingOut] = useState(false);
  return (
    <Button
      onClick={() => {
        setLoggingOut(true);
        localStorage.removeItem("token");
        setTimeout(() => window.location.reload(), 100);
      }}
      disabled={loggingOut}
      {...otherProps}
    >
      Log out {store.loggedInUser.user}
    </Button>
  );
}
