import LayoutEditor from "components/LayoutEditor";
import Page from "components/Page";
import { certPOST } from "lib/API";
import { layouts } from "lib/constants";
import { findOne } from "lib/useful";
import { collect, store } from "react-recollect";
import { useNavigate, useParams } from "react-router-dom";

function Layout() {
  const { layout } = useParams();
  const layoutObj =
    store.settingsMap[`layout_${layout}`] ||
    findOne(layouts, "key", layout, { label: "** Unknown layout! **" });
  const navigate = useNavigate();

  function save(d) {
    certPOST(`saveLayout/${layout}`, d).then(async (r) => {
      if (r && r.ok) {
        // update settingsMap with new layout data (so we don't have to reload the whole site)
        store.settingsMap[`layout_${layout}`] = await r.json();
        navigate("..");
      } else store.error = r.statusText;
    });
  }

  return (
    <Page
      title={`${layoutObj.label} · Layout`}
      heading={`Layout editor: ${layoutObj.label}`}
      breadcrumbs={[{ to: "..", label: "Layouts" }]}
      className="flex flex-col"
    >
      <LayoutEditor layoutData={layoutObj} save={save} />
    </Page>
  );
}

export default collect(Layout);
