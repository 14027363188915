import PDFCustomerInfo from "PDFCreators/PDFCustomerInfo";
import PDFResearchSlip from "PDFCreators/PDFResearchSlip";
import { newPDF, printPDF } from "PDFCreators/common";
import { store } from "react-recollect";

function PDFCustomerInfoAndSlip(researches) {
  const doc = newPDF();
  researches.forEach((research) => {
    PDFCustomerInfo(
      doc,
      store.shopifyOrders[research.shopifyOrdersId],
      research
    );
    PDFResearchSlip(
      doc,
      store.shopifyOrders[research.shopifyOrdersId],
      research
    );
  });
  printPDF(doc);
}

export default PDFCustomerInfoAndSlip;
