import PropTypes from "prop-types";

// it's actually much cleaner to not use tailwind here
// (or I'm not good enough with tailwind)
import "./Loading.css";
// and we want some randomness later, so have to use style=

// altered from https://loading.io/css/

function Loading(props) {
  const { className, number, size, color, text } = props;

  return (
    <div className={`grid place-items-center animate-fadein ${className}`}>
      <div>
        <div className="lds-ring" style={{ width: size, height: size }}>
          {Array(number)
            .fill(0)
            .map((_, ni) => (
              <div
                key={ni}
                style={{
                  borderColor: `${color} transparent transparent transparent`,
                  borderWidth: Math.floor(5 + Math.random() * 15),
                  animationDuration: `${Math.random() * 3 + 0.5}s`,
                }}
              ></div>
            ))}
        </div>
      </div>
      {text && <h1 className="text-2xl mt-10">{text}</h1>}
    </div>
  );
}
Loading.propTypes = {
  className: PropTypes.string,
  number: PropTypes.number,
  text: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
};
Loading.defaultProps = {
  className: "",
  number: 5,
  text: null,
  size: "15vw",
  color: "#57f7",
};

export default Loading;
