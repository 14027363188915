import PropTypes from "prop-types";
import { useState } from "react";

function Collapse({ children, title, defaultOpen }) {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <div className="m-2 shadow-sm border-slate-500">
      <div
        className="flex cursor-pointer bg-slate-100 hover:bg-slate-400"
        onClick={() => setOpen(!open)}
      >
        <div className="text-lg flex-1 p-3 ">{title}</div>
        <div
          className={`text-lg font-bold flex-0 p-3 ${
            open ? "rotate-90" : "rotate-270"
          }`}
        >
          &gt;
        </div>
      </div>
      <div className={open ? "" : "hidden"}>{children}</div>
    </div>
  );
}

Collapse.propTypes = {
  children: PropTypes.node,
  defaultOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
};
Collapse.defaultProps = {
  title: "Click to expand",
  defaultOpen: false,
};

export default Collapse;
