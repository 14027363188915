import ConfettiComponent from "components/ConfettiComponent";

export default function FullsizeMessage({ withConfetti, children }) {
  return (
    <>
      {withConfetti && <ConfettiComponent />}
      <h1 className="text-xl md:text-3xl lg:text-6xl font-bold text-slate-500 p-2 md:p-3 lg:p-4">
        {children}
      </h1>
    </>
  );
}
