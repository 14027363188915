import { pluralN } from "lib/useful";
import { useEffect } from "react";
import { useState } from "react";
import { store } from "react-recollect";
import { Button } from "./Buttons";
import Input from "./Input";
import RenderFieldColumn from "./RenderFieldColumn";

function RenderFieldTextChooser({
  field,
  savedValue,
  setValue,
  localValue,
  resultsWrapClassName,
  settingsKey,
}) {
  const [text, setText] = useState("");
  const [results, setResults] = useState(() =>
    settingsKey === "specs" ? store.settingsMap[settingsKey] : []
  );

  // if savedValue changes (e.g. "Copy values" from a previous cert), update local state
  useEffect(() => {
    // (disable esline because I don't care if setValue changes)
    setValue(savedValue || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedValue]);

  useEffect(() => {
    const t = text.trim().toUpperCase();
    if (t === "") return;
    // filter to anything with this text in
    setResults(
      store.settingsMap[settingsKey].filter((d) => d.toUpperCase().includes(t))
    );
  }, [text, settingsKey]);

  if (localValue) {
    return (
      <RenderFieldColumn leftContent={field.label} rightExtraClasses={"flex"}>
        <div className="w-1/2 px-3 py-3">
          <p className="font-bold">{localValue}</p>
        </div>
        <div className="">
          <Button onClick={() => setValue(null)}>clear</Button>
        </div>
      </RenderFieldColumn>
    );
  }

  let exactMatch = results.find((r) => r.toUpperCase() === text.toUpperCase());
  if (!exactMatch && results.length === 1) exactMatch = results[0];
  function handleEnter() {
    if (exactMatch) setValue(exactMatch);
  }

  return (
    <RenderFieldColumn leftContent={field.label}>
      <div className="w-60">
        <Input value={text} setValue={setText} onEnter={handleEnter} />
        {exactMatch && (
          <div>
            <p>Press enter to select</p>
            <p className="text-xs"> {exactMatch} </p>
          </div>
        )}
      </div>
      <div className="flex-1 pl-3 py-1">
        {results.length < 1 ? (
          <p>No matches</p>
        ) : (
          <div
            className={`overflow-y-auto transition-all shadow-xl bg-white border-t-2 border-t-slate-400 ${resultsWrapClassName}`}
          >
            <p className="font-bold m-3">
              {pluralN(results.length, "possible option")}
            </p>
            {results.map((f) => {
              return (
                <div
                  key={f}
                  onClick={() => setValue(f)}
                  className="flex border cursor-pointer hover:border-emerald-600 hover:bg-emerald-50 m-1"
                >
                  <div className="p-1 ml-1">{f}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </RenderFieldColumn>
  );
}

RenderFieldTextChooser.defaultProps = {
  resultsWrapClassName: "max-h-96",
};

export default RenderFieldTextChooser;
