import ReactDragListView from "react-drag-listview";

// give array, get array
export default function SettingsOrderDrag({ value, onChange }) {
  const onDragEnd = (fromIndex, toIndex) => {
    let updatedArray = [...value];
    const movedItem = updatedArray.splice(fromIndex, 1)[0];
    updatedArray.splice(toIndex, 0, movedItem);
    onChange(updatedArray);
  };

  return (
    <ReactDragListView
      onDragEnd={onDragEnd}
      nodeSelector=".draggableListItem"
      handleSelector=".dragSelector"
    >
      {value.map(({ key, value }) => (
        <div
          key={key}
          className="draggableListItem flex items-center select-none"
        >
          <div className="dragSelector flex-none cursor-move py-1 px-10 m-1 bg-slate-300">
            {key} {value}
          </div>
        </div>
      ))}
    </ReactDragListView>
  );
}
