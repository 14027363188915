import jsPDF from "jspdf";
import "jspdf-autotable";
import Avenir from "lib/jspdf-font-avenir";
import AvenirBold from "lib/jspdf-font-avenir-bold";
import AvenirMedium from "lib/jspdf-font-avenir-medium";

function newPDF() {
  const doc = new jsPDF();
  doc.deletePage(1);
  doc.addFileToVFS("Avenir-normal.ttf", Avenir);
  doc.addFileToVFS("Avenir-medium.ttf", AvenirMedium);
  doc.addFileToVFS("Avenir-bold.ttf", AvenirBold);
  doc.addFont("Avenir-normal.ttf", "Avenir", "", "normal");
  doc.addFont("Avenir-medium.ttf", "Avenir", "", "medium");
  doc.addFont("Avenir-bold.ttf", "Avenir", "", "bold");
  doc.setFont("Avenir", "normal");
  jsPDF.autoTableSetDefaults({
    headStyles: { fillColor: 245, textColor: 0 },
  });

  return doc;
}

function printPDF(doc) {
  doc.autoPrint({ variant: "non-conform" });
  doc.output("dataurlnewwindow");
}

function downloadPDF(doc) {
  doc.output("save");
}

export { newPDF, printPDF, downloadPDF };
