import { store } from "react-recollect";
import PDFPremierPhoto from "./PDFPremierPhoto";
import PDFGenericLayout from "./PDFGenericLayout";

async function PDFCreator(product, doc, fulfilment) {
  // work out what the product is, and call the relevant PDF creator function for it
  const lii = fulfilment.lineItemIndex;

  // prem photos don't have a line item (they come from the same line
  // item as the certificate itself)
  if (lii === -1) return await PDFPremierPhoto(doc, fulfilment);

  const li = store.shopifyOrders[fulfilment.shopifyOrdersId].line_items[lii];
  const rid = fulfilment.researchId;
  const researchData = store.researchDataByCertId[rid];

  switch (li.sku) {
    case "CERT":
    case "CERT-LAM":
    case "CERT-REISS":
    case "CERT-REISS-LAM":
      return await PDFGenericLayout(
        doc,
        store.settingsMap["layout_certstd"],
        researchData
      );
    case "PREM":
    case "PREM-LAM":
    case "PREM-REISS":
    case "PREM-REISS-LAM":
      return await PDFGenericLayout(
        doc,
        store.settingsMap["layout_certprem"],
        researchData
      );
    case "PAPERCOPY":
      return await PDFGenericLayout(
        doc,
        store.settingsMap["layout_paper"],
        researchData
      );
    case "TECHSPEC":
    case "DOCWALLET":
      // Richard wants to do these himself...
      return doc;
    //   return PDFTechSpec(doc, fulfilment);
    // case 'CERT-EMAIL-COPY':
    // these are handled slightly differently (with popup dialog)
    // case 'DOCWALLET':
    // these shouldn't be here...
    default:
      throw Error(
        `PDFCreator called for product type ${product} on SKU ${li.sku} - don't know what to do`
      );
  }
}

export default PDFCreator;
