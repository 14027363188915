import { Fragment, useEffect, useState } from "react";
import { capitalise, dedupe } from "lib/useful";
import { collect, store } from "react-recollect";
import useStoreLoader from "lib/useStoreLoader";
import Loading from "components/Loading";
import OrderLine from "components/OrderLine";
import { certPOST } from "lib/API";
import Page from "components/Page";
import FullsizeMessage from "components/FullsizeMessage";
import RenderFieldReissueSearch from "components/RenderFieldReissueSearch";
import { ChevronDoubleRightIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import ButtonWithConfirmation from "components/ButtonWithConfirmation";
import HeaderBar from "components/HeaderBar";
import Input from "components/Input";
import LoadResearches from "components/loaders/LoadResearches";
import LoadResearchData from "components/loaders/LoadResearchData";
import CertSummary from "components/CertSummary";
import LoadShopifyOrders, {
  needToLoadShopifyOrders,
} from "components/loaders/LoadShopifyOrders";
import CopyToClipboard from "components/CopyToClipboard";

function StandaloneList({ type, text }) {
  const { isLoading, items } = useStoreLoader({
    collection: `standalone`,
    storeKey: `standalone-${type}`,
    find: { type, completed: null },
  });
  const [searchText, setSearchText] = useState("");
  useEffect(
    () => () => {
      delete store[`standalone-${type}`];
      delete store["certificateResearch"];
      delete store["researchDataByCertId"];
    },
    [type]
  ); // force it to reload next time

  if (isLoading) return <Loading text={`Loading items ready to be ${text}`} />;
  const standalone = Object.values(items);

  const researchesToLoad = dedupe(
    standalone
      .map((f) => f.linkedresearchId)
      .filter((r) => r !== "")
      .filter(
        (rid) => !store.certificateResearch || !store.certificateResearch[rid]
      )
  );
  const researchDataToLoad = dedupe(
    standalone
      .map((f) => f.linkedresearchId)
      .filter(
        (rid) => !store.researchDataByCertId || !store.researchDataByCertId[rid]
      )
  );
  const shopifyOrderIds = standalone.map((r) => r.shopifyOrdersId);
  if (needToLoadShopifyOrders(shopifyOrderIds))
    return <LoadShopifyOrders ids={shopifyOrderIds} />;

  if (researchesToLoad.length > 0)
    return <LoadResearches ids={researchesToLoad} />;
  if (researchDataToLoad.length > 0)
    return <LoadResearchData ids={researchDataToLoad} mightBeEmpty />;

  if (standalone.length === 0)
    return <FullsizeMessage>No orders waiting to be {text}</FullsizeMessage>;

  const stt = searchText.trim();
  const searchedStandaloneItems =
    stt.length > 0
      ? standalone.filter((s) => {
          if (s.name.includes(stt)) return true;
          return false;
        })
      : standalone;

  return (
    <Page title={capitalise(type)} heading={`To be ${text}`}>
      <HeaderBar>
        <Input
          autoFocus
          value={searchText}
          setValue={setSearchText}
          label="Search"
        />
      </HeaderBar>
      <div>
        {searchedStandaloneItems
          .filter((s) => !s.complete)
          .map((standaloneItem) => {
            const shipping_address =
              store.shopifyOrders[standaloneItem.shopifyOrdersId]
                ?.shipping_address;
            return (
              <Fragment key={standaloneItem._id}>
                {/* <pre>{JSON.stringify(standaloneItem, null, 2)}</pre> */}
                <OrderLine key={standaloneItem._id} r={standaloneItem}>
                  <div className="mb-2">
                    Part of order {standaloneItem.name}:{" "}
                    <a
                      className="text-blue-700 hover:underline mb-2"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://certificates-3.myshopify.com/admin/orders/${standaloneItem.shopifyId}`}
                    >
                      Open in Shopify
                    </a>
                    {" | "}
                    <Link
                      className="text-blue-700 hover:underline mb-2"
                      to={`/search/#${btoa(standaloneItem.name)}`}
                    >
                      Search
                    </Link>
                  </div>
                  {Object.keys(
                    store.researchDataByCertId[standaloneItem.linkedresearchId]
                  ).length > 0 ? (
                    <CertSummary
                      cert={
                        store.researchDataByCertId[
                          standaloneItem.linkedresearchId
                        ]
                      }
                    >
                      {standaloneItem?.customerInfo?.map(({ name, value }) => {
                        if (name === "_vin") return null;
                        if (name === "_customerData") {
                          return null;
                          // (
                          //   <Fragment key={name}>
                          //     <p className="my-2">Customer-supplied information:</p>
                          //     <table className="w-full table table-zebra my-2">
                          //       <thead>
                          //         <tr>
                          //           <th>key</th>
                          //           <th>value</th>
                          //         </tr>
                          //       </thead>
                          //       <tbody>
                          //         {Object.entries(value).map(([k, v]) => (
                          //           <tr key={k}>
                          //             <td>{k}</td>
                          //             <td>
                          //               {typeof v === "boolean"
                          //                 ? v
                          //                   ? "yes"
                          //                   : "NO"
                          //                 : v}
                          //             </td>
                          //           </tr>
                          //         ))}
                          //       </tbody>
                          //     </table>
                          //   </Fragment>
                          // );
                        }
                        if (name === "previous_certificate_number")
                          return (
                            <>
                              <p>
                                Previous certificate number (from customer):{" "}
                                <b>{value}</b>
                              </p>
                              <RenderFieldReissueSearch initialSearch={value} />
                            </>
                          );
                        if (name === "notes")
                          return (
                            <div>
                              Notes (from customer):
                              <div className="border m-1 p-1 whitespace-pre-line">
                                {value}
                              </div>
                            </div>
                          );
                        return (
                          <pre key={name}>
                            {JSON.stringify({ name, value }, null, 2)}
                          </pre>
                        );
                      })}
                    </CertSummary>
                  ) : (
                    "Can't find any typing up data yet."
                  )}

                  {standaloneItem?.customerInfo?.map(({ name, value }) => {
                    if (name === "notes")
                      return (
                        <div className="mt-2">
                          <h2 className="text-2xl">Notes (from customer)</h2>
                          <div className="border p-2">{value}</div>
                        </div>
                      );

                    if (name === "previous_certificate_number")
                      return (
                        <RenderFieldReissueSearch
                          leftText="Customer-supplied certificate number"
                          initialSearch={value}
                          withCopyButton={false}
                        />
                      );

                    return null;
                  })}

                  {shipping_address ? (
                    <div className="mt-2">
                      <h2 className="text-2xl">Shipping address</h2>
                      <CopyToClipboard
                        text={[
                          "name",
                          "address1",
                          "address2",
                          "city",
                          "province",
                          "zip",
                          "country",
                        ]
                          .map((k) => shipping_address[k] || null)
                          .filter((a) => a !== null)
                          .join("\n")}
                      />
                    </div>
                  ) : (
                    "Can't find shipping address from Shopify"
                  )}

                  <ButtonWithConfirmation
                    message={`Really complete ${standaloneItem.name}?`}
                    onConfirm={async () => {
                      await certPOST("standaloneComplete", {
                        _id: standaloneItem._id,
                      });
                      standaloneItem.complete = new Date();
                    }}
                    className="mt-4 ml-2"
                    color="blue"
                  >
                    <ChevronDoubleRightIcon className="w-6 h-6" />
                    &nbsp;Complete
                  </ButtonWithConfirmation>
                </OrderLine>
              </Fragment>
            );
          })}
      </div>
    </Page>
  );
}

export default collect(StandaloneList);
