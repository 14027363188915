import { useEffect, useState } from "react";
import Confetti from "react-confetti";

function ConfettiComponent() {
  const [wh, setWH] = useState(null);
  useEffect(() => {
    setWH({ w: window.innerWidth, h: window.innerHeight });
  }, []);

  if (!wh) return null;

  return <Confetti width={wh.w} height={wh.h} numberOfPieces={50} />;
}

export default ConfettiComponent;
