import { findOneInArray, pluralN } from "lib/useful";
import { useEffect } from "react";
import { useState } from "react";
import { store } from "react-recollect";
import { Button } from "./Buttons";
import Input from "./Input";
import RenderFieldColumn from "./RenderFieldColumn";

export default function RenderFieldChooser({
  field,
  savedValue,
  setValue,
  localValue,
  resultsWrapClassName,
}) {
  const [text, setText] = useState("");
  const [results, setResults] = useState([]);

  // if savedValue changes (e.g. "Copy values" from a previous cert), update local state
  useEffect(() => {
    // (disable esline because I don't care if setValue changes)
    setValue(savedValue || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedValue]);

  useEffect(() => {
    const t = text.trim().toUpperCase();
    if (t === "") return;
    // filter to anything with this text in
    setResults(
      store.settingsMap[field.key].filter(
        (d) =>
          d.key.toUpperCase().startsWith(t) ||
          d.value.toUpperCase().startsWith(t)
      )
    );
  }, [text, field.key]);

  if (localValue) {
    const key = localValue;
    const value = findOneInArray(store.settingsMap[field.key], "key", key, {
      value: null,
    }).value;
    if (!value)
      return (
        <>
          <p className="text-red-700">
            Can't find {field.key} for key {key}
          </p>{" "}
          <Button onClick={() => setValue(null)}>clear</Button>
        </>
      );
    return (
      <RenderFieldColumn leftContent={field.label} rightExtraClasses={"flex"}>
        <div className="w-1/2 px-3 py-1">
          <pre>{key}</pre>
          <p className="font-bold">{value}</p>
        </div>
        <div className="">
          <Button onClick={() => setValue(null)}>clear</Button>
        </div>
      </RenderFieldColumn>
    );
  }

  let exactMatch = results.find(
    (r) => r.key.toUpperCase() === text.toUpperCase()
  );
  if (!exactMatch && results.length === 1) exactMatch = results[0];
  function handleEnter() {
    if (exactMatch) setValue(exactMatch.key);
  }

  return (
    <RenderFieldColumn leftContent={field.label}>
      <div className="w-60">
        <Input value={text} setValue={setText} onEnter={handleEnter} />
        {exactMatch && (
          <div>
            <p>Press enter to select</p>
            <p className="text-xs">
              {exactMatch.key}: {exactMatch.value}
            </p>
          </div>
        )}
      </div>
      <div className="flex-1 pl-3 py-1">
        {text.trim() === "" ? null : results.length < 1 ? (
          <p>No matches</p>
        ) : (
          <div
            className={`overflow-y-auto transition-all shadow-xl bg-white border-t-2 border-t-slate-400 ${resultsWrapClassName}`}
          >
            <p className="font-bold m-3">
              {pluralN(results.length, "possible option")}
            </p>
            {results.map((f) => {
              return (
                <div
                  key={f.key}
                  onClick={() => setValue(f.key)}
                  className="flex border cursor-pointer hover:border-emerald-600 hover:bg-emerald-50 m-1"
                >
                  <div className="w-24 text-right mr-1 border-r p-1">
                    <pre>{f.key}</pre>
                  </div>
                  <div className="p-1 ml-1">{f.value}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </RenderFieldColumn>
  );
}

RenderFieldChooser.defaultProps = {
  resultsWrapClassName: "max-h-64",
};
