import {
  formatTimestampNicelyDateTime,
  loggedInUserEmail,
  loggedInUserName,
  nowTimestamp,
} from "lib/useful";
import { useState } from "react";
import { Button, ButtonPrimary, ButtonSecondary } from "./Buttons";

export default function Notes({ notes, setNotes }) {
  const [adding, setAdding] = useState(false);
  const [noteText, setNoteText] = useState("");

  function saveNote() {
    setNoteText("");
    setAdding(false);
    const timestamp = nowTimestamp();
    const username = loggedInUserName();
    const email = loggedInUserEmail();
    setNotes([
      ...notes,
      {
        key: `${username}_${timestamp}`,
        username,
        timestamp,
        email,
        note: noteText,
      },
    ]);
  }

  return (
    <div className="border shadow-lg p-2">
      {adding ? (
        <>
          <textarea
            className="border w-full p-2"
            autoFocus
            rows={4}
            value={noteText}
            onChange={(e) => setNoteText(e.target.value)}
          />
          <div className="btn-group">
            <ButtonPrimary
              disabled={noteText.trim().length === 0}
              onClick={saveNote}
            >
              Save
            </ButtonPrimary>
            <Button onClick={() => setAdding(false)}>Cancel</Button>
          </div>
        </>
      ) : (
        <div className="flex">
          <h2 className="text-lg font-bold pt-3 mr-8 ml-2">Notes</h2>
          <ButtonSecondary onClick={() => setAdding(true)}>
            Add note
          </ButtonSecondary>
        </div>
      )}
      {notes
        .sort((a, b) => b.timestamp - a.timestamp)
        .map((note) => (
          <div key={note.key} className="m-2 border">
            <p className="text-sm text-slate-900 m-1">
              From <b>{note.username || note.email}</b> at{" "}
              {formatTimestampNicelyDateTime(note.timestamp)}
            </p>
            <p className="p-2">{note.note}</p>
          </div>
        ))}
    </div>
  );
}
