import { CreateABlankButton } from "./CreateACopyButton";
import DebugClear from "./DebugClear";
import LogoutButton from "./LogoutButton";
import MenuItem from "./MenuItem";

function NavBar({
  children,
  extraNavBarContentLeft,
  extraNavBarContentRight,
  menuItems,
}) {
  return (
    <div className="flex flex-col">
      <div className="h-16 flex text-white bg-gradient-to-r from-slate-800 to-slate-600">
        <h2 className="text-xl sm:text-2xl p-4 whitespace-nowrap">
          Certificate System
        </h2>
        {extraNavBarContentLeft}
        <div className="flex-1"></div>
        {extraNavBarContentRight}
        <CreateABlankButton />
        <DebugClear />
        <LogoutButton className="m-2" />
      </div>
      <div className="h-[calc(100vh_-_64px)]">
        <div className="flex h-full">
          <ul className="menu overflow-y-auto w-50 flex-none bg-slate-200 shadow-md border-r border-r-slate-300">
            {menuItems.map((m) => (
              <MenuItem key={m.t} m={m} />
            ))}
          </ul>
          <div className="flex-1 min-h-full overflow-y-scroll">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
