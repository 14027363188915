import { initialsPerSku, stylesPerSku } from "./PDFCustomerInfo";
import { findOne } from "lib/useful";

function PDFResearchSlip(doc, order, research) {
  doc.addPage("a4");

  // get vin or previous_certificate_number find linked line items
  const researchCustomerVINorPrevCertNo = findOne(
    order.line_items[research.lineItemIndex].properties,
    "name",
    "vin",
    findOne(
      order.line_items[research.lineItemIndex].properties,
      "name",
      "previous_certificate_number",
      { value: "" }
    )
  ).value;

  doc.setFontSize(24);
  doc.text(order.name, 20, 20);
  if (research.certsInOrder > 1) {
    doc.setFontSize(12);
    doc.text(
      `certificate ${research.certNumberWithinOrder + 1} of ${
        research.certsInOrder
      }`,
      155,
      20
    );
  }
  doc.line(15, 21, 195, 21);

  const lineItemIndex = research.lineItemIndex;
  const properties = Object.values(
    order.line_items[lineItemIndex].properties || {}
  );
  const propMap = properties.reduce(
    (a, c) => ({ ...a, [c.name]: c.value }),
    {}
  );
  const p = (k) => propMap[k] || "";

  doc.autoTable({
    margin: { left: 7, right: 7 },
    startY: 30,
    theme: "grid",
    styles: { lineColor: 0, lineWidth: 0.25, fontSize: 12, fontStyle: "bold" },
    bodyStyles: { minCellHeight: 15 },
    body: [
      [
        {
          content: `Marque: ${p("marque")}`,
          styles: { minCellHeight: 10, cellWidth: 90 },
        },
        {
          content: `Model: ${p("model")}\n${p("exact_car_model")}`,
          styles: { minCellHeight: 10, overflow: "ellipsize" },
        },
      ],
      [
        {
          content: `VIN: ${p("vin")}`,
          colSpan: 2,
          styles: { minCellHeight: 10 },
        },
      ],
      [`Engine No:`, `Body No:`],
      ["Specification:", "Exterior Colour:"],
      ["Trim Colour:", "Hood Colour:"],
      ["Date(s) built:", "Date despatched:"],
      [
        {
          content: "Destination (dealer):",
          colSpan: 2,
          styles: { minCellHeight: 20 },
        },
      ],
      [
        {
          content: "Other Numbers:",
          colSpan: 2,
          styles: { minCellHeight: 42 },
        },
      ],
      [
        {
          content: "Details of Equipment:",
          colSpan: 2,
          styles: { minCellHeight: 42 },
        },
      ],
      [
        {
          content: `Other Information:\n${p("additional_information")}`,
          colSpan: 2,
          styles: { minCellHeight: 42 },
        },
      ],
    ],
  });

  doc.line(7, 280, 210 - 7, 280);
  if (research.certsInOrder > 1) {
    doc.setFontSize(12);
    doc.text(
      `MULTIPLE CERTS!       ${research.certNumberWithinOrder + 1} of ${
        research.certsInOrder
      }`,
      7,
      285
    );
  }

  // show products ordered again, in small boxes

  let cols = [];
  const line_item_keys = Object.keys(order.line_items);
  for (let i = 0; i < line_item_keys.length; ++i) {
    const li = order.line_items[line_item_keys[i]];
    const isCurrentItem =
      line_item_keys[i].toString() === research.lineItemIndex.toString();

    const isLinkedToCurrentItem =
      findOne(li.properties, "name", "_vin", { value: "no VIN given!" })
        .value === researchCustomerVINorPrevCertNo ||
      findOne(li.properties, "name", "previous_certificate_number", {
        value: "no VIN given!",
      }).value === researchCustomerVINorPrevCertNo;
    // 2022-09-27: archive now want these items completely ignored, not greyed
    if (research.certsInOrder > 1 && !isCurrentItem && !isLinkedToCurrentItem)
      continue;
    // don't show ones that we don't want (cert, prem, email)
    if (!initialsPerSku[li.sku]) continue;

    cols.push({
      content: initialsPerSku[li.sku] ?? "?",
      styles: { ...stylesPerSku[li.sku], cellWidth: 10 },
    });
  }

  doc.autoTable({
    margin: { left: 7, right: 7 },
    startY: 271,
    body: [cols],
  });
}

export default PDFResearchSlip;
