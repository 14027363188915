import { useState } from "react";
import { Button } from "./Buttons";
import MessageDialogWithActions from "./MessageDialogWithActions";

export default function ButtonWithConfirmation({
  message,
  onConfirm,
  onCancel,
  yesButton,
  cancelButton,
  className,
  color,
  children,
  onClick,
  ...otherProps
}) {
  const [open, setOpen] = useState(false);
  if (onClick)
    throw Error(`ButtonWithConfirmation takes onConfirm, not onClick`);

  function handleCancel() {
    if (onCancel) onCancel();
    setOpen(false);
  }
  function handleConfirm() {
    if (onConfirm) onConfirm();
    setOpen(false);
  }

  return (
    <>
      {open && (
        <MessageDialogWithActions
          name="confirmButtonDialog"
          message={message}
          actions={[
            { text: "Cancel", onClick: handleCancel, colour: "red" },
            { text: "Confirm", onClick: handleConfirm, colour: "blue" },
          ]}
        />
      )}
      <Button color={color} onClick={() => setOpen(true)} className={className}>
        {children}
      </Button>
    </>
  );
}

ButtonWithConfirmation.defaultProps = {
  className: "btn-accent",
  children: "delete",
  yesButton: true,
  cancelButton: true,
};
