import HeaderBar from "components/HeaderBar";
import Loading from "components/Loading";
import Page from "components/Page";
import { useState } from "react";
import { certGET } from "lib/API";
import SearchResult from "components/SearchResult";
import InputTextWithButton from "components/InputTextWithButton";
import { pluralN } from "lib/useful";
import { ButtonPrimary } from "components/Buttons";
import { useEffect } from "react";

function Search() {
  const h = window.location.hash.substring(1);
  const [searchText, setSearchText] = useState(() => {
    try {
      return atob(h);
    } catch (e) {
      console.log("hash", h, "did not decode");
      return "";
    }
  });
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [results, setResults] = useState(null);
  const [showMeAllResultsOverride, setShowMeAllResultsOverride] =
    useState(false);

  function setAndDo(text) {
    setSearchText(text);
    setTimeout(() => doSearch(text), 150);
  }

  function doSearch(textArg) {
    const text = textArg || searchText;
    if (text.trim().length === 0) return;
    setShowMeAllResultsOverride(false);
    setResults(null);
    setLoading(true);
    setError(null);
    setSearch(text);
    window.history.pushState(null, null, `#${btoa(text)}`); //window.location.hash = btoa(text);
    certGET(`search?q=${btoa(text)}`)
      .then(
        (r) => {
          setLoading(false);
          if (!r.ok) {
            r.text().then((t) => setError({ r, t }));
            return [];
          }
          return r.json();
        },
        (e) => console.error("error second?", e)
      )
      .then(setResults);
  }

  useEffect(() => {
    if (h) setTimeout(doSearch, 250);
    // eslint-disable-next-line
  }, [h]);

  return (
    <Page title="Search">
      <HeaderBar>
        <InputTextWithButton
          autoFocus
          value={searchText}
          setValue={setSearchText}
          buttonContent="Search"
          label="Enter search"
          onEnter={doSearch}
        />
      </HeaderBar>
      {error && (
        <div className="bg-red-200">
          Error!<pre>{JSON.stringify(error, null, 2)}</pre>
        </div>
      )}
      {loading && <Loading text="Getting results" size="10vw" />}
      <div className="p-2">
        {results !== null && (
          <p>
            {results.length === 0
              ? "No results"
              : pluralN(results.length, "result")}{" "}
            for "{search}"
          </p>
        )}
        {results !== null &&
          (results.length < 100 || showMeAllResultsOverride) &&
          results.map((r, ri) => (
            <SearchResult key={ri} r={r} q={search} setAndDo={setAndDo} />
          ))}
        {results !== null &&
          results.length >= 100 &&
          !showMeAllResultsOverride && (
            <>
              <p>Too many results &mdash; be more specific</p>
              <ButtonPrimary onClick={() => setShowMeAllResultsOverride(true)}>
                Show me all results anyway (might break your computer though)
              </ButtonPrimary>
              <h2 className="mt-4">Here are the first 50 results:</h2>
              {results.slice(0, 50).map((r, ri) => (
                <SearchResult key={ri} r={r} q={search} setAndDo={setAndDo} />
              ))}
            </>
          )}
      </div>
    </Page>
  );
}

export default Search;
