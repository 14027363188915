import { useEffect, useState } from "react";
import { collect, store } from "react-recollect";
import Loading from "./Loading";
import { certGET } from "lib/API";

function LoadAuths() {
  const [error, setError] = useState(null);
  const [subtitle, setSubtitle] = useState(
    "The server might be having problems, try again later"
  );

  useEffect(() => {
    certGET("auths")
      .then((r) => {
        if (!r.ok) setError(r);
        else return r.json();
      })
      .then((auths) => {
        if (!auths || !Array.isArray(auths) || auths.length === 0)
          return setError({
            statusText: "You have no access to this system",
            subtitle: "You might need to ask an administrator to add you",
            auths,
            storeKeys: Object.keys(store),
          });
        store.auths = auths;
      })
      .catch((e) => {
        if (e instanceof TypeError && e.message === "Failed to fetch") {
          setError({ statusText: `Can't communicate with the server` });
          setSubtitle("The server might be having problems, try again later");
        } else {
          console.error("auths got error", e);
          if (typeof e === "string") setError({ statusText: e });
          else setError(e);
        }
      });
  }, []);

  if (error) {
    return (
      <>
        <h1 className="text-xl md:text-3xl lg:text-6xl font-bold p-2 md:p-3 lg:p-4 text-red-300 border-t-2 border-b-2 border-red-700">
          {error.statusText}
        </h1>
        <p className="text-lg md:text-xl lg:text-3xl text-amber-500 underline underline-offset-1">
          {subtitle}
        </p>
        <p>Logged in as {store.loggedInUser.email}</p>
        <pre className="text-sm text-emerald-600 mt-6 p-2 bg-slate-900 border-x-4 border-emerald-900">
          {JSON.stringify(error, null, 2)}
        </pre>
      </>
    );
  }
  return <Loading text="Loading Certificate System: auths" />;
}

export default collect(LoadAuths);
