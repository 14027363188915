import { certGET } from "lib/API";
import { useEffect } from "react";
import { useState } from "react";
import { store } from "react-recollect";
import { ButtonSecondary } from "./Buttons";
import CertSummary from "./CertSummary";
import InputTextWithButton from "./InputTextWithButton";
import Loading from "./Loading";
import RenderFieldColumn from "./RenderFieldColumn";

function CertSummaryAndSetButton({ mergeNewValues, cert, withCopyButton }) {
  function shortcuterise(sc) {
    // check if this value is a shortcut, and return the long version if it is
    if (!sc) return sc;
    const scUpper = sc.replace(/[/-]*/g, "").trim().toUpperCase();
    const l = store.settingsMap["shortcuts"].find((s) => scUpper === s.short);
    if (l) return l.long;
    return sc;
  }

  function copyToCurrentCert() {
    const newValues = {
      copiedFromPrevCert: cert.certNumber,
      ...store.settingsMap["fields"]
        .filter(
          (f) =>
            f.type !== "notyping" &&
            f.type !== "certNumber" &&
            f.key !== "issuedTo"
        )
        .reduce(
          (p, c) => ({
            ...p,
            [c.key]: shortcuterise(cert[c.key]),
          }),
          {}
        ),
    };
    mergeNewValues(newValues);
  }

  return (
    <div className="border rounded m-2 p-4">
      <CertSummary cert={cert}>
        {withCopyButton && (
          <ButtonSecondary onClick={copyToCurrentCert} className="mt-2">
            Copy data
          </ButtonSecondary>
        )}
      </CertSummary>
    </div>
  );
}
CertSummaryAndSetButton.defaultProps = {
  withCopyButton: true,
};

function ShowResults({ results, mergeNewValues, withCopyButton }) {
  if (!results || !Array.isArray(results) || results.length === 0) return null;
  return (
    <div>
      {results.map((r) => (
        <CertSummaryAndSetButton
          key={r.certNumber}
          mergeNewValues={mergeNewValues}
          cert={r}
          withCopyButton={withCopyButton}
        />
      ))}
    </div>
  );
}

export default function RenderFieldReissueSearch({
  initialSearch,
  mergeNewValues,
  copiedFromPrevCert,
  leftText,
  withCopyButton,
}) {
  const [search, setSearch] = useState(initialSearch || "");
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  function standardizeIssueNumber(s) {
    let spl = s.split("/");
    if (spl.length < 2) return s;
    if (spl[0].length === 0 || spl[1].length === 0) return s;
    if (spl[0] > 0 && spl[0] < 100) spl[0] = 2000 + parseInt(spl[0], 10);
    return spl
      .map((str) => parseInt(str, 10).toString().padStart(4, "0"))
      .join("/");
  }
  const issue = standardizeIssueNumber(search);

  function doSearch() {
    if (!issue || issue === "") return;
    setLoading(true);
    // fetch search results
    certGET(`search?q=${btoa(issue)}`)
      .then(
        (r) => {
          // setLoading(false);
          if (!r.ok) {
            r.text().then((t) => setError(JSON.stringify({ r, t })));
            setLoading(false);
            return [];
          }
          return r.json();
        },
        (e) => console.error("error second?", e)
      )
      .then((r) => {
        setLoading(false);
        setResults(r.map((result) => result.result));
      });
  }

  // if this is first load, and we have a (sensible) saved value then do the search
  useEffect(() => {
    if (copiedFromPrevCert) return;
    if (initialSearch && initialSearch.length > 3) doSearch();
  }, [copiedFromPrevCert, initialSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RenderFieldColumn leftContent={leftText ?? "Re-issue"}>
      {copiedFromPrevCert ? (
        <>
          <div className="p-2">
            Data was copied from certificate {copiedFromPrevCert}
          </div>
          {/* <ButtonSecondary
              onClick={() => mergeNewValues({ copiedFromPrevCert: null })}
            >
              Search again
            </ButtonSecondary> */}
        </>
      ) : (
        <div className="flex-1 p-2 shadow bg-white">
          <InputTextWithButton
            value={search}
            setValue={setSearch}
            label={`Search previous certificates${
              copiedFromPrevCert ? " again" : ""
            }`}
            buttonContent="Search"
            onEnter={doSearch}
          />
          {error && (
            <div className="border-2 rounded border-red-300 shadow-red-400/30 shadow-md m-2 py-2 px-4">
              <p className="text-xl font-bold text-red-600">Error</p>
              <pre>{error}</pre>
            </div>
          )}
          {loading ? (
            <div className="m-4">
              <Loading size="50px" />
            </div>
          ) : results.length > 20 ? (
            <p className="text-red-900 m-2">
              Too many results ({results.length} results)
            </p>
          ) : (
            <ShowResults
              mergeNewValues={mergeNewValues}
              results={results}
              copiedFromPrevCert={copiedFromPrevCert}
              withCopyButton={withCopyButton}
            />
          )}
        </div>
      )}
    </RenderFieldColumn>
  );
}

RenderFieldReissueSearch.defaultProps = {
  withCopyButton: true,
};
