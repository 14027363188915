import { useEffect, useState } from "react";
import { store } from "react-recollect";
import { certGETj } from "./API";
import { isDev } from "./useful";

function useStoreLoader(propsArg) {
  const props =
    typeof propsArg === "string" ? { collection: propsArg } : propsArg;
  if (!props.collection) throw Error("Must give collection to useStoreLoader!");

  const { collection, find, onLoad, storeKey, merge, onError } = Object.assign(
    {
      find: {},
      onLoad: null,
      storeKey: props.collection,
      merge: false,
      onError: null,
    },
    props
  );

  const [counter, setCounter] = useState(0);
  const items = store[storeKey];

  // in JS, {}!=={} so we have to stringify it to check
  const findStringified = JSON.stringify(find);

  useEffect(() => {
    let timerHandle = null;
    let url = "load/" + collection;
    if (Object.keys(find).length > 0) url += "?find=" + JSON.stringify(find);
    certGETj(url)
      .then((returnedItems) => {
        timerHandle = setTimeout(() => {
          const map = returnedItems.reduce(
            (a, c) => ({ ...a, [c._id]: c }),
            {}
          );

          store[storeKey] = merge
            ? {
                ...(store[storeKey] || {}),
                ...map,
              }
            : map;

          if (onLoad) onLoad(returnedItems);
          timerHandle = null;
        }, (isDev() ? 5 : 500) + 500 * Math.random());
      })
      .catch((e) => {
        const { ok, status, statusText, url } = e;
        console.error("caught an error:", { ok, status, statusText, url, e });
        if (onError) return onError({ ok, status, statusText, url, e });
      });

    return () => {
      if (timerHandle) {
        console.warn(
          `Cancelling timer that was loading ${collection}(${findStringified})`
        );
        clearTimeout(timerHandle);
      }
    };
    // we use findStringified instead of find as a dependancy
    // eslint-disable-next-line
  }, [collection, findStringified, counter, merge]);

  return {
    isLoading: items === undefined,
    items,
    refresh: () => {
      setCounter(counter + 1);
      if (!merge) delete store[storeKey];
    },
  };
}

export default useStoreLoader;
