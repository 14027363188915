import { ButtonPrimary } from "components/Buttons";
import Input from "components/Input";
import Loading from "components/Loading";
import MessageDialogWithActions from "components/MessageDialogWithActions";
import Page from "components/Page";
import { format, fromUnixTime } from "date-fns";
import { certGETj, certPOSTj } from "lib/API";
import { useEffect, useState } from "react";
import { collect } from "react-recollect";

function AddAndEditFlagDialogButton({ flag, reload }) {
  const [showing, setShowing] = useState(false);
  const [localFlag, setLocalFlag] = useState(
    flag ?? { flagText: "", reason: "" }
  );
  const [error, setError] = useState(null);
  const set = (k) => (v) => setLocalFlag((f) => ({ ...f, [k]: v }));

  async function addFlag() {
    const r = await certPOSTj("upsertFlag", localFlag);
    if (r.ok) {
      setShowing(false);
      reload();
      setTimeout(
        () => window.scrollTo({ top: 99999, behavior: "smooth" }),
        300
      );
    } else setError(r.error);
  }

  return (
    <div className="m-2">
      <ButtonPrimary onClick={() => setShowing(true)}>
        {flag ? "Edit" : "Add"} flag
      </ButtonPrimary>
      {showing && (
        <MessageDialogWithActions
          name="addFlagDialog"
          message={
            <>
              {error && (
                <p className="m-2 p-4 bg-red-300 border-2 border-red-600">
                  {error}
                </p>
              )}
              <Input
                autoFocus
                value={localFlag.flagText}
                setValue={set("flagText")}
                label={`Text to search for.   It will search whole order, so could be anything - VIN or an email or a name.  All special characters are stripped out, so it doesn't matter how the customer has written the numbers - a VIN of "123-456-789" will be matched by "123456789" (or even a parial match of "123456")`}
              />
              <Input
                value={localFlag.reason}
                setValue={set("reason")}
                label="Reason for flagging this (to remind you and your colleagues)"
              />
            </>
          }
          actions={[
            {
              text: "Cancel",
              colour: "red",
              onClick: () => setShowing(false),
            },
            ...(localFlag.flagText.trim().length > 0 &&
            localFlag.reason.trim().length > 0
              ? [
                  {
                    text: "Save",
                    colour: "green",
                    onClick: addFlag,
                  },
                ]
              : []),
          ]}
        />
      )}
    </div>
  );
}

function Flags() {
  const [loading, setLoading] = useState(true);
  const [flags, setFlags] = useState([]);
  const [reloadC, setReloadC] = useState(0);
  const reload = () => setReloadC((c) => c + 1);

  useEffect(() => {
    setLoading(true);
    certGETj("flags").then((r) => {
      setFlags(r);
      setLoading(false);
    });
  }, [reloadC]);

  if (loading) return <Loading text="Loading flag information" />;

  return (
    <Page title="Flags" heading="Warning flags" className="">
      <AddAndEditFlagDialogButton reload={reload} />
      <div className="p-4">
        <table className="table table-compact table-zebra w-full shadow-md my-2">
          <thead>
            <tr>
              <th>Date added</th>
              <th>Added by</th>
              <th>Text to flag</th>
              <th>Reason</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {flags.map((flag) => (
              <tr key={flag._id}>
                <td>
                  {format(
                    fromUnixTime(flag.flagCreatedTimestamp),
                    "d MMM yyyy"
                  )}
                </td>
                <td>
                  {flag.flaggedBy.replace(/@britishmotormuseum.co.uk/, "")}
                </td>
                <td>{flag.flagText}</td>
                <td>{flag.reason}</td>
                <td>
                  <AddAndEditFlagDialogButton flag={flag} reload={reload} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Page>
  );
}

export default collect(Flags);
