import { certGET } from "lib/API";
import { store } from "react-recollect";
import { ButtonError } from "./Buttons";

export default function DebugClear() {
  async function clear() {
    console.error("clearing things");
    await certGET("CLEAR");
    console.error("clear!");
    window.location.reload();
  }
  if (
    (store.loggedInUser || {}).email !==
    "tristan.moore@britishmotormuseum.co.uk"
  )
    return null;

  return (
    <ButtonError className="mt-2 sm:m-2" onClick={clear}>
      CLEAR!
    </ButtonError>
  );
}
