import { PropTypes } from "react-recollect";

function RenderFieldColumn({
  leftContent,
  rightExtraClasses,
  leftWidthClass,
  leftPaddingClass,
  borderT,
  borderB,
  children,
}) {
  if (leftContent === false)
    return (
      <div className={`flex-1 px-2 py-1 ${rightExtraClasses}`}>{children}</div>
    );
  return (
    <div className="flex">
      <div
        className={`flex-none ${leftWidthClass} ${leftPaddingClass} bg-gradient-to-r from-transparent to-slate-100 border-r border-slate-300 text-right${
          borderT ? " border-t border-t-slate-300" : ""
        }${borderB ? " border-b border-b-slate-300" : ""}`}
      >
        <div className="px-2">{leftContent}</div>
      </div>
      <div className={`flex-1 px-2 py-1 ${rightExtraClasses}`}>{children}</div>
    </div>
  );
}

RenderFieldColumn.propTypes = {
  children: PropTypes.node,
  leftContent: PropTypes.node,
  rightExtraClasses: PropTypes.string,
  leftWidthClass: PropTypes.string,
  leftPaddingClass: PropTypes.string,
  borderB: PropTypes.bool,
  borderT: PropTypes.bool,
};
RenderFieldColumn.defaultProps = {
  leftContent: null,
  rightExtraClasses: "",
  leftWidthClass: "w-60",
  leftPaddingClass: "pt-4 pb-2",
  borderT: false,
  borderB: false,
};

export default RenderFieldColumn;
