async function PDFPremierPhoto(doc, fulfilment, pdfCrop) {
  doc.addPage("a6", "portrait");
  const photoUrl = fulfilment.photoUrl;
  const img = new Image();
  img.setAttribute("crossOrigin", "anonymous");
  img.src = photoUrl;
  doc.addImage(
    img,
    "JPEG",
    pdfCrop.x,
    pdfCrop.y + 74 / 2,
    pdfCrop.w,
    pdfCrop.h
  );
}

export default PDFPremierPhoto;
