import { useEffect, useState } from "react";
import { capitalise, dedupe, isDev } from "lib/useful";
import { collect, store } from "react-recollect";
import PDFOutput from "PDFCreators/PDFOutput";
import PDFCreator from "PDFCreators/PDFCreator";
import useStoreLoader from "lib/useStoreLoader";
import Loading from "components/Loading";
import LoadResearches from "components/loaders/LoadResearches";
import LoadResearchData from "components/loaders/LoadResearchData";
import LoadShopifyOrders from "components/loaders/LoadShopifyOrders";
import OrderLine from "components/OrderLine";
import { certPOST } from "lib/API";
import Page from "components/Page";
import HeaderBar from "components/HeaderBar";
import FullsizeMessage from "components/FullsizeMessage";
import EmailDialogButton from "components/EmailDialogButton";
import { ButtonPrimary, ButtonSecondary } from "components/Buttons";
import PremierPhotoDialogButton from "components/PremierPhotoDialog";
import {
  CheckIcon,
  ChevronDoubleRightIcon,
  DownloadIcon,
  InboxInIcon,
  PrinterIcon,
} from "@heroicons/react/outline";
import ButtonWithConfirmation from "components/ButtonWithConfirmation";
import { downloadPDF } from "PDFCreators/common";

function getPhotoDownloadFilename(fulfilment) {
  let f = "premierPhoto";
  if (fulfilment.researchId) {
    const r =
      store.researchDataByCertId &&
      store.researchDataByCertId[fulfilment.researchId];
    if (r) {
      const c = r.certNumber;
      if (c) f = c.replace("/", "_");
    }
  }
  return f;
}

function getPhotoDownloadLink(fulfilment) {
  const f = getPhotoDownloadFilename(fulfilment);

  return (
    (isDev() ? "//localhost:4040" : "") +
    `/api/premierProxy/${f}.jpg?url=` +
    encodeURIComponent(
      (fulfilment.photoUrl || "").replace("https://cdn.shopify.com", "")
    ) +
    ("&token=" + store.token)
  );
}

function PrintArray(product, fulfilments,outputCallback) {
  PDFOutput((doc) => {
    for (let fulfilment of fulfilments) {
      PDFCreator(product, doc, fulfilment);
    }
  },outputCallback);
}

function FulfilmentList({ product, text }) {
  const { isLoading, items } = useStoreLoader({
    collection: `fulfilments`,
    storeKey: `fulfilments-${product}`,
    find: { product, completed: null },
  });
  useEffect(
    () => () => {
      delete store[`fulfilments-${product}`];
    },
    [product]
  ); // force it to reload next time

  const [autoSend, setAutoSend] = useState(null);

  if (isLoading) return <Loading text={`Loading items ready to be ${text}`} />;
  const fulfilments = Object.values(items);

  // load research, research data, shopify order for each fulfilment
  const researchesToLoad = dedupe(
    fulfilments
      .map((f) => f.researchId)
      .filter(
        (rid) => !store.certificateResearch || !store.certificateResearch[rid]
      )
  );
  const researchDataToLoad = dedupe(
    fulfilments
      .map((f) => f.researchId)
      .filter(
        (rid) => !store.researchDataByCertId || !store.researchDataByCertId[rid]
      )
  );
  const shopifyOrdersToLoad = dedupe(
    fulfilments
      .map((f) => f.shopifyOrdersId)
      .filter((sid) => !store.shopifyOrders || !store.shopifyOrders[sid])
  );

  if (researchesToLoad.length > 0)
    return <LoadResearches ids={researchesToLoad} />;
  if (researchDataToLoad.length > 0)
    return <LoadResearchData ids={researchDataToLoad} />;
  if (shopifyOrdersToLoad.length > 0)
    return <LoadShopifyOrders ids={shopifyOrdersToLoad} />;

  if (fulfilments.length === 0)
    return <FullsizeMessage>No orders waiting to be {text}</FullsizeMessage>;

  function completeAll() {
    const notComplete = fulfilments.filter((f) => !f.complete);
    if (notComplete.length > 0) {
      setTimeout(async () => {
        await certPOST("fulfilmentComplete", { _id: notComplete[0]._id });
        notComplete[0].complete = new Date();
        completeAll();
      }, 100 + Math.random() * 400);
    }
  }

  function emailAll() {
    setAutoSend(true);
  }

  return (
    <Page title={capitalise(product)} heading={`To be ${text}`}>
      {product !== "email" && (
        <HeaderBar>
          <div className="btn-group">
            <ButtonPrimary
              onClick={() =>
                PrintArray(
                  product,
                  fulfilments.filter((f) => !f.complete)
                )
              }
            >
              <PrinterIcon className="w-6 h-6 mr-2" />
              Print all
            </ButtonPrimary>
            <ButtonPrimary
              onClick={() =>
                PrintArray(
                  product,
                  fulfilments.filter((f) => !f.complete)
                  ,downloadPDF
                )
              }
            >
              <DownloadIcon className="w-6 h-6 mr-2"/>
              Download PDF
            </ButtonPrimary>
            <ButtonWithConfirmation
              className="btn-secondary"
              onConfirm={completeAll}
              message="Really complete ALL items?"
              color="blue"
            >
              <CheckIcon className="w-6 h-6 mr-2" />
              Complete all
            </ButtonWithConfirmation>
          </div>
        </HeaderBar>
      )}
      {product === "email" && (
        <HeaderBar>
          <div className="btn-group">
            <ButtonWithConfirmation
              message="Really send ALL EMAILS?"
              onConfirm={emailAll}
              color="blue"
              className="text-white"
            >
              {autoSend ? (
                <Loading size="20" className="mr-8 mb-8" />
              ) : (
                <>
                  <InboxInIcon className="w-6 h-6 mr-2" />
                  Email all
                </>
              )}
            </ButtonWithConfirmation>
          </div>
        </HeaderBar>
      )}
      <div>
        {fulfilments.map((fulfilment, fulfilmentIndex) =>
          fulfilment.complete ? (
            <p key={fulfilment._id} className="ml-2 mb-1">
              Fulfilment complete {fulfilment._id}
            </p>
          ) : (
            <OrderLine
              key={fulfilment._id}
              r={fulfilment}
              extraContentLeft={
                fulfilment.certsInOrder > 1 && (
                  <p>
                    ({fulfilment.certNumberWithinOrder + 1} of{" "}
                    {fulfilment.certsInOrder})
                  </p>
                )
              }
            >
              {product === "premPhoto" && (
                <img
                  src={fulfilment.photoUrl}
                  width={148}
                  height={105}
                  alt=""
                  className="border-2 border-black mx-2 mb-2"
                />
              )}
              <div className="btn-group">
                {product === "email" ? (
                  <EmailDialogButton
                    fulfilmentId={fulfilment._id}
                    autoSend={
                      fulfilmentIndex ===
                        fulfilments.findIndex((f) => !f.complete) && autoSend
                    }
                  />
                ) : product === "premPhoto" ? (
                  <>
                    <PremierPhotoDialogButton fulfilment={fulfilment} />
                    <ButtonSecondary
                      onClick={() =>
                        (window.location = getPhotoDownloadLink(fulfilment))
                      }
                    >
                      Download image {getPhotoDownloadFilename(fulfilment)}
                    </ButtonSecondary>
                  </>
                ) : (
                  <ButtonSecondary
                    onClick={() =>
                      PDFOutput((doc) => PDFCreator(product, doc, fulfilment))
                    }
                  >
                    <PrinterIcon className="w-6 h-6" />
                    &nbsp;Print
                  </ButtonSecondary>
                )}
                <ButtonWithConfirmation
                  message={`Really complete ${fulfilment.name}?`}
                  onConfirm={async () => {
                    await certPOST("fulfilmentComplete", {
                      _id: fulfilment._id,
                    });
                    fulfilment.complete = new Date();
                  }}
                  color="green"
                  className="text-white"
                >
                  <ChevronDoubleRightIcon className="w-6 h-6 mr-2" />
                  Complete
                </ButtonWithConfirmation>
              </div>
            </OrderLine>
          )
        )}
      </div>
    </Page>
  );
}

export default collect(FulfilmentList);
