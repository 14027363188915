import { store } from "react-recollect";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "components/Login";
import VersionInfo from "components/VersionInfo";
import Dashboard from "pages/Dashboard";
import FourOhFour from "pages/FourOhFour";
import CertificatesNew from "pages/CertificatesNew";
import CertificatesGenericTyping from "pages/CertificatesGenericTyping";
import CertificatesGenericResearch from "pages/CertificatesGenericResearch";
import FulfilmentList from "pages/FulfilmentList";
import Users from "pages/Users";
import Settings from "pages/Settings";
import AuditLogs from "pages/AuditLogs";
import Search from "pages/Search";
import ErrorHeader from "components/ErrorHeader";
import Layouts from "pages/Layouts";
import Layout from "pages/Layout";
import Lookup from "pages/Lookup";
import NavBar from "components/NavBar";
import WebResearchList from "pages/WebResearchList";
import WebResearchTyping from "pages/WebResearchTyping";
import StandaloneList from "pages/StandaloneList";
import Flags from "pages/Flags";
import NavBarFlags from "components/NavBarFlags";

window.store = store;

function App() {
  return (
    <>
      <ErrorHeader />
      <Login>
        <BrowserRouter>
          <VersionInfo />
          <NavBar
            menuItems={[
              { t: "Dashboard", p: "/" },
              { t: "Search", p: "/search" },
              { t: "Lookup", p: "/lookup" },
              { t: "New orders", p: "/new" },
              { t: "Web research", p: "/web/new" },
              { t: "Tech Spec", p: "/standalone/techspec" },
              { t: "Doc Wallet", p: "/standalone/docwallet" },
              {
                t: "Researching",
                c: [
                  { t: "Typing up", p: "/typing" },
                  { t: "Typo checking", p: "/typochecking" },
                  { t: "Technical checking", p: "/techchecking" },
                  { t: "Suspended", p: "/suspended" },
                  { t: "Deleted", p: "/deleted", auth: "admin" },
                ],
              },
              {
                t: "Fulfiling",
                c: [
                  { t: "Certificates", p: "/fulfilment/cert" },
                  { t: "Headed", p: "/fulfilment/head" },
                  { t: "Email", p: "/fulfilment/email" },
                  { t: "Premier Photos", p: "/fulfilment/premphoto" },
                  { t: "Supporting Letter", p: "/standalone/supportingLetter" },
                ],
              },
              {
                t: "Reporting",
                auth: "reporting",
                c: [{ t: "Audit logs", p: "/admin/audit" }],
              },
              {
                t: "Admin",
                auth: "admin",
                c: [
                  { t: "Flags", p: "/admin/flags" },
                  { t: "Layouts", p: "/admin/layouts" },
                  { t: "Users", p: "/admin/users" },
                  { t: "Settings", p: "/admin/settings" },
                ],
              },
            ]}
            extraNavBarContentLeft={<NavBarFlags />}
          >
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/search" element={<Search />} />
              <Route path="/lookup" element={<Lookup />} />
              <Route path="/new" element={<CertificatesNew />} />
              <Route path="/web/new" element={<WebResearchList />} />
              <Route path="/web/:id" element={<WebResearchTyping />} />
              <Route
                path="/typing"
                element={
                  <CertificatesGenericResearch
                    title="Typing"
                    heading="Typing up"
                    statuskey="typing"
                    path="typing"
                  />
                }
              />
              <Route
                path="/typing/:certId"
                element={
                  <CertificatesGenericTyping
                    title="Research entry"
                    moveTo="typochecking"
                    moveText="Typo Checking"
                    navTo="/typing"
                  />
                }
              />
              <Route
                path="/typochecking"
                element={
                  <CertificatesGenericResearch
                    title="Typo"
                    heading="Typo checking"
                    statuskey="typochecking"
                    path="typochecking"
                  />
                }
              />
              <Route
                path="/typochecking/:certId"
                element={
                  <CertificatesGenericTyping
                    title="Typo"
                    moveTo="techchecking"
                    moveText="Technical Checking"
                    navTo="/typochecking"
                  />
                }
              />
              <Route
                path="/techchecking"
                element={
                  <CertificatesGenericResearch
                    title="Tech"
                    heading="Technical checking"
                    statuskey="techchecking"
                    path="techchecking"
                  />
                }
              />
              <Route
                path="/techchecking/:certId"
                element={
                  <CertificatesGenericTyping
                    title="Tech"
                    moveTo="fulfiling"
                    moveText="Fulfiling"
                    navTo="/techchecking"
                  />
                }
              />
              <Route
                path="/suspended"
                element={
                  <CertificatesGenericResearch
                    title="Suspended"
                    heading="Suspended"
                    statuskey="suspended"
                    path="suspended"
                  />
                }
              />
              <Route
                path="/suspended/:certId"
                element={
                  <CertificatesGenericTyping
                    title="Suspended"
                    moveTo="-"
                    navTo="/suspended"
                  />
                }
              />
              <Route
                path="/deleted"
                element={
                  <CertificatesGenericResearch
                    title="Deleted"
                    heading="Deleted"
                    statuskey="deleted"
                    path="deleted"
                  />
                }
              />
              <Route
                path="/deleted/:certId"
                element={
                  <CertificatesGenericTyping
                    title="Deleted"
                    moveTo="typochecking"
                    navTo="/deleted"
                  />
                }
              />

              <Route
                path="/fulfilment/cert"
                element={
                  <FulfilmentList
                    product="cert"
                    text="printed on certificate paper"
                  />
                }
              />
              <Route
                path="/fulfilment/head"
                element={
                  <FulfilmentList
                    product="head"
                    text="printed on headed paper"
                  />
                }
              />
              <Route
                path="/fulfilment/email"
                element={
                  <FulfilmentList product="email" text="emailed to customer" />
                }
              />
              <Route
                path="/fulfilment/premphoto"
                element={
                  <FulfilmentList
                    product="premPhoto"
                    text="printed on glossy photo paper"
                  />
                }
              />
              <Route
                path="/standalone/techspec"
                element={
                  <StandaloneList
                    type="techspec"
                    text="researched for Tech Spec"
                  />
                }
              />
              <Route
                path="/standalone/supportingLetter"
                element={
                  <StandaloneList
                    type="supportingLetter"
                    text="researched for supporting letter"
                  />
                }
              />
              <Route
                path="/standalone/docwallet"
                element={
                  <StandaloneList
                    type="docwallet"
                    text="researched for Doc Wallet"
                  />
                }
              />

              <Route path="/admin/flags" element={<Flags />} />
              <Route path="/admin/layouts">
                <Route index element={<Layouts />} />
                <Route path=":layout" element={<Layout />} />
              </Route>
              <Route path="/admin/users" element={<Users />} />
              <Route path="/admin/settings" element={<Settings />} />
              <Route path="/admin/audit" element={<AuditLogs />} />

              <Route path="*" element={<FourOhFour />} />
            </Routes>
          </NavBar>
        </BrowserRouter>
      </Login>
    </>
  );
}

export default App;
