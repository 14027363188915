import {
  SwitchVerticalIcon,
  SwitchHorizontalIcon,
  ArrowsExpandIcon,
  SortDescendingIcon,
  MenuAlt3Icon,
  MenuAlt2Icon,
  GlobeIcon,
  GlobeAltIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import Input from "./Input";
import Toggle from "./Toggle";
import { Tooltip } from "./Tooltip";

function LayoutEditField({ fieldLabel, getV, setV }) {
  return (
    <>
      <div className="pl-2 flex items-center">
        <span className="text-xl font-bold mr-2">{fieldLabel}</span>
        <Toggle
          left
          label="Show field"
          value={getV("show", false)}
          setValue={setV("show")}
        />
        <Toggle
          left
          label="Show when empty"
          value={getV("showWhenEmpty", false)}
          setValue={setV("showWhenEmpty")}
        />
      </div>
      {!!getV("show", false) && (
        <>
          <h2 className="font-bold text-lg text-slate-900">Field label</h2>
          <div className="p-2 flex items-center pl-10">
            <Input
              value={getV("label", "")}
              setValue={setV("label")}
              placeholder="Label"
            />
          </div>
          <div className="p-2 flex items-center">
            <Tooltip position="right" text="Font size">
              <ArrowsExpandIcon className="h-6 w-6 inline-block mr-2" />
            </Tooltip>
            <Input
              type="number"
              value={getV("lsize", 12)}
              setValue={setV("lsize")}
            />
            <Tooltip position="left" text="Bold">
              <div
                className={`ml-2 w-6 h-6 cursor-pointer shadow inline-grid items-center justify-center ${
                  getV("lbold", false) ? "font-bold bg-green-100" : ""
                }`}
                onClick={() => setV("lbold")(!getV("lbold", false))}
              >
                {getV("lbold", false) ? "B" : "b"}
              </div>
            </Tooltip>
          </div>
          <div className="p-2 flex items-center">
            <Tooltip
              position="right"
              text="Indent (change horizontal position by this amount)"
            >
              <SwitchHorizontalIcon className="h-6 w-6 inline-block mr-2" />
            </Tooltip>
            <Input type="number" value={getV("lx")} setValue={setV("lx")} />
            <Tooltip position="left" text="Right or left align text">
              {getV("lRightAlign", false) ? (
                <MenuAlt3Icon
                  onClick={() => setV("lRightAlign")(false)}
                  className={`h-6 w-6 cursor-pointer inline-block ml-2 shadow`}
                />
              ) : (
                <MenuAlt2Icon
                  onClick={() => setV("lRightAlign")(true)}
                  className={`h-6 w-6 cursor-pointer inline-block ml-2 shadow`}
                />
              )}
            </Tooltip>
            <Tooltip
              position="left"
              text="Either use global column setting plus this delta value, or absolute horiz position"
            >
              {getV("lUseGlobalColumn", false) ? (
                <GlobeIcon
                  onClick={() => setV("lUseGlobalColumn")(false)}
                  className={`h-6 w-6 cursor-pointer inline-block ml-2 shadow bg-green-100`}
                />
              ) : (
                <GlobeAltIcon
                  onClick={() => setV("lUseGlobalColumn")(true)}
                  className={`h-6 w-6 cursor-pointer inline-block ml-2 shadow bg-red-100`}
                />
              )}
            </Tooltip>
          </div>
          <div className="p-2 flex items-center">
            <Toggle
              left
              label="Place below previous text"
              value={getV("belowPrev", false)}
              setValue={setV("belowPrev")}
            />
            {getV("belowPrev", false) ? (
              <>
                <Tooltip text="Add this vertical space after the previous text">
                  <SortDescendingIcon
                    className="h-6 w-6 inline-block mx-2"
                    onClick={() => setV("belowPrev")(false)}
                  />
                </Tooltip>
                <Input
                  type="number"
                  value={getV("lineHeight")}
                  setValue={setV("lineHeight")}
                />
              </>
            ) : (
              <>
                <SwitchVerticalIcon
                  className="h-6 w-6 inline-block mx-2"
                  onClick={() => setV("belowPrev")(true)}
                />
                <Input type="number" value={getV("ly")} setValue={setV("ly")} />
              </>
            )}
          </div>
          <h2 className="font-bold text-lg text-slate-900">Field data</h2>
          <div className="p-2 flex items-center">
            <Tooltip position="right" text="Font size">
              <ArrowsExpandIcon className="h-6 w-6 inline-block mr-2" />
            </Tooltip>
            <Input
              type="number"
              value={getV("fsize", 12)}
              setValue={setV("fsize")}
            />
            <Tooltip position="left" text="Bold">
              <div
                className={`ml-2 w-6 h-6 cursor-pointer shadow inline-grid items-center justify-center ${
                  getV("fbold", false) ? "font-bold bg-green-100" : ""
                }`}
                onClick={() => setV("fbold")(!getV("fbold", false))}
              >
                B
              </div>
            </Tooltip>
          </div>
          <div className="p-2 flex items-center">
            <Tooltip
              position="right"
              text="Indent (change horizontal position by this amount)"
            >
              <SwitchHorizontalIcon className="h-6 w-6 inline-block mr-2" />
            </Tooltip>
            <Input type="number" value={getV("fx")} setValue={setV("fx")} />
            <Tooltip
              position="left"
              text="Either use global column setting plus this delta value, or absolute horiz position"
            >
              {getV("fUseGlobalColumn", false) ? (
                <GlobeIcon
                  onClick={() => setV("fUseGlobalColumn")(false)}
                  className={`h-6 w-6 cursor-pointer inline-block ml-2 shadow bg-green-100`}
                />
              ) : (
                <GlobeAltIcon
                  onClick={() => setV("fUseGlobalColumn")(true)}
                  className={`h-6 w-6 cursor-pointer inline-block ml-2 shadow bg-red-100`}
                />
              )}
            </Tooltip>
          </div>
          <div className="p-2 flex items-center">
            <Toggle
              left
              label="Same vertical position as title text"
              value={getV("sameAsTitle", false)}
              setValue={setV("sameAsTitle")}
            />
            {!getV("sameAsTitle", false) && (
              <>
                <SwitchVerticalIcon className="h-6 w-6 inline-block mr-2" />
                <Input type="number" value={getV("fy")} setValue={setV("fy")} />
              </>
            )}
          </div>
          <div className="p-2 flex items-center">
            <Toggle
              left
              label="Use right margin"
              value={getV("useMargin", true)}
              setValue={setV("useMargin")}
            />
          </div>
          <div className="p-2 flex items-center">
            <Tooltip
              position="right"
              text={
                fieldLabel === "Signed on behalf of BMIHT"
                  ? "Signature JPG file (must start with a /); if blank or doesn't start with a / then don't output a signature"
                  : "Text to show when there is no data for this field.  Normally a hyphen"
              }
            >
              <InformationCircleIcon className="w-6 h-6 inline-block mr-2" />
            </Tooltip>
            <Input
              type="text"
              value={getV("valueWhenEmpty", "-")}
              setValue={setV("valueWhenEmpty")}
            />
          </div>
        </>
      )}
    </>
  );
}

export default LayoutEditField;
