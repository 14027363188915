import Loading from "components/Loading";
import Page from "components/Page";
import { useState } from "react";
import { certGET } from "lib/API";
import { findOne, pluralN, fieldName } from "lib/useful";
import RenderFieldChooser from "components/RenderFieldChooser";
import { useEffect } from "react";
import RenderFieldColumn from "components/RenderFieldColumn";
import { Link } from "react-router-dom";

function ClickForMoreInfo({ info }) {
  const [clicked, setClicked] = useState(false);

  if (!clicked)
    return (
      <span
        className="text-blue-500 cursor-pointer"
        onClick={() => setClicked(true)}
      >
        Click for more info
      </span>
    );
  return (
    <ul className="text-sm text-slate-500">
      {info.map((i) => (
        <li key={i}>
          <Link to={`/search/#${btoa(i)}`}>{i}</Link>
        </li>
      ))}
    </ul>
  );
}

function Lookup() {
  const [lookupText, setLookupText] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lookupData, setLookupData] = useState(null);

  const lookup =
    lookupText && lookupText.trim().length > 0
      ? findOne(lookupData, "key", lookupText)
      : null;

  useEffect(() => {
    certGET("load/lookups")
      .then(
        (r) => {
          setLoading(false);
          if (!r.ok) {
            r.text().then((t) => setError({ r, t }));
            return [];
          }
          return r.json();
        },
        (e) => console.error("error second?", e)
      )
      .then(setLookupData);
  }, []);

  if (!lookupData) return <Loading text="Fetching lookup data" />;
  if (loading) return <Loading text="Getting results" size="10vw" />;
  if (error)
    return (
      <div className="bg-red-200">
        Error!<pre>{JSON.stringify(error, null, 2)}</pre>
      </div>
    );

  return (
    <Page title="Lookup" heading="Find certificates by make and model">
      <RenderFieldChooser
        field={{ key: "makeAndModel", label: "Make and Model" }}
        savedValue=""
        setValue={setLookupText}
        localValue={lookupText}
        resultsWrapClassName="max-h-128"
      />
      {lookup && (
        <>
          <h2 className="text-xl m-2">
            {pluralN(lookup.numberOfCerts, "Certificate")} issued
          </h2>
          {lookup.tallies.map((tally) => (
            <RenderFieldColumn
              key={tally.key}
              leftContent={fieldName(tally.key)}
              rightExtraClasses="border-2"
            >
              {tally.value
                .sort((a, b) => b.value - a.value)
                .map((tallyvalue) => (
                  <RenderFieldColumn
                    key={tallyvalue.key}
                    leftContent={tallyvalue.key}
                    leftWidthClass="w-96"
                  >
                    <div className="py-3">
                      <p>
                        {pluralN(tallyvalue.value.length, "certificate")} issued
                      </p>
                      <ClickForMoreInfo info={tallyvalue.value} />
                    </div>
                  </RenderFieldColumn>
                ))}
            </RenderFieldColumn>
          ))}
        </>
      )}
    </Page>
  );
}

export default Lookup;
