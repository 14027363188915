import { useEffect, useState } from "react";
import { certPOST } from "lib/API";
import { newPDF } from "PDFCreators/common";
import { store } from "react-recollect";
import MessageDialogWithActions from "components/MessageDialogWithActions";
import PDFEmailCopy from "PDFCreators/PDFEmailCopy";
import Loading from "components/Loading";
import Input from "./Input";
import { isDev } from "lib/useful";

function EmailDialog({
  handleClose,
  fulfilment,
  shOrder,
  researchData,
  autoSend,
}) {
  const [emailText, setEmailText] = useState(
    (
      "This email is part of your order {name}\n\n" +
      "Please find attached your certificate, number {certNo}"
    )
      .replace("{name}", shOrder.name)
      .replace("{certNo}", researchData.certNumber)
  );
  const [subjectText, setSubjectText] = useState(
    `BMIHT Certificate ${researchData.certNumber}`
  );
  const [emailTo, setEmailTo] = useState(
    (isDev() ? "tris@bmm.co.uk" : shOrder.email) || ""
  );

  // waiting | sending | sent | error
  const [sendingState, setSendingState] = useState("waiting");

  const filename = `Heritage Certificate Email Copy ${researchData.certNumber.replace(
    "/",
    "_"
  )}.pdf`;

  const [generatedPDF, setGeneratedPDF] = useState(null);

  useEffect(() => {
    const doc = newPDF();
    PDFEmailCopy(doc, fulfilment);
    setGeneratedPDF(doc.output("datauristring", { filename }));
  }, [filename, fulfilment]);

  useEffect(() => {
    if (sendingState === "waiting" && generatedPDF !== null && autoSend) {
      setTimeout(send, 1000);
    }
  }, [autoSend, sendingState, generatedPDF]); // eslint-disable-line

  async function send() {
    setSendingState("sending");
    const emailData = {
      from: "archive@britishmotormuseum.co.uk",
      to: emailTo,
      subject: subjectText,
      text: emailText,
      envelope: {
        from: "British Motor Museum Archive <archive@britishmotormuseum.co.uk>",
        to: emailTo,
      },
      attachments: [{ filename, path: generatedPDF }],
    };
    const res = await certPOST("email", emailData);
    setSendingState(res.ok ? "sent" : "error");
    if (res.ok) {
      certPOST("fulfilmentComplete", { _id: fulfilment._id });
      fulfilment.complete = new Date();
      handleClose();
    } else {
      store.error = (
        <>
          <p>Error sending email!</p>
          <pre>{JSON.stringify({ emailData, res }, null, 2)}</pre>
        </>
      );
    }
  }

  // https://stackoverflow.com/questions/46155/whats-the-best-way-to-validate-an-email-address-in-javascript
  const disabled = !emailTo.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  return (
    <MessageDialogWithActions
      name="emailDialog"
      message={
        sendingState === "sending" ? (
          <Loading />
        ) : (
          <>
            {sendingState === "error" && (
              <p className="text-red-600">ERROR when sending email</p>
            )}
            <h2 className="border-b-2 border-slate-300">
              {autoSend ? "Auto-sending email..." : "Send email"}
            </h2>
            <Input
              className="m-1"
              type="email"
              placeholder="to"
              value={emailTo}
              setValue={setEmailTo}
            />
            <div className="border-t-2 border-b-2 border-slate-500 bg-slate-100 p-2 my-2">
              <div className="form-control">
                <input
                  type="text"
                  placeholder="subject"
                  className="input input-bordered"
                  value={subjectText}
                  onChange={(e) => setSubjectText(e.target.value)}
                />
              </div>
              <div className="form-control">
                <textarea
                  className="w-full textarea h-36 textarea-bordered"
                  onChange={(e) => setEmailText(e.target.value)}
                  value={emailText}
                />
              </div>
            </div>

            <p>Attached PDF:</p>
            <iframe
              title="Email copy"
              width="100%"
              height={Math.max(100, window.innerHeight - 550)}
              src={generatedPDF}
            />
          </>
        )
      }
      actions={[
        {
          text: "Cancel",
          colour: "red",
          onClick: handleClose,
        },
        ...(disabled
          ? []
          : [
              {
                text: "Send",
                colour: "green",
                onClick: send,
              },
            ]),
      ]}
    />
  );
}

export default EmailDialog;
