import { store } from "react-recollect";
const packageJSON = require("../../package.json");

const allowedURLsEvenWithoutLogin = [
  "login",
  "sendForgotEmail",
  "resetPassword",
];

export const API_URL =
  process.env.NODE_ENV === "production"
    ? "/api/"
    : "http://localhost:4040/api/";

console.log("API_URL", API_URL);

function certGET(url) {
  const t = store.token;
  if (!t) throw Error(`No token: ${JSON.stringify(store.loggedInUser)}`);

  // (`certGET>${url}`);
  return fetch(API_URL + url, {
    headers: { Authorization: t, "Client-Version": packageJSON.version },
  })
    .then((r) => {
      if (!r.ok) throw r.statusText;
      return r;
    })
    .catch((error) => {
      console.error("certGET error", error, url, API_URL);
      store.error = (
        <>
          <p>Error when communicating with the server:</p>
          <pre>{JSON.stringify({ error, url, API_URL }, null, 2)}</pre>
          <p>You may have to wait a while and then refresh this page</p>
        </>
      );
    });
}

function certPOST(url, data = {}) {
  const t = store.token;
  if (!t && !allowedURLsEvenWithoutLogin.includes(url))
    throw Error(`No token: ${JSON.stringify(store.loggedInUser)}`);

  try {
    const body = JSON.stringify(data);
    return fetch(API_URL + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: t,
        "Client-Version": packageJSON.version,
      },
      body,
    })
      .then((r) => {
        if (!r.ok) throw r.statusText;
        return r;
      })
      .catch((error) => {
        console.error("certPOST error", error, url, API_URL);
        store.error = (
          <>
            <p>Error when communicating with the server:</p>
            <pre>{JSON.stringify({ error, url, API_URL }, null, 2)}</pre>
            <p>You may have to wait a while and then refresh this page</p>
          </>
        );
      });
  } catch (e) {
    console.error("Caught error when stringifying", data);
  }
}

const certGETj = (url) => certGET(url).then((r) => r.json());
const certPOSTj = (url, data = {}) => certPOST(url, data).then((r) => r.json());

export { certGET, certPOST, certGETj, certPOSTj };
