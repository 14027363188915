import { ButtonSecondary } from "components/Buttons";
import Collapse from "components/Collapse";
import Input from "components/Input";
import MessageDialogWithActions from "components/MessageDialogWithActions";
import Page from "components/Page";
import { useState } from "react";
import { store } from "react-recollect";
import { certPOSTj } from "lib/API";
import RenderFieldSharonShortcutTextarea from "components/RenderFieldSharonShortcutTextarea";
import SettingsOrderDialogButton from "components/SettingsOrderDialogButton";

function NewSettingValue({ settingKey }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [short, setShort] = useState("");
  const [long, setLong] = useState("");

  async function save() {
    const currentSettings = store.settingsMap[settingKey] || [];
    const newSettings = [...currentSettings, { key: short, value: long }];
    console.log("save", { settingKey, currentSettings, newSettings });
    await certPOSTj(`saveSettings/${settingKey}`, newSettings);
    setDialogOpen(false);
    window.location.reload();
  }

  const valid = short.length > 0 && long.length > 0;

  return (
    <>
      {dialogOpen && (
        <MessageDialogWithActions
          name="settingsAddNew"
          message={
            <div className="m-1">
              <h1>New {settingKey}</h1>
              <Input
                value={short}
                setValue={setShort}
                label="From (short text)"
              />
              <Input
                value={long}
                setValue={setLong}
                label="Expand to (long text)"
              />
            </div>
          }
          actions={[
            { text: "Cancel", onClick: () => setDialogOpen(false) },
            ...(valid
              ? [
                  {
                    text: "Save",
                    colour: "green",
                    onClick: () => save(),
                  },
                ]
              : []),
          ]}
        />
      )}
      <ButtonSecondary onClick={() => setDialogOpen(true)}>
        Add new
      </ButtonSecondary>
    </>
  );
}

function SettingsTable({
  settingsKey,
  description,
  fields,
  settings,
  fieldNames,
  withPracticeArea,
}) {
  const [dialogId, setDialogId] = useState(null);
  const [setting, setSetting] = useState("");
  const [practice, setPractice] = useState("");

  function openDialog(id) {
    setDialogId(id);
    setSetting(settings.find((s) => s[fields[0]] === id)[fields[1]] || "");
  }

  async function deleteSetting(id) {
    console.log("deleting key", id);
    const currentSettings = store.settingsMap[settingsKey] || [];
    const newSettings = currentSettings.filter((s) => s.key !== id);
    console.log(
      "save",
      JSON.stringify({
        id,
        settingsKey,
        currentSettings,
        newSettings,
      })
    );
    await certPOSTj(`saveSettings/${settingsKey}`, newSettings);
    setDialogId(null);
    window.location.reload();
  }

  async function saveSetting(id) {
    console.log("saving for key", id, "new value", setting);
    const currentSettings = store.settingsMap[settingsKey] || [];
    const newSettings = currentSettings.map((s) =>
      s.key === id ? { ...s, value: setting } : s
    );
    await certPOSTj(`saveSettings/${settingsKey}`, newSettings);
    setDialogId(null);
    window.location.reload();
  }

  function showHiddenSpaces(str) {
    return str.split(" ").map((s, si) => (
      <>
        {si > 0 && <span className="text-yellow-600 mx-[1px]">_</span>}
        {s}
      </>
    ));
  }

  async function saveReorder(o) {
    const currentSettings = store.settingsMap[settingsKey] || [];
    console.log("saving order", { settingsKey, o, currentSettings });
    await certPOSTj(`saveSettings/${settingsKey}`, o);
    window.location.reload();
  }

  settings.forEach((v, i) => {
    const fi = settings.findIndex((l) => l.key === v.key);
    if (fi !== i) {
      console.error(`Duplicate keys in setting ${i} and ${fi}}`);
    }
  });

  return (
    <div className="p-2">
      {dialogId && (
        <MessageDialogWithActions
          name="settingsChange"
          actions={[
            { text: "Cancel", onClick: () => setDialogId(null) },
            {
              text: "Delete",
              onClick: () => deleteSetting(dialogId),
              colour: "red",
            },
            {
              text: "Save",
              colour: "green",
              onClick: () => saveSetting(dialogId),
            },
          ]}
          message={
            <>
              <h2 className="text-xl border-b border-slate-300">
                Change key {dialogId}
              </h2>
              <Input value={setting} setValue={setSetting} />
            </>
          }
        />
      )}
      <p className="text-slate-800">{description}</p>

      <table className="table table-compact table-zebra w-full shadow-md my-2">
        <thead>
          <tr>
            <th>{fieldNames?.key || "Key"}</th>
            <th>{fieldNames?.value || "Value"}</th>
          </tr>
        </thead>
        <tbody>
          {settings.map((setting) => (
            <tr
              key={setting[fields[0]]}
              className="cursor-pointer"
              onClick={() => openDialog(setting[fields[0]])}
            >
              <td>{showHiddenSpaces(setting[fields[0]])}</td>
              <td>{showHiddenSpaces(setting[fields[1]])}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="btn-group">
        <NewSettingValue settingKey={settingsKey} />
        <SettingsOrderDialogButton
          value={settings.map((s) => ({
            key: s[fields[0]],
            value: s[fields[1]],
          }))}
          onSave={saveReorder}
        >
          reorder
        </SettingsOrderDialogButton>
      </div>
      {withPracticeArea && (
        <RenderFieldSharonShortcutTextarea
          label="Practice area"
          value={practice}
          onChange={setPractice}
        />
      )}
    </div>
  );
}

function Settings() {
  // some settings are handled elsewhere (e.g. layouts)
  // some are tables
  // some can only be reset (created_at_min, since_id)
  const settingsTypes = {
    layout_email: { type: "hidden" },
    layout_paper: { type: "hidden" },
    layout_certstd: { type: "hidden" },
    layout_certprem: { type: "hidden" },
    specold: { type: "hidden" },
    created_at_min: { type: "hidden" },
    since_id: { type: "hidden" },
    shortcuts: {
      type: "hidden",
      description: "Text is replaced when research is saved",
      fields: ["short", "long"],
    },
    fields: { type: "hidden" },
    makeAndModel: {
      title: "Makes and Models",
      type: "table",
      description: "Short codes mapping for makes and models",
      fields: ["key", "value"],
    },
    specs: {
      type: "hidden",
      description: "List of car specifications available",
      fields: ["0", "1"],
    },
    sharonShortcuts: {
      title: "Shortcuts when typing up",
      withPracticeArea: true,
      type: "table",
      description:
        "Expand shortcuts to full text while typing.  Make sure longest is at the top of the list",
      fields: ["key", "value"],
      fieldNames: { key: "Short", value: "Long" },
    },
  };

  const unknownSettings = Object.keys(store.settingsMap).filter(
    (k) => settingsTypes[k] === undefined
  );

  return (
    <Page title="Settings" headings="Change settings">
      {Object.entries(settingsTypes).map(([key, value]) => {
        switch (value.type) {
          case "hidden":
            return null;
          case "arraytable":
            return (
              <Collapse key={key} title={key}>
                <SettingsTable
                  settingsKey={key}
                  description={value.description}
                  key={key}
                  fields={value.fields}
                  fieldNames={value.fieldNames}
                  settings={Object.entries(store.settingsMap[key] || {})}
                />
              </Collapse>
            );
          case "table":
            return (
              <Collapse
                key={key}
                title={value.title || key}
                defaultOpen={value.defaultOpen}
              >
                <SettingsTable
                  settingsKey={key}
                  description={value.description}
                  withPracticeArea={value.withPracticeArea}
                  key={key}
                  fields={value.fields}
                  fieldNames={value.fieldNames}
                  settings={Object.values(store.settingsMap[key] || {})}
                />
              </Collapse>
            );
          default:
            return (
              <Collapse key={key} title={key}>
                <p className="text-slate-800">{value.description}</p>
                <pre>{JSON.stringify(value, null, 2)}</pre>
              </Collapse>
            );
        }
      })}
      {unknownSettings.length > 0 && (
        <div className="m-2 p-2 border-2 border-red-600 shadow-md shadow-red-500">
          <h2 className="text-xl mb-4">Unknown settings</h2>
          {unknownSettings.map((sKey) => (
            <pre key={sKey} className="border m-2 p-2">
              <b>{sKey}</b>
              {"\n"}---{"\n"}
              {"\n"}
              {JSON.stringify(store.settingsMap[sKey], null, 2)}
            </pre>
          ))}
        </div>
      )}
    </Page>
  );
}

export default Settings;
