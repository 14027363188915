import PropTypes from "prop-types";
import { buttonClasses } from "lib/constants";

function Button({ children, color, className, ...otherProps }) {
  return (
    <button className={`${buttonClasses[color]} ${className}`} {...otherProps}>
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
};
Button.defaultProps = {
  color: "default",
  className: "",
};

const ButtonPrimary = (props) => <Button color="green" {...props} />;
const ButtonSecondary = (props) => <Button color="blue" {...props} />;
const ButtonError = (props) => <Button color="red" {...props} />;

export { Button, ButtonPrimary, ButtonSecondary, ButtonError };
